import axios from "axios";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import firebaseAuth from "./Services/auth";
import { logEvent } from "./Services/firebase";
import { requestHeaders } from "./Tools/DataUtils";
import log from "./Tools/Log";

function CheckAccountStateInterstitial() {
  const eventSource = "CheckAccountStateInterstitial";

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/checkAuth`,
          {},
          await requestHeaders()
        )
        .then(response => {
          log.trace("fetchData.response.data", response.data);
          logEvent(eventSource, "fetchData.response");
          navigate("/console");
        })
        .catch(error => {
          log.error("Error requesting data", error);
          logEvent(eventSource, "fetchData.error", error);

          if (error.status == 310) {
            // 310 - not a member
            navigate("/newuserprofile");
          } else if (error.status == 311) {
            // 311 - no membership
            navigate("/newusermembership");
          } else if (error.status == 313) {
            // 313 - already subscribed
            navigate("/console"); // TODO check loop cycles
          } else if (error.status == 314) {
            // 314 - session paid; no membership created yet
            navigate("/subscribesuccess");
          } else {
            // server error
            firebaseAuth.signOut().then(() => {
              navigate("/");
            });
          }
        });
    } catch (exception) {
      log.error("Error requesting data", exception);
      logEvent(eventSource, "fetchData.exception", exception);
      firebaseAuth.signOut().then(() => {
        navigate("/");
      });
    }
  };

  return <></>;
}

export default CheckAccountStateInterstitial;
