import { Alert, Box, LinearProgress, Stack, Typography } from "@mui/material";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { logEvent } from "../Services/firebase";
import { requestHeaders } from "../Tools/DataUtils";
import log from "../Tools/Log";

function Membership() {
  const eventSource = "Membership";

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [userMembershipData, setUserMembershipData] = useState({});

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    fetchData();
  }, []);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getUserMembership`,
          {},
          await requestHeaders()
        )
        .then(response => {
          log.trace("fetchData.response.data", response.data);
          logEvent(eventSource, "fetchData.response");

          setUserMembershipData(response.data);
        })
        .catch(error => {
          log.error("Error requesting data", error);
          logEvent(eventSource, "fetchData.error", error);
          setAlertMessage({
            message: t("error.dataFetch"),
            severity: "error",
          });
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting data", exception);
      logEvent(eventSource, "fetchData.exception", exception);
      setAlertMessage({
        message: t("error.dataFetch"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  return (
    <PageContainer title={t("text.membership")} breadcrumbs="">
      <Box component="section">
        {alertMessage.message && (
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        )}
        {loading ? (
          <LinearProgress />
        ) : (
          <Box component="section">
            <Stack direction="row" spacing={2}>
              <Stack direction="column">
                <Typography
                  variant="caption"
                  gutterBottom
                  sx={{ display: "block" }}
                >
                  {t("text.membership")}
                </Typography>
                <Typography variant="h5">
                  {userMembershipData.metadata?.product.name}
                </Typography>
              </Stack>
              <Stack direction="column">
                <Typography
                  variant="caption"
                  gutterBottom
                  sx={{ display: "block" }}
                >
                  {t("text.status")}
                </Typography>
                <Typography variant="h5">
                  {userMembershipData.membership?.status?.toLocaleUpperCase()}
                </Typography>
              </Stack>
              <Stack direction="column">
                <Typography
                  variant="caption"
                  gutterBottom
                  sx={{ display: "block" }}
                >
                  {t("view.membership.text.refreshesAt")}
                </Typography>
                <Typography variant="h5">
                  {userMembershipData.membership?.refreshesAt != null
                    ? new Date(
                        userMembershipData.membership?.refreshesAt * 1000
                      ).toLocaleString()
                    : "-"}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="column" sx={{ pt: 2 }}>
              <Typography
                variant="caption"
                gutterBottom
                sx={{ display: "block" }}
              >
                {t("view.membership.text.manageMembership")}
              </Typography>
              <Typography variant="h5">
                <a
                  href="https://dashboard.stripe.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("view.membership.text.stripeDashboard")}
                </a>
              </Typography>
            </Stack>
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}

export default Membership;
