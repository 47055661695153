import { Button, Step, StepLabel, Stepper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import log from "../Tools/Log";

const Wizard = ({
  open,
  onClose,
  title,
  totalSteps,
  steps,
  stepsComponents,
}) => {
  const eventSource = "Dashboard";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [activeStep, setActiveStep] = useState(0);

  const handlePreviousStep = e => {
    log.trace("handlePreviousStep", e);
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleNextStep = e => {
    log.trace("handleNextStep", e);
    if (activeStep < totalSteps - 1) {
      setActiveStep(activeStep + 1);
    } else {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {stepsComponents[activeStep]}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handlePreviousStep}
          color="inherit"
          disabled={activeStep === 0}
          sx={{ mr: 1 }}
        >
          {t("buttonAction.previous")}
        </Button>
        <Button onClick={onClose} color="secondary" sx={{ flex: "1 1 auto" }}>
          {t("buttonAction.skip")}
        </Button>
        <Button onClick={handleNextStep} color="secondary">
          {activeStep === steps.length - 1
            ? t("buttonAction.finish")
            : t("buttonAction.next")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Wizard;
