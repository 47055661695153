import { log } from "loglevel";
import firebaseAuth from "../Services/auth";

export function truncateIfNeeded(str, maxLength = 120) {
  return str.length > maxLength ? str.substring(0, maxLength - 1) + "…" : str;
}

export function sanitizeInputPrompt(str) {
  //TODO
  return str;
}

export async function requestHeaders() {
  const headers = {
    headers: {
      "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${await firebaseAuth.currentUser.getIdToken()}`,
    },
  };

  return headers;
}

export async function formSubmitHeaders() {
  const headers = {
    headers: {
      "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS}`,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${await firebaseAuth.currentUser.getIdToken()}`,
    },
  };

  return headers;
}

export function getUserId() {
  return firebaseAuth.currentUser.uid;
}

export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 130,
      height: 130,
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}
