import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { logEvent } from "../../Services/firebase";
import { requestHeaders, truncateIfNeeded } from "../../Tools/DataUtils";
import log from "../../Tools/Log";
import ConfirmationDialog from "../../UiComponents/ConfirmationDialog";
import PagePreview from "./PagePreview";

function Pages() {
  const eventSource = "Pages";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [bookId] = useState(pageParams.id);
  const [pages, setPages] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogConfirmationData, setDialogConfirmationData] = useState({});

  const [openPreview, setOpenPreview] = React.useState(false);
  const [previewPageData, setPreviewPageData] = useState();
  const [locationsList, setLocationsList] = useState({ options: [] });

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    fetchData();
  }, []);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      //TODO replace with implementation later
      const filters = {
        title: "search*",
      };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getPages`,
          {
            bookUuid: pageParams.id,
            filters,
          },
          await requestHeaders()
        )
        .then(response => {
          log.trace("fetchData.response.data", response.data);
          logEvent(eventSource, "fetchData.response");

          response.data.map(item => {
            item.backgroundImage &&
              (item.imageUrl =
                `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                item.backgroundImage);

            item.narrativeAudio &&
              (item.audioUrl =
                `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                item.narrativeAudio);
          });

          setPages(response.data);
        })
        .catch(error => {
          log.error("Error requesting data", error);
          logEvent(eventSource, "fetchData.error", error);
          setAlertMessage({
            message: t("error.dataFetch"),
            severity: "error",
          });
        })
        .finally(() => {
          hideLoadingUI();
        });

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getLocations`,
          {
            bookUuid: pageParams.id,
            filters,
          },
          await requestHeaders()
        )
        .then(response => {
          log.trace("fetchData.response.data", response.data);
          logEvent(eventSource, "fetchData.response");

          response.data.map(item => {
            item.imageUrl =
              `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
              item.backgroundImage;
          });

          if (response.data && response.data.length > 0) {
            setLocationsList({
              locations: response.data,
              options: response.data.map(item => item.name),
            });
          }
        })
        .catch(error => {
          log.error("Error requesting data", error);
          logEvent(eventSource, "fetchData.error", error);
          setAlertMessage({
            message: t("error.dataFetch"),
            severity: "error",
          });
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting data", exception);
      logEvent(eventSource, "fetchData.exception", exception);
      setAlertMessage({
        message: t("error.dataFetch"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const swapPageNumbers = async (leftPageUuid, rightPageUuid) => {
    log.trace("swapPageNumbers.request");
    logEvent(eventSource, "swapPageNumbers.request");

    try {
      showLoadingUI();

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/swapPageNumber`,
          {
            bookUuid: bookId,
            leftPageUuid: leftPageUuid,
            rightPageUuid: rightPageUuid,
          },
          await requestHeaders()
        )
        .then(response => {
          log.trace("swapPageNumbers.response.data", response.data);
          logEvent(eventSource, "swapPageNumbers.response");
          fetchData();
        })
        .catch(error => {
          log.error("Error requesting action", error);
          logEvent(eventSource, "swapPageNumbers.error", error);
          setAlertMessage({
            message: t("error.action"),
            severity: "error",
          });
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting action", exception);
      logEvent(eventSource, "swapPageNumbers.exception", exception);
      setAlertMessage({
        message: t("error.action"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleOpenDialog = () => {
    log.trace("handleOpenDialog");
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    log.trace("handleCloseDialog");
    setOpenDialog(false);
    setDialogConfirmationData({});
  };

  const handleConfirmDialog = async () => {
    log.trace("handleConfirmDialog.request");
    handleCloseDialog();

    try {
      showLoadingUI();

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/deletePage`,
          {
            bookUuid: bookId,
            pageUuid: dialogConfirmationData.uuid,
          },
          await requestHeaders()
        )
        .then(response => {
          log.trace("handleConfirmDialog.response.data", response.data);
          logEvent(eventSource, "handleConfirmDialog.response");
        })
        .catch(error => {
          log.error("Error requesting action", error);
          logEvent(eventSource, "handleConfirmDialog.error", error);
          setAlertMessage({
            message: t("error.action"),
            severity: "error",
          });
        })
        .finally(() => {
          setDialogConfirmationData({});
          hideLoadingUI();
          fetchData();
        });
    } catch (exception) {
      log.error("Error requesting action", exception);
      logEvent(eventSource, "handleConfirmDialog.exception", exception);
      setAlertMessage({
        message: t("error.action"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleCreateClick = () => {
    log.trace("handleCreateClick");
    navigate("/console/books/" + bookId + "/pages/new/edit");
  };

  const handleEditClick = (event, pageId) => {
    log.trace("handleEditClick", event, bookId, pageId);
    navigate("/console/books/" + bookId + "/pages/" + pageId + "/edit");
  };

  const handleDeleteClick = async (event, pageId, pageNumber) => {
    log.trace("handleDeleteClick", event, pageId, pageNumber);

    setDialogConfirmationData({ uuid: pageId, title: pageNumber });

    handleOpenDialog();
  };

  const showPagePreview = (pageData, state = true) => {
    log.trace("showPagePreview", state);

    setPreviewPageData(pageData);
    setOpenPreview(state);
  };

  const handlePageUpClick = async (event, pageUuid, pageNumber, i) => {
    log.trace("handlePageUpClick", event, pageUuid, pageNumber, i);

    if (pages.length < 2) {
      setAlertMessage({
        message: t("view.pages.error.pageUpDownOnlyOnePage"),
        severity: "warning", //success info warning error
      });
    } else if (pages[0].uuid == pageUuid) {
      // already first page
      setAlertMessage({
        message: t("view.pages.error.pageUp"),
        severity: "warning", //success info warning error
      });
    } else {
      swapPageNumbers(pages[i - 1].uuid, pageUuid);
    }
  };

  const handlePageDownClick = async (event, pageUuid, pageNumber, i) => {
    log.trace("handlePageDownClick", event, pageUuid, pageNumber, i);

    if (pages.length < 2) {
      setAlertMessage({
        message: t("view.pages.error.pageUpDownOnlyOnePage"),
        severity: "warning", //success info warning error
      });
    } else if (pages.at(-1).uuid == pageUuid) {
      // already last page
      setAlertMessage({
        message: t("view.pages.error.pageDown"),
        severity: "warning", //success info warning error
      });
    } else {
      swapPageNumbers(pageUuid, pages[i + 1].uuid);
    }
  };

  return (
    <PageContainer
      title=""
      breadcrumbs={[
        { title: t("text.overview"), path: `/console/books/${pageParams.id}` },
        {
          title: t("text.pages"),
          path: `/console/books/${pageParams.id}/pages`,
        },
      ]}
    >
      <Box component="section" sx={{}}>
        {loading ? (
          <LinearProgress />
        ) : (
          <Box component="section" sx={{ p: 2 }}>
            <Backdrop
              sx={theme => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
              open={openPreview}
            >
              <PagePreview
                openPreview={openPreview}
                pageData={previewPageData}
                locationData={
                  (previewPageData &&
                    previewPageData.location &&
                    locationsList.locations.filter(
                      location => location.name === previewPageData.location
                    )[0]) ||
                  null
                }
                onClick={e => showPagePreview({}, false)}
              />
            </Backdrop>

            {alertMessage.message && (
              <Alert severity={alertMessage.severity}>
                {alertMessage.message}
              </Alert>
            )}

            <ConfirmationDialog
              open={openDialog}
              onClose={handleCloseDialog}
              onConfirm={handleConfirmDialog}
              title="Confirm Deletion"
              message="Warning! This action is irreversible. Please type the page number to confirm the action."
              promptHelperText="Type the page number to confirm."
              confirmationData={dialogConfirmationData}
            />
            <Grid
              container
              rowSpacing={1}
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid size={3}>
                <Button
                  onClick={handleCreateClick}
                  variant="text"
                  sx={{ width: "100%", height: "100%" }}
                >
                  <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AddCircleOutlineOutlinedIcon />
                    CREATE NEW
                  </Stack>
                </Button>
              </Grid>
              {pages.map((page, i) => (
                <Grid size={3} key={page.uuid}>
                  <Card
                    sx={{
                      maxWidth: 345,
                      height: 345,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardMedia
                      sx={{ height: 140 }}
                      image={page.imageUrl}
                      title={page.pageNumber}
                    />
                    <CardContent>
                      <Stack direction="row" spacing={2}>
                        {i !== 0 && pages.length > 1 && (
                          <IconButton
                            onClick={e =>
                              handlePageUpClick(
                                e,
                                page.uuid,
                                page.pageNumber,
                                i
                              )
                            }
                            color="primary"
                            size="small"
                          >
                            <ArrowLeftIcon />
                          </IconButton>
                        )}
                        <Typography gutterBottom variant="h5" component="div">
                          #{truncateIfNeeded(page.pageNumber, 8)}
                        </Typography>
                        {i < pages.length - 1 && (
                          <IconButton
                            onClick={e =>
                              handlePageDownClick(
                                e,
                                page.uuid,
                                page.pageNumber,
                                i
                              )
                            }
                            color="primary"
                            size="small"
                          >
                            <ArrowRightIcon />
                          </IconButton>
                        )}
                      </Stack>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        {truncateIfNeeded(page.ideation)}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ mt: "auto" }}>
                      <IconButton
                        onClick={e => showPagePreview(page)}
                        color="primary"
                        size="small"
                      >
                        <PlayCircleOutlineIcon />
                      </IconButton>
                      <IconButton
                        onClick={e => handleEditClick(e, page.uuid)}
                        color="primary"
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={e =>
                          handleDeleteClick(e, page.uuid, page.pageNumber)
                        }
                        color="primary"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}

export default Pages;
