import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { logEvent } from "../../Services/firebase";
import { requestHeaders, truncateIfNeeded } from "../../Tools/DataUtils";
import log from "../../Tools/Log";
import ConfirmationDialog from "../../UiComponents/ConfirmationDialog";

function Characters() {
  const eventSource = "Characters";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteDialogConfirmationData, setDeleteDialogConfirmationData] =
    useState({});
  const [characters, setCharacters] = useState([]);
  const [bookId] = useState(pageParams.id);

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    fetchData();
  }, []);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      //TODO replace with implementation later
      const filters = {
        title: "search*",
      };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getCharacters`,
          {
            bookUuid: pageParams.id,
            filters,
          },
          await requestHeaders()
        )
        .then(response => {
          log.trace("fetchData.response.data", response.data);
          logEvent(eventSource, "fetchData.response");

          response.data.map(item => {
            item.imageUrl =
              `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
              item.backgroundImage;
          });

          setCharacters(response.data);
        })
        .catch(error => {
          log.error("Error requesting data", error);
          logEvent(eventSource, "fetchData.error", error);
          setAlertMessage({
            message: t("error.dataFetch"),
            severity: "error",
          });
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting data", exception);
      logEvent(eventSource, "fetchData.exception", exception);
      setAlertMessage({
        message: t("error.dataFetch"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleOpenDeleteDialog = () => {
    log.trace("handleOpenDeleteDialog");
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    log.trace("handleCloseDeleteDialog");
    setOpenDeleteDialog(false);
    setDeleteDialogConfirmationData({});
  };

  const handleConfirmDeleteCharacterDialog = async () => {
    log.trace("handleConfirmDeleteCharacterDialog.request");
    logEvent(eventSource, "handleConfirmDeleteCharacterDialog.request");

    handleCloseDeleteDialog();

    try {
      showLoadingUI();

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/deleteCharacter`,
          {
            bookUuid: bookId,
            characterUuid: deleteDialogConfirmationData.uuid,
          },
          await requestHeaders()
        )
        .then(response => {
          log.trace(
            "handleConfirmDeleteCharacterDialog.response.data",
            response.data
          );
          logEvent(eventSource, "handleConfirmDeleteCharacterDialog.response");
        })
        .catch(error => {
          log.error("Error requesting action", error);
          logEvent(
            eventSource,
            "handleConfirmDeleteCharacterDialog.error",
            error
          );
          setAlertMessage({
            message: t("error.action"),
            severity: "error",
          });
        })
        .finally(() => {
          setDeleteDialogConfirmationData({});
          hideLoadingUI();
          fetchData();
        });
    } catch (exception) {
      log.error("Error requesting action", exception);
      logEvent(
        eventSource,
        "handleConfirmDeleteCharacterDialog.exception",
        exception
      );
      setAlertMessage({
        message: t("error.action"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleCreateClick = () => {
    log.trace("handleCreateClick");
    navigate("/console/books/" + bookId + "/characters/new/edit");
  };

  const handleEditClick = (event, characterId) => {
    log.trace("handleEditClick", event, characterId);
    navigate(
      "/console/books/" + bookId + "/characters/" + characterId + "/edit"
    );
  };

  const handleDeleteClick = async (event, characterId, characterName) => {
    log.trace("handleDeleteClick", characterId);
    setDeleteDialogConfirmationData({
      uuid: characterId,
      title: characterName,
    });
    handleOpenDeleteDialog();
  };

  return (
    <PageContainer
      title=""
      breadcrumbs={[
        { title: t("text.overview"), path: `/console/books/${pageParams.id}` },
        {
          title: t("text.characters"),
          path: `/console/books/${pageParams.id}/characters`,
        },
      ]}
    >
      <Box component="section">
        {alertMessage.message && (
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        )}
        {loading ? (
          <LinearProgress />
        ) : (
          <Box component="section" sx={{ p: 2 }}>
            <ConfirmationDialog
              open={openDeleteDialog}
              onClose={handleCloseDeleteDialog}
              onConfirm={handleConfirmDeleteCharacterDialog}
              title={t("deleteConfirmationDialog.title")}
              message={t("deleteConfirmationDialog.character.message")}
              promptHelperText={t(
                "deleteConfirmationDialog.character.helperText"
              )}
              confirmationData={deleteDialogConfirmationData}
            />
            <Grid
              container
              rowSpacing={1}
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid size={3}>
                <Button
                  onClick={handleCreateClick}
                  variant="text"
                  sx={{ width: "100%", height: "100%" }}
                >
                  <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AddCircleOutlineOutlinedIcon />
                    {t("text.createNew").toLocaleUpperCase()}
                  </Stack>
                </Button>
              </Grid>
              {characters.map(character => (
                <Grid size={3} key={character.uuid}>
                  <Card
                    sx={{
                      maxWidth: 345,
                      height: 345,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardMedia
                      sx={{ height: 140 }}
                      image={character.imageUrl}
                      title={character.name}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {character.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        {truncateIfNeeded(character.backstory)}
                      </Typography>
                    </CardContent>

                    <CardActions sx={{ mt: "auto" }}>
                      <IconButton
                        onClick={e => handleEditClick(e, character.uuid)}
                        color="primary"
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={e =>
                          handleDeleteClick(e, character.uuid, character.name)
                        }
                        color="primary"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}

export default Characters;
