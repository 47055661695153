import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import firebaseAuth from "../Services/auth";
import { logEvent } from "../Services/firebase";
import { requestHeaders } from "../Tools/DataUtils";
import log from "../Tools/Log";
import Wizard from "../UiComponents/Wizard";

function Dashboard() {
  const eventSource = "Dashboard";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageParams = useParams();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [dashboardData, setDashboardData] = useState({
    numberOfBooks: 0,
    aiTextGenerationsTotal: 0,
    aiTextGenerationsPerCycle: 0,
    aiImageGenerationsTotal: 0,
    aiImageGenerationsPerCycle: 0,
    aiAudioGenerationsTotal: 0,
    aiAudioGenerationsPerCycle: 0,
  });

  const wizardKey = "isWizardOpen_" + eventSource;
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [wasWizardPresentedOnce, setWasWizardPresentedOnce] = useState(false);
  useEffect(() => {
    log.trace("setItem isWizardOpen", wasWizardPresentedOnce);
    if (wasWizardPresentedOnce === true) {
      localStorage.setItem(wizardKey, JSON.stringify(wasWizardPresentedOnce));
    }
  }, [wasWizardPresentedOnce]);

  const showWizardIfNotSeen = () => {
    const savedWizardState = localStorage.getItem(wizardKey);
    log.trace("showWizardIfNotSeen", savedWizardState);
    if (savedWizardState === null) {
      setIsWizardOpen(true);
    }
  };

  const handleCloseWizard = () => {
    log.trace("handleCloseWizard");
    setIsWizardOpen(false);
    setWasWizardPresentedOnce(true);
  };

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    fetchData();
  }, []);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getDashboard`,
          {},
          await requestHeaders()
        )
        .then(response => {
          log.trace("fetchData.response.data", response.data);
          logEvent(eventSource, "fetchData.response");

          setDashboardData(response.data);
        })
        .catch(error => {
          log.error("Error requesting data", error);
          logEvent(eventSource, "fetchData.error", error);
          setAlertMessage({
            message: t("error.dataFetch"),
            severity: "error",
          });

          //TODO priority should be checked on all pages; how ?
          // if new member or no membership, redirect
          if (error.status == 310) {
            // 310 - not a member
            navigate("/newuserprofile");
          } else if (error.status == 311) {
            // 311 - no membership
            navigate("/newusermembership");
          } else {
            // server error
            log.error(error);
            firebaseAuth.signOut().then(() => {
              navigate("/error");
            });
          }
        })
        .finally(() => {
          hideLoadingUI();
          showWizardIfNotSeen();
        });
    } catch (exception) {
      log.error("Error requesting data", exception);
      logEvent(eventSource, "fetchData.exception", exception);
      setAlertMessage({
        message: t("error.dataFetch"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const Step1 = () => {
    return (
      <Box component="section" sx={{ p: 2 }}>
        <Typography variant="body1">This is your Dashboard.</Typography>
        <Typography variant="body1" sx={{ pt: 2 }}>
          Here you can keep track of your books and the AI Assisted tools you
          have used. It will provide you a high level view of your activities.
        </Typography>
      </Box>
    );
  };

  const Step2 = () => {
    return (
      <Box component="section" sx={{ p: 2 }}>
        <Typography variant="body1">
          The left menu contain important tools that allows you to create,
          publish and evaluate books performance in the mobile apps.
        </Typography>
        <Typography variant="body1" sx={{ pt: 2 }}>
          You can also verify your account and profile information.
        </Typography>
      </Box>
    );
  };

  const Step3 = () => {
    return (
      <Box component="section" sx={{ p: 2 }}>
        <Typography variant="body1">
          If at any moment you need help, you can look for{" "}
          <HelpOutlineIcon sx={{ verticalAlign: "middle" }} /> icons or check
          out the Learning Center.
        </Typography>
        <Typography variant="body1" sx={{ pt: 2, pb: 2 }}>
          Now, go to Books and click{" "}
          <AddCircleOutlineOutlinedIcon sx={{ verticalAlign: "middle" }} />{" "}
          Create New to get started!
        </Typography>
        <Box component="section" sx={{ p: 2, textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("books")}
            startIcon={<MenuBookIcon />}
            sx={{ ml: 1 }}
          >
            Books
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <PageContainer title={t("text.dashboard")} breadcrumbs="">
      <Box component="section" sx={{}}>
        {alertMessage.message && (
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        )}
        {loading ? (
          <LinearProgress />
        ) : (
          <Box component="section">
            <Wizard
              open={isWizardOpen}
              title="Quick Guide"
              onClose={handleCloseWizard}
              totalSteps={3}
              steps={["Your dashboard", "Navigation menu", "More help"]}
              stepsComponents={[<Step1 />, <Step2 />, <Step3 />]}
            ></Wizard>

            <Stack direction="row" spacing={2}>
              <Card
                variant="outlined"
                sx={{
                  textAlign: "center",
                  width: "150px",
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    sx={{ color: "text.secondary", fontSize: 14 }}
                  >
                    {t("text.books").toLocaleUpperCase()}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {dashboardData.numberOfBooks}
                  </Typography>
                </CardContent>
              </Card>

              <Card
                variant="outlined"
                sx={{ textAlign: "center", width: "150px" }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    sx={{ color: "text.secondary", fontSize: 14 }}
                  >
                    {t("text.textGeneration").toLocaleUpperCase()}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {dashboardData.aiTextGenerationsTotal} of{" "}
                    {dashboardData.aiTextGenerationsPerCycle}
                  </Typography>
                </CardContent>
              </Card>

              <Card
                variant="outlined"
                sx={{ textAlign: "center", width: "150px" }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    sx={{ color: "text.secondary", fontSize: 14 }}
                  >
                    {t("text.imageGeneration").toLocaleUpperCase()}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {dashboardData.aiImageGenerationsTotal} of{" "}
                    {dashboardData.aiImageGenerationsPerCycle}
                  </Typography>
                </CardContent>
              </Card>

              <Card
                variant="outlined"
                sx={{ textAlign: "center", width: "150px" }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    sx={{ color: "text.secondary", fontSize: 14 }}
                  >
                    {t("text.audioGeneration").toLocaleUpperCase()}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {dashboardData.aiAudioGenerationsTotal} of{" "}
                    {dashboardData.aiAudioGenerationsPerCycle}
                  </Typography>
                </CardContent>
              </Card>
            </Stack>
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}

export default Dashboard;
