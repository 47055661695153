import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Slider,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LearningAudioDetails from "../Console/Learning/LearningAudioDetails";
import { logEvent } from "../Services/firebase";
import log from "../Tools/Log";

const TimelineDialog = ({
  title,
  message,
  open,
  onClose,
  onContinue,
  onDelete,
  timelineData,
}) => {
  const { t } = useTranslation();

  const [timeline, setTimeline] = useState({
    title: "",
    shortDescription: "",
    details: "",
    order: 0,
  });

  useEffect(() => {
    log.trace("useEffect");

    if (timelineData && Object.keys(timelineData).length > 0) {
      setTimeline(timelineData);
    } else {
      setTimeline({});
    }
  }, [open, timelineData]);

  const handleConfirmation = e => {
    log.trace("handleConfirmation", e);
    onContinue(timeline);
  };

  const handleDialogClose = e => {
    log.trace("handleDialogClose", e);
    onClose(timeline);
  };

  const handleDialogDelete = e => {
    log.trace("handleDialogDelete", e);
    onDelete(timeline);
  };

  const handleInputChange = e => {
    log.trace("handleInputChange", e);
    const { name, value } = e.target;
    setTimeline({ ...timeline, [name]: value });
  };

  const handleFormSubmit = async e => {
    log.trace("handleFormSubmit", e);
    e.preventDefault();
    handleConfirmation();
  };

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <Box component="form" onSubmit={handleFormSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="title"
            name="title"
            label={t("text.title")}
            type="text"
            fullWidth
            value={timeline.title}
            onChange={handleInputChange}
          />
          <TextField
            required
            margin="dense"
            id="shortDescription"
            name="shortDescription"
            label={t("text.shortDescription")}
            type="text"
            fullWidth
            value={timeline.shortDescription}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            multiline
            id="details"
            name="details"
            label={t("text.shortDescription")}
            type="text"
            fullWidth
            value={timeline.details}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit" color="primary">
            {t("buttonAction.continue")}
          </Button>
          <Button
            variant="outlined"
            onClick={handleDialogClose}
            color="secondary"
          >
            {t("buttonAction.cancel")}
          </Button>
          {timelineData && timelineData.uuid && (
            <IconButton onClick={handleDialogDelete} color="error" size="small">
              <DeleteIcon />
            </IconButton>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default TimelineDialog;
