import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { logEvent } from "../../Services/firebase";
import log from "../../Tools/Log";

function LearningAudioDetails() {
  const { t } = useTranslation();

  return (
    <Box sx={{ maxWidth: "500px" }}>
      <Typography variant="h6">
        {t("help.learningAudioDetails.header")}
      </Typography>
      <Divider sx={{ p: 1 }} />
      <Typography variant="subtitle1" sx={{ fontStyle: "italic", p: 2 }}>
        {t("help.learningAudioDetails.definition")}
      </Typography>
      <Typography variant="body1">
        {t("help.learningAudioDetails.impact")}
      </Typography>
      <Typography variant="body1">
        {t("help.learningAudioDetails.callToAction")}
      </Typography>
    </Box>
  );
}

export default LearningAudioDetails;
