import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { logEvent } from "../../Services/firebase";
import { requestHeaders, truncateIfNeeded } from "../../Tools/DataUtils";
import log from "../../Tools/Log";
import ConfirmationDialog from "../../UiComponents/ConfirmationDialog";
import Wizard from "../../UiComponents/Wizard";

function Books() {
  const eventSource = "Books";

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [books, setBooks] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteDialogConfirmationData, setDeleteDialogConfirmationData] =
    useState({});

  const wizardKey = "isWizardOpen_" + eventSource;
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [wasWizardPresentedOnce, setWasWizardPresentedOnce] = useState(false);
  useEffect(() => {
    log.trace("setItem isWizardOpen", wasWizardPresentedOnce);
    if (wasWizardPresentedOnce === true) {
      localStorage.setItem(wizardKey, JSON.stringify(wasWizardPresentedOnce));
    }
  }, [wasWizardPresentedOnce]);

  const showWizardIfNotSeen = () => {
    const savedWizardState = localStorage.getItem(wizardKey);
    log.trace("showWizardIfNotSeen", savedWizardState);
    if (savedWizardState === null) {
      setIsWizardOpen(true);
    }
  };

  const handleCloseWizard = () => {
    log.trace("handleCloseWizard");
    setIsWizardOpen(false);
    setWasWizardPresentedOnce(true);
  };

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    fetchData();

    // const savedWizardState = localStorage.getItem(wizardKey);
    // if (savedWizardState !== null) {
    //   setIsWizardOpen(JSON.parse(savedWizardState));
    // }
  }, []);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      //TODO replace with implementation later
      const filters = {
        title: "search*",
      };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getBooks`,
          { filters },
          await requestHeaders()
        )
        .then(response => {
          log.trace("fetchData.response.data", response.data);
          logEvent(eventSource, "fetchData.response");

          response.data.map(item => {
            item.imageUrl =
              `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
              item.coverImage;
          });

          setBooks(response.data);

          // show wizard only if never showed and there are books to show
          if (response.data.length > 0) {
            showWizardIfNotSeen();
          }
        })
        .catch(error => {
          log.error("Error requesting data", error);
          logEvent(eventSource, "fetchData.error", error);
          setAlertMessage({
            message: t("error.dataFetch"),
            severity: "error",
          });
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting data", exception);
      logEvent(eventSource, "fetchData.exception", exception);
      setAlertMessage({
        message: t("error.dataFetch"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleOpenDeleteDialog = () => {
    log.trace("handleOpenDeleteDialog");
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    log.trace("handleCloseDeleteDialog");
    setOpenDeleteDialog(false);
    setDeleteDialogConfirmationData({});
  };

  const handleConfirmDeleteBookDialog = async () => {
    log.trace("handleConfirmDeleteBookDialog.request");
    logEvent(eventSource, "handleConfirmDeleteBookDialog.request");

    handleCloseDeleteDialog();

    try {
      showLoadingUI();

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/deleteBook`,
          { bookUuid: deleteDialogConfirmationData.uuid },
          await requestHeaders()
        )
        .then(response => {
          log.trace(
            "handleConfirmDeleteBookDialog.response.data",
            response.data
          );
          logEvent(eventSource, "handleConfirmDeleteBookDialog.response");
        })
        .catch(error => {
          log.error("Error requesting action", error);
          logEvent(eventSource, "handleConfirmDeleteBookDialog.error", error);
          setAlertMessage({
            message: t("error.action"),
            severity: "error",
          });
        })
        .finally(() => {
          setDeleteDialogConfirmationData({});
          hideLoadingUI();
          fetchData();
        });
    } catch (exception) {
      log.error("Error requesting action", exception);
      logEvent(
        eventSource,
        "handleConfirmDeleteBookDialog.exception",
        exception
      );
      setAlertMessage({
        message: t("error.action"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleOpenBookClick = (e, bookId, bookTitle) => {
    log.trace("handleOpenBookClick", e, bookId, bookTitle);
    logEvent(eventSource, "openBook", bookId);
    navigate("/console/books/" + bookId, {
      state: { title: bookTitle },
    });
  };

  const handleCreateClick = () => {
    log.trace("handleCreateClick");
    navigate("/console/books/new/edit");
  };

  const handleEditClick = (event, bookId) => {
    log.trace("handleEditClick", event, bookId);
    navigate("/console/books/" + bookId + "/edit");
  };

  const handleShareClick = (event, bookId) => {
    log.trace("handleShareClick", bookId);
  };

  const handleDeleteClick = async (event, bookId, bookTitle) => {
    log.trace("handleDeleteClick", event, bookId, bookTitle);
    setDeleteDialogConfirmationData({ uuid: bookId, title: bookTitle });
    handleOpenDeleteDialog();
  };

  const Step1 = () => {
    return (
      <Box component="section" sx={{ p: 2 }}>
        <Typography variant="body1">
          Here you see a list of all books you created.
        </Typography>
        <Typography variant="body1" sx={{ pt: 2 }}>
          You can work on multiple books at the same time.
        </Typography>
      </Box>
    );
  };

  const Step2 = () => {
    return (
      <Box component="section" sx={{ p: 2 }}>
        <Typography variant="body1">In this page you can.</Typography>
        <List>
          <ListItem>
            <AutoStoriesIcon sx={{ mr: 1 }} />
            <ListItemText primary="Add content to your book, like pages, characters, locations and more." />
          </ListItem>
          <ListItem>
            <EditIcon sx={{ mr: 1 }} />
            <ListItemText primary="Edit the book information, like title, author, description and cover image." />
          </ListItem>
          <ListItem>
            <ShareIcon sx={{ mr: 1 }} />
            <ListItemText primary="Share your book with friends" />
          </ListItem>
          <ListItem>
            <DeleteIcon sx={{ mr: 1 }} />
            <ListItemText primary="Delete a book after confirming it" />
          </ListItem>
        </List>
      </Box>
    );
  };

  const Step3 = () => {
    return (
      <Box component="section" sx={{ p: 2 }}>
        <Typography variant="body1">
          Once your book is ready to go, you can publish it to the mobile apps!
        </Typography>
        <Typography variant="body1" sx={{ pt: 2 }}>
          The apps are free and available on Android and iOS devices.
        </Typography>
        <Typography variant="body1">
          When you're ready to publish, open the book and select Publish.
        </Typography>
      </Box>
    );
  };

  return (
    <PageContainer title={t("text.books")} breadcrumbs="">
      <Box component="section">
        {alertMessage.message && (
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        )}

        {loading ? (
          <LinearProgress />
        ) : (
          <Box component="section" sx={{ p: 2 }}>
            <Wizard
              open={isWizardOpen}
              title="Quick Guide"
              onClose={handleCloseWizard}
              totalSteps={3}
              steps={["Your books", "Actions", "Publishing"]}
              stepsComponents={[<Step1 />, <Step2 />, <Step3 />]}
            />

            <ConfirmationDialog
              open={openDeleteDialog}
              onClose={handleCloseDeleteDialog}
              onConfirm={handleConfirmDeleteBookDialog}
              title={t("deleteConfirmationDialog.title")}
              message={t("deleteConfirmationDialog.book.message")}
              promptHelperText={t("deleteConfirmationDialog.book.helperText")}
              confirmationData={deleteDialogConfirmationData}
            />
            <Grid
              container
              rowSpacing={1}
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid size={3}>
                <Button
                  onClick={handleCreateClick}
                  variant="text"
                  sx={{ width: "100%", height: "100%" }}
                >
                  <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AddCircleOutlineOutlinedIcon />
                    {t("text.createNew").toLocaleUpperCase()}
                  </Stack>
                </Button>
              </Grid>
              {books.map(book => (
                <Grid size={3} key={book.uuid}>
                  <Card
                    sx={{
                      maxWidth: 345,
                      height: 345,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardMedia
                      sx={{ height: 140 }}
                      image={book.imageUrl}
                      title={book.title}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {book.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        {truncateIfNeeded(book.description)}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ mt: "auto" }}>
                      <IconButton
                        onClick={e =>
                          handleOpenBookClick(e, book.uuid, book.title)
                        }
                        color="primary"
                        size="small"
                      >
                        <AutoStoriesIcon />
                      </IconButton>
                      <IconButton
                        onClick={e => handleEditClick(e, book.uuid)}
                        color="primary"
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={e => handleShareClick(e, book.uuid)}
                        color="primary"
                        size="small"
                      >
                        <ShareIcon />
                      </IconButton>
                      <IconButton
                        onClick={e =>
                          handleDeleteClick(e, book.uuid, book.title)
                        }
                        color="primary"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}

export default Books;
