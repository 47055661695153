import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Button,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { logEvent } from "../../Services/firebase";
import {
  formSubmitHeaders,
  getUserId,
  requestHeaders,
} from "../../Tools/DataUtils";
import log from "../../Tools/Log";
import ImageGenerationDialog from "../../UiComponents/ImageGenerationDialog";
import ImagePicker from "../../UiComponents/ImagePicker";
import Wizard from "../../UiComponents/Wizard";

function Book() {
  const eventSource = "Book";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [bookData, setBookData] = useState({});
  const [creatingNew, setCreatingNew] = useState(false);
  const [openImageGenerationDialog, setOpenImageGenerationDialog] =
    useState(false);
  const [imageGenerationOptions, setImageGenerationOptions] = useState({
    imageService: {
      name: `${process.env.REACT_APP_DEFAULT_AI_IMAGE_SERVICE}`,
      endpoint: `${process.env.REACT_APP_DEFAULT_AI_IMAGE_SERVICE_ENDPOINT}`,
    },
    useLocation: true,
    useCharacters: true,
    useImageDescriptorService: true,
  });
  const [openImagePickerDialog, setOpenImagePickerDialog] = useState(false);
  const [imagePickerOptions, setImagePickerOptions] = useState({});

  const wizardKey = "isWizardOpen_" + eventSource;
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [wasWizardPresentedOnce, setWasWizardPresentedOnce] = useState(false);
  useEffect(() => {
    log.trace("setItem isWizardOpen", wasWizardPresentedOnce);
    if (wasWizardPresentedOnce === true) {
      localStorage.setItem(wizardKey, JSON.stringify(wasWizardPresentedOnce));
    }
  }, [wasWizardPresentedOnce]);

  const showWizardIfNotSeen = () => {
    const savedWizardState = localStorage.getItem(wizardKey);
    log.trace("showWizardIfNotSeen", savedWizardState);
    if (savedWizardState === null) {
      setIsWizardOpen(true);
    }
  };

  const handleCloseWizard = () => {
    log.trace("handleCloseWizard");
    setIsWizardOpen(false);
    setWasWizardPresentedOnce(true);
  };

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    if (pageParams.id == "new") {
      log.trace("creating new");
      setCreatingNew(true);
      showWizardIfNotSeen();
    }

    // if not new, request data
    // (React quirk) cannot use creatingNew as it won't be set as yet
    if (pageParams.id != "new") {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getBooks`,
          { filters: { bookUuid: pageParams.id } },
          await requestHeaders()
        )
        .then(response => {
          log.trace("fetchData.response.data", response.data);
          logEvent(eventSource, "fetchData.response");

          response.data.map(item => {
            item.imageUrl =
              `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
              item.coverImage;
          });

          setBookData(response.data[0]);
        })
        .catch(error => {
          log.error("Error requesting data", error);
          logEvent(eventSource, "fetchData.error", error);
          setAlertMessage({
            message: t("error.dataFetch"),
            severity: "error",
          });
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting data", exception);
      logEvent(eventSource, "fetchData.exception", exception);
      setAlertMessage({
        message: t("error.dataFetch"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleFormSubmit = async e => {
    log.trace("handleFormSubmit.request", e, bookData);
    logEvent(eventSource, "handleFormSubmit.request");

    try {
      e.preventDefault();
      showLoadingUI();

      const formData = new FormData();

      formData.append("userId", getUserId());

      if (!creatingNew) {
        formData.append("uuid", bookData.uuid);
      }

      formData.append("title", bookData.title);
      formData.append("author", bookData.author);
      formData.append("description", bookData.description);

      bookData.version && formData.append("version", bookData.version);

      bookData.imagePrompt &&
        formData.append("imagePrompt", bookData.imagePrompt);

      bookData.coverImage && formData.append("coverImage", bookData.coverImage);

      bookData.newCoverImage &&
        formData.append("newCoverImage", bookData.newCoverImage);

      if (creatingNew) {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/putBook`,
            formData,
            await formSubmitHeaders()
          )
          .then(response => {
            log.trace("handleFormSubmit.response.data", response.data);
            logEvent(eventSource, "handleFormSubmit.response");
            navigate("/console/books");
          })
          .catch(error => {
            log.error("Error requesting action", error);
            logEvent(eventSource, "handleFormSubmit.error", error);
            setAlertMessage({
              message: t("error.action"),
              severity: "error",
            });
          })
          .finally(() => {
            hideLoadingUI();
          });
      } else {
        axios
          .put(
            `${process.env.REACT_APP_API_URL}/putBook`,
            formData,
            await formSubmitHeaders()
          )
          .then(response => {
            log.trace("handleFormSubmit.response.data", response.data);
            logEvent(eventSource, "handleFormSubmit.response");
            navigate("/console/books");
          })
          .catch(error => {
            log.error("Error requesting action", error);
            logEvent(eventSource, "handleFormSubmit.error", error);
            setAlertMessage({
              message: t("error.action"),
              severity: "error",
            });
          })
          .finally(() => {
            hideLoadingUI();
          });
      }
    } catch (exception) {
      log.error("Error requesting action", exception);
      logEvent(eventSource, "handleFormSubmit.exception", exception);
      setAlertMessage({
        message: t("error.action"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleInputChange = e => {
    log.trace("handleInputChange", e);

    const { name, value } = e.target;
    setBookData({ ...bookData, [name]: value });
  };

  const handleFileChange = e => {
    log.trace("handleFileChange", e);

    URL.revokeObjectURL(bookData.imageUrl);
    const previewImageUrl = URL.createObjectURL(e.target.files[0]);

    setBookData({
      ...bookData,
      newCoverImage: e.target.files[0],
      coverImage: e.target.files[0].name,
      imageUrl: previewImageUrl,
      imagePrompt: null,
    });
  };

  const handleCancel = () => {
    log.trace("handleCancel");
    navigate("/console/books");
  };

  const handleBookCoverLoaded = e => {
    log.trace("handleBookCoverLoaded", e);
    // const img = e.target;
    // TODO warn of best options for image format, ration, etc
    // BE uses sharp; converts to web friendly format automatically
  };

  const suggestCoverImageFromBookDescription = async selectedOptionsData => {
    log.trace(
      "suggestCoverImageFromBookDescription.request",
      selectedOptionsData
    );
    logEvent(eventSource, "suggestCoverImageFromBookDescription.request");

    if (!bookData.description || bookData.description.length == 0) {
      setAlertMessage({
        message: t("error.suggestCoverImageMissingParameters"),
        severity: "error",
      });

      return;
    }

    showLoadingUI();

    try {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/${selectedOptionsData.imageService.endpoint}`,
          {
            bookUuid: pageParams.id,
            narrativePrompt: bookData.description,
            options: selectedOptionsData,
          },
          await requestHeaders()
        )
        .then(response => {
          log.trace(
            "suggestCoverImageFromBookDescription.response.data",
            response.data
          );
          logEvent(
            eventSource,
            "suggestCoverImageFromBookDescription.response"
          );

          const imageUrl =
            `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
            response.data.filePath;

          //set form data with image generated. image is already stored, so just save the file path
          setBookData({
            ...bookData,
            imagePrompt: response.data.imagePrompt,
            coverImage: response.data.filePath,
            imageUrl: imageUrl,
          });
        })
        .catch(error => {
          log.error("Error requesting action", error);
          logEvent(
            eventSource,
            "suggestCoverImageFromBookDescription.error",
            error
          );

          if (error.status == 460) {
            setAlertMessage({
              message: t("error.membershipGenerationLimitsReached"),
              severity: "error",
            });
          } else {
            setAlertMessage({
              message: t("error.imageGenerationGeneric"),
              severity: "error",
            });
          }
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting action", exception);
      logEvent(
        eventSource,
        "suggestCoverImageFromBookDescription.exception",
        exception
      );
      setAlertMessage({
        message: t("error.imageGenerationGeneric"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleOpenImageGenerationDialog = () => {
    log.trace("handleOpenImageGenerationDialog");
    logEvent(eventSource, "handleOpenImageGenerationDialog");

    setImageGenerationOptions({
      ...imageGenerationOptions,
      useCharacters: "hidden",
      useLocation: "hidden",
    });

    setOpenImageGenerationDialog(true);
  };

  const handleCloseImageGenerationDialog = selectedOptionsData => {
    log.trace("handleCloseImageGenerationDialog", selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);
    setOpenImageGenerationDialog(false);
  };

  const handleContinueImageGeneration = async selectedOptionsData => {
    log.trace("handleContinueImageGeneration", selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);
    setOpenImageGenerationDialog(false);
    suggestCoverImageFromBookDescription(selectedOptionsData);
  };

  const handleOpenImagePickerDialog = () => {
    log.trace("handleOpenImagePickerDialog");
    setOpenImagePickerDialog(true);
  };

  const handleCloseImagePickerDialog = selectedOptionsData => {
    log.trace("handleCloseImagePickerDialog", selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);
    setOpenImagePickerDialog(false);
  };

  const handleContinueImagePicker = async selectedOptionsData => {
    log.trace("handleContinueImagePicker", selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);
    setOpenImagePickerDialog(false);

    //from computer or from gallery, ...
    if (!selectedOptionsData) {
      log.trace("handleContinueImagePicker.fromComputer");
      logEvent(eventSource, "handleContinueImagePicker.fromComputer");
      imagePickerSelectFromComputer();
    } else {
      log.trace("handleContinueImagePicker.fromGallery");
      logEvent(eventSource, "handleContinueImagePicker.fromGallery");
      const filePath = selectedOptionsData.filePath;
      const imagePrompt = selectedOptionsData.imagePrompt;
      const imageUrl = selectedOptionsData.imageUrl;

      //set form data with image previously generated. image is already stored, so just get the file path
      setBookData({
        ...bookData,
        imagePrompt: imagePrompt,
        coverImage: filePath,
        imageUrl: imageUrl,
        newCoverImage: null,
      });
    }
  };

  const imagePickerSelectFromComputer = () => {
    log.trace("imagePickerSelectFromComputer");
    uploadInputRef.current && uploadInputRef.current.click();
  };

  const Step1 = () => {
    return (
      <Box component="section" sx={{ p: 2 }}>
        <Typography variant="body1" sx={{ pt: 2 }}>
          Enter the book name, author name and a brief explanation of what the
          book is all about.
        </Typography>
        <Typography variant="body1" sx={{ pt: 2 }}>
          This is similar to the information you see in a book back cover.
        </Typography>
      </Box>
    );
  };

  const Step2 = () => {
    return (
      <Box component="section" sx={{ p: 2 }}>
        <Typography variant="body1">
          You can now select a cover image for your book.
        </Typography>
        <Typography variant="body1" sx={{ pt: 2 }}>
          There are multiple options:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="To upload from your computer or select from your Libell.us library, just click Select Cover Image." />
          </ListItem>
          <ListItem>
            <ListItemText primary="To use the AI Assisted tool to generate an image for you, click on Suggest Cover Image." />
          </ListItem>
        </List>
        <Typography variant="body1">
          Once you're done editing, just click Save.
        </Typography>
      </Box>
    );
  };

  const uploadInputRef = useRef(null);

  return (
    <PageContainer
      title=""
      breadcrumbs={[
        { title: t("text.books"), path: "/console/books" },
        { title: t("text.book"), path: `/console/books/${pageParams.id}` },
      ]}
    >
      <Box component="section">
        {alertMessage.message && (
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        )}

        {loading ? (
          <LinearProgress />
        ) : (
          <Box component="section" sx={{ p: 2 }}>
            <Wizard
              open={isWizardOpen}
              title="Quick Guide"
              onClose={handleCloseWizard}
              totalSteps={2}
              steps={["Book Information", "Selecting a cover"]}
              stepsComponents={[<Step1 />, <Step2 />]}
            />

            <ImageGenerationDialog
              title={t("imageGenerationDialog.title")}
              message={t("imageGenerationDialog.message")}
              open={openImageGenerationDialog}
              onClose={handleCloseImageGenerationDialog}
              onContinue={handleContinueImageGeneration}
              optionsData={imageGenerationOptions}
            />

            <ImagePicker
              title={t("imagePicker.title")}
              message={t("imagePicker.message")}
              open={openImagePickerDialog}
              onClose={handleCloseImagePickerDialog}
              onContinue={handleContinueImagePicker}
              optionsData={imagePickerOptions}
            />

            <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 1 }}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="book-details-content"
                  id="book-details-header"
                >
                  {t("header.bookDetails")}
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2} direction="column" sx={{ p: 2 }}>
                    <TextField
                      id="title"
                      name="title"
                      fullWidth
                      required
                      value={bookData.title}
                      onChange={handleInputChange}
                      label={t("text.title")}
                      variant="outlined"
                    />
                    {!creatingNew && bookData.version && (
                      <Box sx={{ textAlign: "right" }}>
                        <Typography variant="caption" gutterBottom>
                          {t("text.version")} {Math.ceil(bookData.version)}
                        </Typography>
                      </Box>
                    )}
                    <TextField
                      id="author"
                      name="author"
                      fullWidth
                      required
                      value={bookData.author}
                      onChange={handleInputChange}
                      label={t("text.author")}
                      variant="outlined"
                    />
                    <TextField
                      id="description"
                      name="description"
                      fullWidth
                      required
                      value={bookData.description}
                      onChange={handleInputChange}
                      multiline
                      maxRows={20}
                      label={t("text.description")}
                      variant="outlined"
                    />
                    <Button
                      variant="text"
                      onClick={handleOpenImageGenerationDialog}
                      startIcon={<AutoFixHighIcon />}
                    >
                      {t("buttonAction.suggestCoverImage")}
                    </Button>
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="images-content"
                  id="images-header"
                >
                  {t("header.media")}
                </AccordionSummary>
                <AccordionDetails>
                  <Box component="section" sx={{ p: 2 }}>
                    <Stack direction="row" justifyContent="center">
                      <Button
                        onClick={handleOpenImagePickerDialog}
                        variant="contained"
                      >
                        {t("buttonAction.selectCoverImage")}
                      </Button>
                    </Stack>

                    {bookData.coverImage && (
                      <Box sx={{ pt: 2 }}>
                        <Avatar
                          alt={bookData.coverImage}
                          src={bookData.imageUrl}
                          variant="square"
                          sx={{ width: "100%", height: "30%" }}
                          onLoad={handleBookCoverLoaded}
                        />
                        <Typography
                          variant="body2"
                          id="coverImage"
                          name="coverImage"
                          sx={{ textAlign: "right" }}
                        >
                          {bookData.coverImage}
                        </Typography>
                      </Box>
                    )}
                    <input
                      id="newCoverImage"
                      name="newCoverImage"
                      ref={uploadInputRef}
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Stack
                spacing={2}
                direction="row"
                sx={{ p: 2 }}
                justifyContent="center"
              >
                <Button variant="contained" type="submit" color="primary">
                  {t("buttonAction.save")}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancel}
                >
                  {t("buttonAction.cancel")}
                </Button>
              </Stack>
            </Box>
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}

export default Book;
