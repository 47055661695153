import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  LinearProgress,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { logEvent } from "../../Services/firebase";
import { requestHeaders } from "../../Tools/DataUtils";
import log from "../../Tools/Log";

function UserFeedback() {
  const eventSource = "UserFeedback";

  const { i18n, t } = useTranslation();
  const [alertMessage, setAlertMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const [statsData, setStatsData] = useState([]);
  //const [totalAggregator, setTotalAggregator] = useState({});

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    fetchData();
  }, []);

  const fetchData = async (isChained = false) => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      if (!isChained) showLoadingUI();

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getUserFeedback`,
          {},
          await requestHeaders()
        )
        .then(response => {
          log.trace("fetchData.response.data", response.data);
          logEvent(eventSource, "fetchData.response");

          setStatsData(response.data);
        })
        .catch(error => {
          log.error("Error requesting data", error);
          logEvent(eventSource, "fetchData.error", error);
          setAlertMessage({
            message: t("error.dataFetch"),
            severity: "error",
          });
        })
        .finally(() => {
          if (!isChained) hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting data", exception);
      logEvent(eventSource, "fetchData.exception", exception);
      setAlertMessage({
        message: t("error.dataFetch"),
        severity: "error",
      });
      if (!isChained) hideLoadingUI();
    }
  };

  return (
    <PageContainer title={t("text.userFeedback")} breadcrumbs="">
      <Box component="section">
        {alertMessage.message && (
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        )}
        {loading ? (
          <LinearProgress />
        ) : (
          <Box component="section">
            {statsData.length === 0 && (
              <Typography variant="body1">
                {t("view.userFeedback.text.noDataToPresent")}
              </Typography>
            )}

            {statsData.map((stats, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="stats-details-content"
                  id="stats-details-header"
                >
                  {stats.metadata.title}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid size={6}>
                      <Typography variant="body1">
                        {t("view.userFeedback.text.rating").toLocaleUpperCase()}
                      </Typography>
                      <Typography variant="body2">
                        {stats.rating ?? "-"}
                      </Typography>
                    </Grid>
                    <Grid size={6}>
                      <Typography variant="body1">
                        {t(
                          "view.userFeedback.text.totalRatings"
                        ).toLocaleUpperCase()}
                      </Typography>
                      <Typography variant="body2">
                        {stats.totalRatings ?? "-"}
                      </Typography>
                    </Grid>
                    <Grid size={2}>
                      <Typography variant="body1">
                        {t(
                          "view.userFeedback.text.ratings5"
                        ).toLocaleUpperCase()}
                      </Typography>
                      <Typography variant="body2">
                        {stats.ratings5 ?? "-"}
                      </Typography>
                    </Grid>
                    <Grid size={2}>
                      <Typography variant="body1">
                        {t(
                          "view.userFeedback.text.ratings4"
                        ).toLocaleUpperCase()}
                      </Typography>
                      <Typography variant="body2">
                        {stats.ratings4 ?? "-"}
                      </Typography>
                    </Grid>
                    <Grid size={2}>
                      <Typography variant="body1">
                        {t(
                          "view.userFeedback.text.ratings3"
                        ).toLocaleUpperCase()}
                      </Typography>
                      <Typography variant="body2">
                        {stats.ratings3 ?? "-"}
                      </Typography>
                    </Grid>
                    <Grid size={2}>
                      <Typography variant="body1">
                        {t(
                          "view.userFeedback.text.ratings2"
                        ).toLocaleUpperCase()}
                      </Typography>
                      <Typography variant="body2">
                        {stats.ratings2 ?? "-"}
                      </Typography>
                    </Grid>
                    <Grid size={2}>
                      <Typography variant="body1">
                        {t(
                          "view.userFeedback.text.ratings1"
                        ).toLocaleUpperCase()}
                      </Typography>
                      <Typography variant="body2">
                        {stats.ratings1 ?? "-"}
                      </Typography>
                    </Grid>
                    <Grid size={2}>
                      <Typography variant="body1">
                        {t(
                          "view.userFeedback.text.reports"
                        ).toLocaleUpperCase()}
                      </Typography>
                      <Typography variant="body2">
                        {stats.totalReports ?? "-"}{" "}
                        {stats.totalReports > 0 && (
                          <WarningAmberIcon fontSize="small" />
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}

export default UserFeedback;
