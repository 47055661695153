import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  LinearProgress,
  Stack,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import firebaseAuth from "../Services/auth";
import { logEvent } from "../Services/firebase";
import {
  formSubmitHeaders,
  requestHeaders,
  truncateIfNeeded,
} from "../Tools/DataUtils";
import log from "../Tools/Log";

function NewUserProfile() {
  const eventSource = "NewUserProfile";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [userProfileData, setUserProfileData] = useState({
    displayName: "",
    email: "",
    emailVerified: "",
    penName: "",
    customerId: "",
    membership: {},
  });

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    setUserProfileData({
      displayName: firebaseAuth.currentUser.displayName,
      email: firebaseAuth.currentUser.email,
      emailVerified: firebaseAuth.currentUser.emailVerified,
      penName: "",
      membership: {},
    });
  }, []);

  const handleFormSubmit = async e => {
    log.trace("handleFormSubmit.request", e, firebaseAuth?.currentUser);
    logEvent(eventSource, "handleFormSubmit.request");

    try {
      e.preventDefault();
      showLoadingUI();

      const formData = new FormData();

      formData.append("userId", firebaseAuth.currentUser.uid);
      formData.append("displayName", userProfileData.displayName);
      formData.append("email", userProfileData.email);
      formData.append("emailVerified", userProfileData.emailVerified);

      if (userProfileData.penName) {
        formData.append("penName", userProfileData.penName);
      }

      axios
        .put(
          `${process.env.REACT_APP_API_URL}/putUser`,
          formData,
          await formSubmitHeaders()
        )
        .then(response => {
          log.trace("handleFormSubmit.response.data", response.data);
          logEvent(eventSource, "handleFormSubmit.response");
          setUserProfileData({});
          navigate("/newusermembership");
        })
        .catch(error => {
          log.error("Error requesting action", error);
          logEvent(eventSource, "handleFormSubmit.error", error);
          setAlertMessage({
            message: t("error.action"),
            severity: "error",
          });
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting action", exception);
      logEvent(eventSource, "handleFormSubmit.exception", exception);
      setAlertMessage({
        message: t("error.action"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleInputChange = e => {
    log.trace("handleInputChange.request", e);
    const { name, value } = e.target;
    setUserProfileData({ ...userProfileData, [name]: value });
  };

  const handleCancel = () => {
    log.trace("handleCancel");
    navigate("/logout");
  };

  return (
    <Box component="section" sx={{}}>
      {alertMessage.message && (
        <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
      )}

      {loading ? (
        <LinearProgress />
      ) : (
        <Box component="section" sx={{ p: 2 }}>
          <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 1 }}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="book-details-content"
                id="book-details-header"
              >
                {t("header.createProfile")}
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2} direction="column" sx={{ p: 2 }}>
                  <TextField
                    id="name"
                    name="name"
                    fullWidth
                    required
                    readonly
                    value={userProfileData.displayName}
                    label={t("text.displayName")}
                    variant="filled"
                  />
                  <TextField
                    id="email"
                    name="email"
                    fullWidth
                    readonly
                    required
                    value={userProfileData.email}
                    label={t("text.email")}
                    variant="filled"
                  />
                  <TextField
                    id="penName"
                    name="penName"
                    fullWidth
                    value={userProfileData.penName}
                    onChange={handleInputChange}
                    multiline
                    maxRows={20}
                    label={t("text.penName")}
                    variant="outlined"
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Stack spacing={2} direction="row" sx={{ p: 2 }}>
              <Button variant="contained" type="submit" color="primary">
                {t("buttonAction.next")}
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                {t("buttonAction.cancel")}
              </Button>
            </Stack>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default NewUserProfile;
