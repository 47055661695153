import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  Item,
  Menu,
  Separator,
  Submenu,
  useContextMenu,
} from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { logEvent } from "../../Services/firebase";
import { formSubmitHeaders, requestHeaders } from "../../Tools/DataUtils";
import log from "../../Tools/Log";
import AudioGenerationDialog from "../../UiComponents/AudioGenerationDialog";
import ImageGenerationDialog from "../../UiComponents/ImageGenerationDialog";
import ImagePicker from "../../UiComponents/ImagePicker";
import TextGenerationDialog from "../../UiComponents/TextGenerationDialog";
import PagePreview from "./PagePreview";

function Page() {
  const eventSource = "Page";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [pageData, setPageData] = useState({});
  const [creatingNew, setCreatingNew] = useState(false);
  const [openPreview, setOpenPreview] = React.useState(false);
  const [charactersList, setCharactersList] = useState({ options: [] });
  const [locationsList, setLocationsList] = useState({ options: [] });

  const [openTextGenerationDialog, setOpenTextGenerationDialog] =
    useState(false);
  const [textGenerationOptions, setTextGenerationOptions] = useState({
    useLocation: true,
    useCharacters: true,
    usePreviousPagesContext: true,
  });

  const [openImageGenerationDialog, setOpenImageGenerationDialog] =
    useState(false);
  const [imageGenerationOptions, setImageGenerationOptions] = useState({
    imageService: {
      name: `${process.env.REACT_APP_DEFAULT_AI_IMAGE_SERVICE}`,
      endpoint: `${process.env.REACT_APP_DEFAULT_AI_IMAGE_SERVICE_ENDPOINT}`,
    },
    useLocation: true,
    useCharacters: true,
    useImageDescriptorService: true,
  });

  const [openAudioGenerationDialog, setOpenAudioGenerationDialog] =
    useState(false);
  // TODO set default in env
  const [audioGenerationOptions, setAudioGenerationOptions] = useState({
    voiceType: {
      label: "Studio (Male Q)",
      name: "en-US-Studio-Q",
      gender: "MALE",
      isConfigurable: true,
    },
    speakingRate: 1,
    pitch: 0,
  });

  const [openImagePickerDialog, setOpenImagePickerDialog] = useState(false);
  const [imagePickerOptions, setImagePickerOptions] = useState({});

  const CONTEXT_MENU_ID = "editor-context-menu";
  const { show } = useContextMenu({
    id: CONTEXT_MENU_ID,
  });

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    if (pageParams.pid == "new") {
      log.trace("creating new");
      setCreatingNew(true);
      setPageData({ ...pageData, bookUuid: pageParams.id });
    }

    fetchData(pageParams.pid == "new");
  }, []);

  const fetchData = async isNewPage => {
    log.trace("fetchData.request", isNewPage, pageParams.pid);
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      // TODO implement filters
      const filters = { pageUuid: pageParams.pid };

      // TODO priority run requests in parallel (futures)

      // load page if not creating a new one
      if (!isNewPage) {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/getPages`,
            {
              bookUuid: pageParams.id,
              filters,
            },
            await requestHeaders()
          )
          .then(response => {
            log.trace("fetchData.response.data", response.data);
            logEvent(eventSource, "fetchData.response");

            response.data.map(item => {
              item.backgroundImage &&
                (item.imageUrl =
                  `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                  item.backgroundImage);

              item.narrativeAudio &&
                (item.audioUrl =
                  `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                  item.narrativeAudio);
            });

            if (response.data[0]) {
              if (!response.data[0].characters) {
                response.data[0].characters = [];
              }

              setPageData(response.data[0]);
            }
          })
          .catch(error => {
            log.error("Error requesting data", error);
            logEvent("Book", "fetchData.error", error);
            setAlertMessage({
              message: t("error.dataFetch"),
              severity: "error",
            });
          })
          .finally(() => {
            // hideLoadingUI();
          });
      }

      // load characters dropdown data
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getCharacters`,
          {
            bookUuid: pageParams.id,
            filters,
          },
          await requestHeaders()
        )
        .then(response => {
          log.trace("fetchData.response.data", response.data);
          logEvent(eventSource, "fetchData.response");

          response.data.map(item => {
            item.imageUrl =
              `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
              item.backgroundImage;
          });

          if (response.data && response.data.length > 0) {
            log.trace("Loading characters list");
            setCharactersList({
              characters: response.data,
              options: response.data.map(item => item.name),
            });
          } else {
            log.trace("No characters available; useCharacters false");
            setImageGenerationOptions({
              ...imageGenerationOptions,
              useCharacters: false,
            });
          }
        })
        .catch(error => {
          log.error("Error requesting data", error);
          logEvent("Book", "fetchData.error", error);
          setAlertMessage({
            message: t("error.dataFetch"),
            severity: "error",
          });
        })
        .finally(() => {
          // hideLoadingUI();
        });

      // load locations dropdown data
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getLocations`,
          {
            bookUuid: pageParams.id,
            filters,
          },
          await requestHeaders()
        )
        .then(response => {
          log.trace("fetchData.response.data", response.data);
          logEvent(eventSource, "fetchData.response");

          response.data.map(item => {
            item.imageUrl =
              `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
              item.backgroundImage;
          });

          if (response.data && response.data.length > 0) {
            log.trace("Loading locations list");
            setLocationsList({
              locations: response.data,
              options: response.data.map(item => item.name),
            });
          } else {
            log.trace("No location available; useLocation false");
            setImageGenerationOptions({
              ...imageGenerationOptions,
              useLocation: false,
            });
          }
        })
        .catch(error => {
          log.error("Error requesting data", error);
          logEvent(eventSource, "fetchData.error", error);
          setAlertMessage({
            message: t("error.dataFetch"),
            severity: "error",
          });
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting data", exception);
      logEvent(eventSource, "fetchData.exception", exception);
      setAlertMessage({
        message: t("error.dataFetch"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleFormSubmit = async e => {
    log.trace("handleFormSubmit.request", e, pageData);
    logEvent(eventSource, "handleFormSubmit.request");

    try {
      e.preventDefault();
      showLoadingUI();

      const formData = new FormData();

      if (!creatingNew) {
        formData.append("uuid", pageData.uuid);
      }

      formData.append("bookUuid", pageParams.id);
      formData.append("ideation", pageData.ideation);
      formData.append("narrative", pageData.narrative);
      formData.append("characters", pageData.characters ?? []);

      pageData.imagePrompt &&
        formData.append("imagePrompt", pageData.imagePrompt);

      pageData.pageNumber && formData.append("pageNumber", pageData.pageNumber);

      pageData.location && formData.append("location", pageData.location);

      pageData.narrativeAudio &&
        formData.append("narrativeAudio", pageData.narrativeAudio);

      pageData.audioVoiceType &&
        formData.append(
          "audioVoiceType",
          JSON.stringify(pageData.audioVoiceType)
        );

      pageData.backgroundImage &&
        formData.append("backgroundImage", pageData.backgroundImage);

      pageData.newBackgroundImage &&
        formData.append("newBackgroundImage", pageData.newBackgroundImage);

      if (creatingNew) {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/putPage`,
            formData,
            await formSubmitHeaders()
          )
          .then(response => {
            log.trace("handleFormSubmit.response.data", response.data);
            logEvent(eventSource, "handleFormSubmit.response");
            setPageData({});
            navigate(`/console/books/${pageParams.id}/pages`);
          })
          .catch(error => {
            log.error("Error requesting action", error);
            logEvent(eventSource, "handleFormSubmit.error", error);
            setAlertMessage({
              message: t("error.action"),
              severity: "error",
            });
          })
          .finally(() => {
            hideLoadingUI();
          });
      } else {
        axios
          .put(
            `${process.env.REACT_APP_API_URL}/putPage`,
            formData,
            await formSubmitHeaders()
          )
          .then(response => {
            log.trace("handleFormSubmit.response.data", response.data);
            setPageData({});
            navigate(`/console/books/${pageParams.id}/pages`);
          })
          .catch(error => {
            log.error("Error requesting action", error);
            logEvent(eventSource, "handleFormSubmit.error", error);
            setAlertMessage({
              message: t("error.action"),
              severity: "error",
            });
          })
          .finally(() => {
            hideLoadingUI();
          });
      }
    } catch (exception) {
      log.error("Error requesting action", exception);
      logEvent("Book", "handleFormSubmit.exception", exception);
      setAlertMessage({
        message: t("error.action"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleInputChange = e => {
    log.trace("handleInputChange", e);
    const { name, value } = e.target;
    setPageData({ ...pageData, [name]: value });
  };

  const handleFileChange = e => {
    log.trace("handleFileChange", e);

    if (e.target.files[0]) {
      URL.revokeObjectURL(pageData.imageUrl);
      const previewImageUrl = URL.createObjectURL(e.target.files[0]);

      setPageData({
        ...pageData,
        newBackgroundImage: e.target.files[0],
        backgroundImage: e.target.files[0].name,
        imageUrl: previewImageUrl,
        imagePrompt: null,
      });
    }
  };

  const handleCancel = () => {
    log.trace("handleCancel");

    navigate(`/console/books/${pageParams.id}/pages`);
  };

  const handlePageBackgroundImageLoaded = e => {
    log.trace("handlePageBackgroundImageLoaded", e);
    // const img = e.target;
    // TODO warn of best options for image format, ration, etc
    // BE uses sharp; converts to web friendly format automatically
  };

  const suggestPageNarrativeFromIdeation = async selectedOptionsData => {
    log.trace("suggestPageNarrativeFromIdeation.request", selectedOptionsData);
    logEvent(eventSource, "suggestPageNarrativeFromIdeation.request");

    if (!pageData.ideation || pageData.ideation.length == 0) {
      setAlertMessage({
        message: t("error.suggestPageNarrativeMissingParameters"),
        severity: "error", //success info warning error
      });

      return;
    }

    showLoadingUI();

    try {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/suggestNarrativeBasedOnIdeation`,
          {
            bookUuid: pageParams.id,
            ideationPrompt: pageData.ideation,
            location: pageData.location,
            characters: pageData.characters,
            options: selectedOptionsData,
          },
          await requestHeaders()
        )
        .then(generatedText => {
          log.trace(
            "suggestPageNarrativeFromIdeation.response.data",
            generatedText.data
          );
          logEvent(eventSource, "suggestPageNarrativeFromIdeation.response");

          setPageData({ ...pageData, narrative: generatedText.data.text });
        })
        .catch(error => {
          log.error("Error requesting action", error);
          logEvent(
            eventSource,
            "suggestPageNarrativeFromIdeation.error",
            error
          );

          if (error.status == 460) {
            setAlertMessage({
              message: t("error.membershipGenerationLimitsReached"),
              severity: "error", //success info warning error
            });
          } else {
            setAlertMessage({
              message: t("error.pageNarrativeGeneric"),
              severity: "error", //success info warning error
            });
          }
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting action", exception);
      logEvent(
        eventSource,
        "suggestPageNarrativeFromIdeation.exception",
        exception
      );
      setAlertMessage({
        message: t("error.pageNarrativeGeneric"),
        severity: "error", //success info warning error
      });
      hideLoadingUI();
    }
  };

  const suggestBackgroundImageFromPageNarrative = async selectedOptionsData => {
    log.trace(
      "suggestBackgroundImageFromPageNarrative.request",
      selectedOptionsData
    );
    logEvent(eventSource, "suggestBackgroundImageFromPageNarrative.request");

    if (
      (!pageData.ideation || pageData.ideation.length == 0) &&
      (!pageData.narrative || pageData.narrative.length == 0)
    ) {
      setAlertMessage({
        message: t("error.suggestBackgroundImageMissingParameters"),
        severity: "error", //success info warning error
      });

      return;
    }

    showLoadingUI();

    try {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/${selectedOptionsData.imageService.endpoint}`,
          {
            bookUuid: pageParams.id,
            narrativePrompt: pageData.narrative ?? pageData.ideation,
            location: pageData.location,
            characters: pageData.characters,
            options: selectedOptionsData,
          },
          await requestHeaders()
        )
        .then(response => {
          log.trace(
            "suggestBackgroundImageFromPageNarrative.response.data",
            response.data
          );
          logEvent(
            eventSource,
            "suggestBackgroundImageFromPageNarrative.response"
          );

          const imageUrl =
            `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
            response.data.filePath;

          //set form data with image generated. image is already stored, so just save the file path
          setPageData({
            ...pageData,
            imagePrompt: response.data.imagePrompt,
            backgroundImage: response.data.filePath,
            imageUrl: imageUrl,
          });
        })
        .catch(error => {
          log.error("Error requesting action", error);
          logEvent(
            eventSource,
            "suggestBackgroundImageFromPageNarrative.error",
            error
          );

          if (error.status == 460) {
            setAlertMessage({
              message: t("error.membershipGenerationLimitsReached"),
              severity: "error", //success info warning error
            });
          } else {
            setAlertMessage({
              message: t("error.imageGenerationGeneric"),
              severity: "error", //success info warning error
            });
          }
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting action", exception);
      logEvent(
        eventSource,
        "suggestBackgroundImageFromPageNarrative.exception",
        exception
      );
      setAlertMessage({
        message: t("error.imageGenerationGeneric"),
        severity: "error", //success info warning error
      });
      hideLoadingUI();
    }
  };

  const suggestPageNarrationAudio = async selectedOptionsData => {
    log.trace("suggestPageNarrationAudio.request", selectedOptionsData);
    logEvent(eventSource, "suggestPageNarrationAudio.request");

    try {
      if (!pageData.narrative || pageData.narrative.length == 0) {
        setAlertMessage({
          message: t("error.suggestPageNarrationAudioMissingParameters"),
          severity: "error", //success info warning error
        });

        return;
      }

      showLoadingUI();

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/suggestNarrationBasedOnNarrative`,
          {
            bookUuid: pageParams.id,
            narrativePrompt: pageData.narrative,
            options: selectedOptionsData,
          },
          await requestHeaders()
        )
        .then(generatedAudioFilePath => {
          log.trace(
            "suggestPageNarrationAudio.response.data",
            generatedAudioFilePath.data
          );
          logEvent(eventSource, "suggestPageNarrationAudio.response");

          const audioUrl =
            `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
            generatedAudioFilePath.data;

          //set form data with image generated. image is already stored, so just save the file path
          setPageData({
            ...pageData,
            narrativeAudio: generatedAudioFilePath.data,
            audioUrl: audioUrl,
            audioVoiceType: selectedOptionsData.voiceType,
          });
        })
        .catch(error => {
          log.error("Error requesting action", error);
          logEvent(eventSource, "suggestPageNarrationAudio.error", error);

          if (error.status == 460) {
            setAlertMessage({
              message: t("error.membershipGenerationLimitsReached"),
              severity: "error", //success info warning error
            });
          } else {
            setAlertMessage({
              message: t("error.audioGenerationGeneric"),
              severity: "error", //success info warning error
            });
          }
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting action", exception);
      logEvent(eventSource, "suggestPageNarrationAudio.exception", exception);
      setAlertMessage({
        message: t("error.audioGenerationGeneric"),
        severity: "error", //success info warning error
      });
      hideLoadingUI();
    }
  };

  const removePageNarrationAudio = async () => {
    log.trace("removePageNarrationAudio");
    logEvent(eventSource, "removePageNarrationAudio");
    setPageData({ ...pageData, narrativeAudio: null, audioUrl: null });
  };

  const showPagePreview = async (state = true) => {
    log.trace("showPagePreview", state);
    logEvent(eventSource, "showPagePreview");
    setOpenPreview(state);
  };

  const handleCharacterInputChange = (event, value) => {
    log.trace("handleCharacterInputChange", event, value);

    setAlertMessage({});
    setPageData({ ...pageData, characters: value });
  };

  const handleCharacterClick = (event, value) => {
    log.trace("handleCharacterClick", value);
  };

  const handleLocationInputChange = (event, value) => {
    log.trace("handleLocationInputChange", event, value);

    setAlertMessage({});
    setPageData({ ...pageData, location: value });
  };

  // TODO any interesting actions here?
  const handleLocationClick = (event, value) => {
    log.trace("handleLocationClick", event, value);
  };

  const handleOpenTextGenerationDialog = (e, selectedOptionsData) => {
    log.trace("handleOpenTextGenerationDialog", e, selectedOptionsData);
    logEvent(eventSource, "handleOpenTextGenerationDialog");

    const optionsSource = selectedOptionsData
      ? selectedOptionsData
      : textGenerationOptions;
    setTextGenerationOptions({
      ...optionsSource,
      useCharacters:
        (pageData.characters && pageData.characters.length != 0) || "hidden",
      useLocation:
        (pageData.location && pageData.location.length != 0) || "hidden",
    });

    setOpenTextGenerationDialog(true);
  };

  const handleCloseTextGenerationDialog = selectedOptionsData => {
    log.trace("handleCloseTextGenerationDialog", selectedOptionsData);
    setTextGenerationOptions(selectedOptionsData);
    setOpenTextGenerationDialog(false);
  };

  const handleContinueTextGeneration = async selectedOptionsData => {
    log.trace("handleContinueTextGeneration", selectedOptionsData);
    setTextGenerationOptions(selectedOptionsData);
    setOpenTextGenerationDialog(false);

    suggestPageNarrativeFromIdeation(selectedOptionsData);
  };

  const handleOpenImageGenerationDialog = (e, selectedOptionsData) => {
    log.trace("handleOpenImageGenerationDialog", e, selectedOptionsData);
    logEvent(eventSource, "handleOpenImageGenerationDialog");

    // TODO how to properly handle no characters or location options
    const optionsSource = selectedOptionsData
      ? selectedOptionsData
      : imageGenerationOptions;

    setImageGenerationOptions({
      ...optionsSource,
      useCharacters:
        (pageData.characters && pageData.characters.length != 0) || "hidden",
      useLocation:
        (pageData.location && pageData.location.length != 0) || "hidden",
      useContext: selectedOptionsData ? selectedOptionsData.useContext : false,
      context: selectedOptionsData ? selectedOptionsData.context : null,
    });

    setOpenImageGenerationDialog(true);
  };

  const handleCloseImageGenerationDialog = selectedOptionsData => {
    log.trace("handleCloseImageGenerationDialog", selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);
    setOpenImageGenerationDialog(false);
  };

  const handleContinueImageGeneration = async selectedOptionsData => {
    log.trace("handleContinueImageGeneration", selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);
    setOpenImageGenerationDialog(false);

    suggestBackgroundImageFromPageNarrative(selectedOptionsData);
  };

  const handleOpenAudioGenerationDialog = () => {
    log.trace("handleOpenAudioGenerationDialog");
    logEvent(eventSource, "handleOpenAudioGenerationDialog");
    setOpenAudioGenerationDialog(true);
  };

  const handleCloseAudioGenerationDialog = selectedOptionsData => {
    log.trace("handleCloseAudioGenerationDialog", selectedOptionsData);
    setAudioGenerationOptions(selectedOptionsData);

    setOpenAudioGenerationDialog(false);
  };

  const handleContinueAudioGeneration = async selectedOptionsData => {
    log.trace("handleContinueAudioGeneration", selectedOptionsData);
    setAudioGenerationOptions(selectedOptionsData);
    setOpenAudioGenerationDialog(false);
    suggestPageNarrationAudio(selectedOptionsData);
  };

  const handleOpenImagePickerDialog = () => {
    log.trace("handleOpenImagePickerDialog");
    logEvent(eventSource, "handleOpenImagePickerDialog");
    setOpenImagePickerDialog(true);
  };

  const handleCloseImagePickerDialog = selectedOptionsData => {
    log.trace("handleCloseImagePickerDialog", selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);
    setOpenImagePickerDialog(false);
  };

  const handleContinueImagePicker = async selectedOptionsData => {
    log.trace("handleContinueImagePicker", selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);
    setOpenImagePickerDialog(false);

    //from computer or from gallery, ...
    if (!selectedOptionsData) {
      log.trace("handleContinueImagePicker.fromComputer");
      logEvent(eventSource, "handleContinueImagePicker.fromComputer");

      imagePickerSelectFromComputer();
    } else {
      log.trace("handleContinueImagePicker.fromGallery");
      logEvent(eventSource, "handleContinueImagePicker.fromGallery");
      const filePath = selectedOptionsData.filePath;
      const imagePrompt = selectedOptionsData.imagePrompt;
      const imageUrl = selectedOptionsData.imageUrl;

      //set form data with image previously generated. image is already stored, so just get the file path
      setPageData({
        ...pageData,
        imagePrompt: imagePrompt,
        backgroundImage: filePath,
        imageUrl: imageUrl,
        newBackgroundImage: null,
      });
    }
  };

  const imagePickerSelectFromComputer = () => {
    log.trace("imagePickerSelectFromComputer");

    uploadInputRef.current && uploadInputRef.current.click();
  };

  function handleContextMenu(e) {
    log.trace("handleContextMenu", e);

    show({
      event: e,
    });
  }

  const handleItemClick = action => {
    log.trace("handleItemClick", action);

    const selection = window.getSelection().toString();
    if (selection.length === 0) {
      return;
    }

    if (action === "suggestImage") {
      const options = {
        ...imageGenerationOptions,
        useContext: true,
        context: selection,
      };
      handleOpenImageGenerationDialog(null, options);
    } else if (action === "rephrase") {
      // TODO
    }
  };

  const uploadInputRef = useRef(null);

  return (
    <PageContainer
      title=""
      breadcrumbs={[
        { title: t("text.overview"), path: `/console/books/${pageParams.id}` },
        {
          title: t("text.pages"),
          path: `/console/books/${pageParams.id}/pages`,
        },
        {
          title: t("text.page"),
          path: `/console/books/${pageParams.id}/pages/${pageParams.pid}`,
        },
      ]}
    >
      <Box component="section">
        {alertMessage.message && (
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        )}

        {loading ? (
          <LinearProgress />
        ) : (
          <Box component="section" sx={{ p: 2 }}>
            <Backdrop
              sx={theme => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
              open={openPreview}
            >
              <PagePreview
                openPreview={openPreview}
                pageData={pageData}
                locationData={
                  (pageData.location &&
                    locationsList.locations.filter(
                      location => location.name === pageData.location
                    )[0]) ||
                  null
                }
                onClick={() => showPagePreview(false)}
              />
            </Backdrop>

            <TextGenerationDialog
              title={t("textGenerationDialog.title")}
              message={t("textGenerationDialog.message")}
              open={openTextGenerationDialog}
              onClose={handleCloseTextGenerationDialog}
              onContinue={handleContinueTextGeneration}
              optionsData={textGenerationOptions}
            />

            <ImageGenerationDialog
              title={t("imageGenerationDialog.title")}
              message={t("imageGenerationDialog.message")}
              open={openImageGenerationDialog}
              onClose={handleCloseImageGenerationDialog}
              onContinue={handleContinueImageGeneration}
              optionsData={imageGenerationOptions}
            />

            <AudioGenerationDialog
              title={t("audioGenerationDialog.title")}
              message={t("audioGenerationDialog.message")}
              open={openAudioGenerationDialog}
              onClose={handleCloseAudioGenerationDialog}
              onContinue={handleContinueAudioGeneration}
              optionsData={audioGenerationOptions}
            />

            <ImagePicker
              title={t("imagePicker.title")}
              message={t("imagePicker.message")}
              open={openImagePickerDialog}
              onClose={handleCloseImagePickerDialog}
              onContinue={handleContinueImagePicker}
              optionsData={imagePickerOptions}
            />

            <Menu id={CONTEXT_MENU_ID}>
              <Submenu label="Image">
                <Item onClick={e => handleItemClick("suggestImage")}>
                  {t("text.suggestWithText")}
                </Item>
              </Submenu>
              <Separator />
            </Menu>

            <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 1 }}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="plot-content"
                  id="plot-header"
                >
                  {t("header.plot")}
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2} direction="column">
                    {charactersList.options.length > 0 && (
                      <Autocomplete
                        {...charactersList}
                        id="characters-auto-complete"
                        name="characters-auto-complete"
                        fullWidth
                        autoComplete
                        multiple
                        filterSelectedOptions
                        onChange={handleCharacterInputChange}
                        value={pageData.characters}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            const { key, ...tagProps } = getTagProps({ index });
                            return (
                              <Chip
                                variant="filled"
                                label={option}
                                key={key}
                                onClick={e => handleCharacterClick(e, option)}
                                {...tagProps}
                              />
                            );
                          })
                        }
                        renderInput={character => (
                          <TextField
                            {...character}
                            key={character}
                            variant="standard"
                            label={t("text.charactersInScene")}
                          />
                        )}
                      />
                    )}
                    {locationsList.options.length > 0 && (
                      <Autocomplete
                        {...locationsList}
                        id="location-auto-complete"
                        name="location-auto-complete"
                        fullWidth
                        autoComplete
                        filterSelectedOptions
                        onChange={handleLocationInputChange}
                        value={pageData.location}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            const { key, ...tagProps } = getTagProps({ index });
                            return (
                              <Chip
                                variant="filled"
                                label={option}
                                key={key}
                                onClick={e => handleLocationClick(e, option)}
                                {...tagProps}
                              />
                            );
                          })
                        }
                        renderInput={location => (
                          <TextField
                            {...location}
                            key={location}
                            variant="standard"
                            label={t("text.sceneLocation")}
                          />
                        )}
                      />
                    )}
                    <TextField
                      id="ideation"
                      name="ideation"
                      required
                      autoFocus
                      fullWidth
                      multiline
                      maxRows={20}
                      value={pageData.ideation}
                      onChange={handleInputChange}
                      label={t("text.ideation")}
                      variant="outlined"
                    />
                    <Button
                      variant="text"
                      onClick={handleOpenTextGenerationDialog}
                      startIcon={<ArticleIcon />}
                    >
                      {t("buttonAction.suggestNarrativeText")}
                    </Button>
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="plot-content"
                  id="plot-header"
                >
                  {t("header.narrative")}
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2} direction="column">
                    <TextField
                      id="narrative"
                      name="narrative"
                      multiline
                      fullWidth
                      maxRows={20}
                      value={pageData.narrative}
                      onChange={handleInputChange}
                      label={t("text.narrative")}
                      variant="outlined"
                      onContextMenu={handleContextMenu}
                    />
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                    >
                      <Button
                        variant="text"
                        onClick={handleOpenImageGenerationDialog}
                        startIcon={<AddPhotoAlternateIcon />}
                      >
                        {t("buttonAction.suggestBackgroundImage")}
                      </Button>
                      {pageData.audioUrl ? (
                        <>
                          <audio
                            id="narrativeAudio"
                            name="narrativeAudio"
                            controls
                            src={pageData.audioUrl}
                          >
                            {t("error.noAudioSupport")}
                          </audio>
                          <IconButton
                            aria-label="Delete audio"
                            color="error"
                            onClick={removePageNarrationAudio}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </>
                      ) : (
                        <Button
                          variant="text"
                          onClick={handleOpenAudioGenerationDialog}
                          startIcon={<VolumeUpIcon />}
                        >
                          {t("buttonAction.generateAudio")}
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="images-content"
                  id="images-header"
                >
                  {t("header.media")}
                </AccordionSummary>
                <AccordionDetails>
                  <Box component="section" sx={{ p: 2 }}>
                    <Stack direction="row" justifyContent="center">
                      <Button
                        onClick={handleOpenImagePickerDialog}
                        variant="contained"
                      >
                        {t("buttonAction.selectBackgroundImage")}
                      </Button>
                    </Stack>

                    {pageData.backgroundImage && (
                      <Box sx={{ pt: 2, pb: 2 }}>
                        <Avatar
                          alt={pageData.backgroundImage}
                          src={pageData.imageUrl}
                          variant="square"
                          sx={{ width: "100%", height: "30%" }}
                          onLoad={handlePageBackgroundImageLoaded}
                        />
                        <Typography
                          variant="body2"
                          id="backgroundImage"
                          name="backgroundImage"
                          sx={{ textAlign: "right" }}
                        >
                          {pageData.backgroundImage}
                        </Typography>
                      </Box>
                    )}
                    <input
                      id="newBackgroundImage"
                      name="newBackgroundImage"
                      ref={uploadInputRef}
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Stack
                spacing={2}
                direction="row"
                sx={{ p: 2 }}
                justifyContent="center"
              >
                <Button variant="contained" type="submit" color="primary">
                  {t("buttonAction.save")}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancel}
                >
                  {t("buttonAction.cancel")}
                </Button>
                <Button
                  variant="text"
                  onClick={showPagePreview}
                  sx={{ ml: "auto" }}
                >
                  {t("buttonAction.quickPreview")}
                </Button>
              </Stack>
            </Box>
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}

export default Page;
