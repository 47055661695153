import {
  Alert,
  Box,
  Button,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import firebaseAuth from "../Services/auth";
import { logEvent } from "../Services/firebase";
import { requestHeaders } from "../Tools/DataUtils";
import log from "../Tools/Log";

function SubscribeSuccess() {
  const eventSource = "SubscribeSuccess";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [userProfileData, setUserProfileData] = useState({
    displayName: "",
    email: "",
    emailVerified: "",
    penName: "",
    customerId: "",
    membership: {},
    fbcu_email: "",
  });

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    fetchData();
  }, []);

  const fetchData = async () => {
    log.trace("fetchData.request");
    if (!firebaseAuth.currentUser) return;

    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getUserProfile`,
          {},
          await requestHeaders()
        )
        .then(async response => {
          log.trace("fetchData.response.data", response.data);
          logEvent(eventSource, "fetchData.response");

          setUserProfileData(response.data);

          if (
            !response.data.membership ||
            response.data.membership.status !== "active"
          ) {
            setAlertMessage({
              message: t("error.paymentVerificationDelayed"),
              severity: "warning",
            });
          }
        })
        .catch(error => {
          log.error("Error requesting data", error);
          logEvent(eventSource, "fetchData.error", error);
          setAlertMessage({
            message: t("error.dataFetch"),
            severity: "error",
          });
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting data", exception);
      logEvent(eventSource, "fetchData.exception", exception);
      setAlertMessage({
        message: t("error.dataFetch"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  // Listen to change in auth state so it displays the correct UI for when
  // the user is signed in or not.
  firebaseAuth.onAuthStateChanged(user => {
    log.trace("onAuthStateChanged", user);

    if (
      user &&
      firebaseAuth.currentUser &&
      !userProfileData.email &&
      !userProfileData.fbcu_email
    ) {
      setUserProfileData({ fbcu_email: user.email });
      fetchData();
    }
  });

  return (
    <Box component="section" sx={{ p: 2 }}>
      {alertMessage.message && (
        <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
      )}

      {loading ? (
        <LinearProgress />
      ) : (
        <Box component="section" sx={{ p: 2 }}>
          <Typography variant="h5" sx={{ pb: 2 }}>
            {t("view.subscribeSuccess.text.welcomeNewMember", {
              displayName: userProfileData.displayName,
            })}
          </Typography>
          <Stack spacing={2} direction="row">
            {userProfileData.membership &&
            userProfileData.membership.status === "active" ? (
              <Button
                key="startBuilding"
                sx={{ my: 2, display: "block" }}
                component={Link}
                to="/console"
              >
                {t("text.startCreating")}
              </Button>
            ) : (
              <Button
                key="startBuirefreshlding"
                sx={{ my: 2, display: "block" }}
                component={Link}
                onClick={fetchData}
              >
                {t("text.refresh")}
              </Button>
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
}

export default SubscribeSuccess;
