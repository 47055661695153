import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import AirIcon from "@mui/icons-material/Air";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Button,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { logEvent } from "../../Services/firebase";
import { formSubmitHeaders, requestHeaders } from "../../Tools/DataUtils";
import log from "../../Tools/Log";
import ImageGenerationDialog from "../../UiComponents/ImageGenerationDialog";
import ImagePicker from "../../UiComponents/ImagePicker";
import LocationAmbianceSetupDialog from "../../UiComponents/LocationAmbianceSetupDialog";

function Location() {
  const eventSource = "Location";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [locationData, setLocationData] = useState({
    uuid: "",
    bookUuid: "",
    name: "",
    backstory: "",
    weather: null,
    sounds: null,
    lights: null,
    smells: null,
    sights: null,
    backgroundImage: "",
    newBackgroundImage: null,
  });
  const [creatingNew, setCreatingNew] = useState(false);
  const [openImageGenerationDialog, setOpenImageGenerationDialog] =
    useState(false);
  const [imageGenerationOptions, setImageGenerationOptions] = useState({
    imageService: {
      name: `${process.env.REACT_APP_DEFAULT_AI_IMAGE_SERVICE}`,
      endpoint: `${process.env.REACT_APP_DEFAULT_AI_IMAGE_SERVICE_ENDPOINT}`,
    },
    useLocation: true,
    useCharacters: true,
    useImageDescriptorService: true,
  });
  const [openImagePickerDialog, setOpenImagePickerDialog] = useState(false);
  const [imagePickerOptions, setImagePickerOptions] = useState({});
  const [openLocationAmbianceSetupDialog, setOpenLocationAmbianceSetupDialog] =
    useState(false);
  const [locationAmbianceOptions, setLocationAmbianceOptions] = useState({
    isQuiet: { response: false, reasoning: "" },
    isProneToWinds: { response: false, reasoning: "" },
    isProneToRain: { response: false, reasoning: "" },
    isProneToThunderstorms: { response: false, reasoning: "" },
    hasChattingNoise: { response: false, reasoning: "" },
    hasTrafficNoise: { response: false, reasoning: "" },
    hasIndustryNoise: { response: false, reasoning: "" },
    hasEcho: { response: false, reasoning: "" },
    isOpenLocation: { response: false, reasoning: "" },
  });

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    if (pageParams.lid == "new") {
      log.trace("creating new");
      setCreatingNew(true);
      setLocationData({ ...locationData, bookUuid: pageParams.id });
    }

    // if not new, request data
    // (React quirk) cannot use creatingNew as it won't be set as yet
    if (pageParams.lid != "new") {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getLocations`,
          {
            bookUuid: pageParams.id,
            filters: { locationUuid: pageParams.lid },
          },
          await requestHeaders()
        )
        .then(response => {
          log.trace("fetchData.response.data", response.data);
          logEvent(eventSource, "fetchData.response");

          response.data.map(item => {
            item.imageUrl =
              `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
              item.backgroundImage;
          });

          if (response.data[0]) {
            setLocationData(response.data[0]);
            response.data[0].ambiance &&
              setLocationAmbianceOptions(response.data[0].ambiance);
          }
        })
        .catch(error => {
          log.error("Error requesting data", error);
          logEvent(eventSource, "fetchData.error", error);
          setAlertMessage({
            message: t("error.dataFetch"),
            severity: "error",
          });
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting data", exception);
      logEvent(eventSource, "fetchData.exception", exception);
      setAlertMessage({
        message: t("error.dataFetch"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleFormSubmit = async e => {
    log.trace("handleFormSubmit.request", e, locationData);
    logEvent(eventSource, "handleFormSubmit.request");

    try {
      e.preventDefault();
      showLoadingUI();

      const formData = new FormData();

      if (!creatingNew) {
        formData.append("uuid", locationData.uuid);
      }
      formData.append("bookUuid", pageParams.id);
      formData.append("name", locationData.name);
      formData.append("backstory", locationData.backstory);

      locationData.sights && formData.append("sights", locationData.sights);
      locationData.weather && formData.append("weather", locationData.weather);
      locationData.smells && formData.append("smells", locationData.smells);
      locationData.sounds && formData.append("sounds", locationData.sounds);
      locationData.lights && formData.append("lights", locationData.lights);

      locationAmbianceOptions &&
        formData.append("ambiance", JSON.stringify(locationAmbianceOptions));

      locationData.imagePrompt &&
        formData.append("imagePrompt", locationData.imagePrompt);

      locationData.backgroundImage &&
        formData.append("backgroundImage", locationData.backgroundImage);

      locationData.newBackgroundImage &&
        formData.append("newBackgroundImage", locationData.newBackgroundImage);

      if (creatingNew) {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/putLocation`,
            formData,
            await formSubmitHeaders()
          )
          .then(response => {
            log.trace("handleFormSubmit.response.data", response.data);
            logEvent(eventSource, "handleFormSubmit.response");

            setLocationData({});
            navigate(`/console/books/${pageParams.id}/locations`);
          })
          .catch(error => {
            log.error("Error requesting action", error);
            logEvent(eventSource, "handleFormSubmit.error", error);
            setAlertMessage({
              message: t("error.action"),
              severity: "error",
            });
          })
          .finally(() => {
            hideLoadingUI();
          });
      } else {
        axios
          .put(
            `${process.env.REACT_APP_API_URL}/putLocation`,
            formData,
            await formSubmitHeaders()
          )
          .then(response => {
            log.trace("handleFormSubmit.response.data", response.data);
            logEvent(eventSource, "handleFormSubmit.response");

            setLocationData({});
            navigate(`/console/books/${pageParams.id}/locations`);
          })
          .catch(error => {
            log.error("Error requesting action", error);
            logEvent(eventSource, "handleFormSubmit.error", error);
            setAlertMessage({
              message: t("error.action"),
              severity: "error",
            });
          })
          .finally(() => {
            hideLoadingUI();
          });
      }
    } catch (exception) {
      log.error("Error requesting action", exception);
      logEvent(eventSource, "handleFormSubmit.exception", exception);
      setAlertMessage({
        message: t("error.action"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleInputChange = e => {
    log.trace("handleInputChange", e);
    const { name, value } = e.target;
    setLocationData({ ...locationData, [name]: value });
  };

  const handleFileChange = e => {
    log.trace("handleFileChange", e);

    if (e.target.files[0]) {
      URL.revokeObjectURL(locationData.imageUrl);
      const previewImageUrl = URL.createObjectURL(e.target.files[0]);

      setLocationData({
        ...locationData,
        newBackgroundImage: e.target.files[0],
        backgroundImage: e.target.files[0].name,
        imageUrl: previewImageUrl,
        imagePrompt: null,
      });
    }
  };

  const handleCancel = () => {
    log.trace("handleCancel");
    navigate(`/console/books/${pageParams.id}/locations`);
  };

  const handlePageBackgroundImageLoaded = e => {
    log.trace("handlePageBackgroundImageLoaded", e);
    // const img = e.target;
    // TODO warn of best options for image format, ration, etc
    // BE uses sharp; converts to web friendly format automatically
  };

  const suggestBackgroundImageFromDescription = async selectedOptionsData => {
    log.trace(
      "suggestBackgroundImageFromDescription.request",
      selectedOptionsData
    );
    logEvent(eventSource, "suggestBackgroundImageFromDescription.request");

    if (!locationData.backstory || locationData.backstory.length == 0) {
      setAlertMessage({
        message: t("error.suggesLocationBackgroundImageMissingParameters"),
        severity: "error",
      });

      return;
    }

    showLoadingUI();

    // TODO priority this should be a specific endpoint for this functionality; no client side logic
    // genai to produce bg image
    try {
      const locationlDescription = [
        "Generate a landscape image for a location with the following characteristics. ",
      ];

      locationData.backstory &&
        locationlDescription.push(locationData.backstory + ".");
      locationData.sights &&
        locationlDescription.push(
          "With the following landmarks: " + locationData.sights + "."
        );
      locationData.weather &&
        locationlDescription.push(
          "With the weather elements: " + locationData.weather + "."
        );
      locationlDescription.push(
        "With sounds and noise as: " + locationData.sounds + "."
      );
      locationlDescription.push(
        "With smells like: " + locationData.smells + "."
      );
      locationlDescription.push(
        "With light setup as: " + locationData.lights + "."
      );

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/${selectedOptionsData.imageService.endpoint}`,
          {
            bookUuid: pageParams.id,
            narrativePrompt: locationlDescription.join(" "),
            options: selectedOptionsData,
          },
          await requestHeaders()
        )
        .then(response => {
          log.trace(
            "suggestBackgroundImageFromDescription.response.data",
            response.data
          );
          logEvent(
            eventSource,
            "suggestBackgroundImageFromDescription.response"
          );

          const imageUrl =
            `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
            response.data.filePath;

          //set form data with image generated. image is already stored, so just save the file path
          setLocationData({
            ...locationData,
            imagePrompt: response.data.imagePrompt,
            backgroundImage: response.data.filePath,
            imageUrl: imageUrl,
          });
        })
        .catch(error => {
          log.error("Error requesting action", error);
          logEvent(
            eventSource,
            "suggestBackgroundImageFromDescription.error",
            error
          );

          if (error.status == 460) {
            setAlertMessage({
              message: t("error.membershipGenerationLimitsReached"),
              severity: "error", //success info warning error
            });
          } else {
            setAlertMessage({
              message: t("error.imageGenerationGeneric"),
              severity: "error", //success info warning error
            });
          }
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting action", exception);
      logEvent(
        eventSource,
        "suggestBackgroundImageFromDescription.exception",
        exception
      );
      setAlertMessage({
        message: t("error.imageGenerationGeneric"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleOpenImageGenerationDialog = () => {
    log.trace("handleOpenImageGenerationDialog");
    logEvent(eventSource, "handleOpenImageGenerationDialog");

    setImageGenerationOptions({
      ...imageGenerationOptions,
      useCharacters: "hidden",
      useLocation: "hidden",
    });

    setOpenImageGenerationDialog(true);
  };

  const handleCloseImageGenerationDialog = selectedOptionsData => {
    log.trace("handleCloseImageGenerationDialog", selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);

    setOpenImageGenerationDialog(false);
  };

  const handleContinueImageGeneration = async selectedOptionsData => {
    log.trace("handleContinueImageGeneration", selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);
    setOpenImageGenerationDialog(false);

    suggestBackgroundImageFromDescription(selectedOptionsData);
  };

  const handleOpenImagePickerDialog = () => {
    log.trace("handleOpenImagePickerDialog");
    setOpenImagePickerDialog(true);
  };

  const handleCloseImagePickerDialog = selectedOptionsData => {
    log.trace("handleCloseImagePickerDialog", selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);
    setOpenImagePickerDialog(false);
  };

  const handleContinueImagePicker = async selectedOptionsData => {
    log.trace("handleContinueImagePicker", selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);
    setOpenImagePickerDialog(false);

    //from computer or from gallery, ...
    if (!selectedOptionsData) {
      log.trace("handleContinueImagePicker.fromComputer");
      logEvent(eventSource, "handleContinueImagePicker.fromComputer");
      imagePickerSelectFromComputer();
    } else {
      log.trace("handleContinueImagePicker.fromGallery");
      logEvent(eventSource, "handleContinueImagePicker.fromGallery");
      const filePath = selectedOptionsData.filePath;
      const imagePrompt = selectedOptionsData.imagePrompt;
      const imageUrl = selectedOptionsData.imageUrl;

      //set form data with image previously generated. image is already stored, so just get the file path
      setLocationData({
        ...locationData,
        imagePrompt: imagePrompt,
        backgroundImage: filePath,
        imageUrl: imageUrl,
        newBackgroundImage: null,
      });
    }
  };

  const imagePickerSelectFromComputer = () => {
    log.trace("imagePickerSelectFromComputer");
    uploadInputRef.current && uploadInputRef.current.click();
  };

  const handleOpenLocationAmbianceSetupDialog = (e, selectedOptionsData) => {
    log.trace("handleOpenLocationAmbianceSetupDialog", selectedOptionsData);
    logEvent(eventSource, "handleOpenLocationAmbianceSetupDialog");

    if (selectedOptionsData) {
      setLocationAmbianceOptions(selectedOptionsData);
    }
    setOpenLocationAmbianceSetupDialog(true);
  };

  const handleCloseLocationAmbianceSetupDialog = selectedOptionsData => {
    log.trace("handleCloseLocationAmbianceSetupDialog", selectedOptionsData);
    setOpenLocationAmbianceSetupDialog(false);
  };

  const handleContinueLocationAmbianceSetupDialog =
    async selectedOptionsData => {
      log.trace(
        "handleContinueLocationAmbianceSetupDialog",
        selectedOptionsData
      );
      setLocationAmbianceOptions(selectedOptionsData);
      setOpenLocationAmbianceSetupDialog(false);
    };

  const suggestAmbianceSetupFromDetails = async () => {
    log.trace("suggestAmbianceSetupFromDetails.request");
    logEvent(eventSource, "suggestAmbianceSetupFromDetails.request");

    if (!locationData.backstory || locationData.backstory.length == 0) {
      setAlertMessage({
        message: t("error.suggestAmbianceSetupFromDetails"),
        severity: "error",
      });

      return;
    }

    showLoadingUI();

    try {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/suggestLocationAmbiance`,
          {
            bookUuid: pageParams.id,
            location: locationData,
          },
          await requestHeaders()
        )
        .then(response => {
          log.trace(
            "suggestAmbianceSetupFromDetails.response.data",
            response.data
          );
          logEvent(eventSource, "suggestAmbianceSetupFromDetails.response");

          setLocationAmbianceOptions(response.data);
          handleOpenLocationAmbianceSetupDialog(null, response.data);
        })
        .catch(error => {
          log.error("Error requesting action", error);
          logEvent(eventSource, "suggestAmbianceSetupFromDetails.error", error);

          if (error.status == 460) {
            setAlertMessage({
              message: t("error.membershipGenerationLimitsReached"),
              severity: "error",
            });
          } else {
            setAlertMessage({
              message: t("error.ambianceGenerationGeneric"),
              severity: "error",
            });
          }
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting action", exception);
      logEvent(
        eventSource,
        "suggestAmbianceSetupFromDetails.exception",
        exception
      );
      setAlertMessage({
        message: t("error.imageGenerationGeneric"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const uploadInputRef = useRef(null);

  return (
    <PageContainer
      title=""
      breadcrumbs={[
        { title: t("text.overview"), path: `/console/books/${pageParams.id}` },
        {
          title: t("text.locations"),
          path: `/console/books/${pageParams.id}/locations`,
        },
        {
          title: t("text.location"),
          path: `/console/books/${pageParams.id}/locations/${pageParams.lid}`,
        },
      ]}
    >
      <Box component="section">
        {alertMessage.message && (
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        )}

        {loading ? (
          <LinearProgress />
        ) : (
          <Box component="section" sx={{ p: 2 }}>
            <ImageGenerationDialog
              title={t("imageGenerationDialog.title")}
              message={t("imageGenerationDialog.message")}
              open={openImageGenerationDialog}
              onClose={handleCloseImageGenerationDialog}
              onContinue={handleContinueImageGeneration}
              optionsData={imageGenerationOptions}
            />

            <ImagePicker
              title={t("imagePicker.title")}
              message={t("imagePicker.message")}
              open={openImagePickerDialog}
              onClose={handleCloseImagePickerDialog}
              onContinue={handleContinueImagePicker}
              optionsData={imagePickerOptions}
            />

            <LocationAmbianceSetupDialog
              title={t("locationAmbianceSetupDialog.title")}
              message={t("locationAmbianceSetupDialog.message")}
              open={openLocationAmbianceSetupDialog}
              onClose={handleCloseLocationAmbianceSetupDialog}
              onContinue={handleContinueLocationAmbianceSetupDialog}
              optionsData={locationAmbianceOptions}
            />

            <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 1 }}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="location-details-content"
                  id="location-details-header"
                >
                  {t("header.locationDetails")}
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2} direction="column" sx={{ p: 2 }}>
                    <TextField
                      id="name"
                      name="name"
                      required
                      autoFocus
                      value={locationData.name}
                      onChange={handleInputChange}
                      label={t("text.name")}
                      variant="outlined"
                    />
                    <TextField
                      id="backstory"
                      name="backstory"
                      required
                      multiline
                      value={locationData.backstory}
                      onChange={handleInputChange}
                      label={t("text.backstory")}
                      variant="outlined"
                    />
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="location-details-content"
                  id="location-details-header"
                >
                  {t("header.expandedDetails")}
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2} direction="column" sx={{ p: 2 }}>
                    <TextField
                      id="sights"
                      name="sights"
                      value={locationData.sights}
                      onChange={handleInputChange}
                      label={t("view.location.text.sights")}
                      variant="outlined"
                    />
                    <TextField
                      id="weather"
                      name="weather"
                      value={locationData.weather}
                      onChange={handleInputChange}
                      label={t("view.location.text.weather")}
                      variant="outlined"
                    />
                    <TextField
                      id="sounds"
                      name="sounds"
                      value={locationData.sounds}
                      onChange={handleInputChange}
                      label={t("view.location.text.sounds")}
                      variant="outlined"
                    />
                    <TextField
                      id="smells"
                      name="smells"
                      value={locationData.smells}
                      onChange={handleInputChange}
                      label={t("view.location.text.smells")}
                      variant="outlined"
                    />
                    <TextField
                      id="lights"
                      name="lights"
                      value={locationData.lights}
                      onChange={handleInputChange}
                      label={t("view.location.text.lights")}
                      variant="outlined"
                    />
                    <Stack
                      spacing={2}
                      direction="row"
                      sx={{ p: 2, justifyContent: "center" }}
                    >
                      <Button
                        variant="text"
                        onClick={handleOpenImageGenerationDialog}
                        startIcon={<AddPhotoAlternateIcon />}
                      >
                        {t("buttonAction.suggestLocationImage")}
                      </Button>
                      <Button
                        variant="text"
                        onClick={suggestAmbianceSetupFromDetails}
                        startIcon={<AutoFixHighIcon />}
                      >
                        {t("buttonAction.suggestAmbianceSetup")}
                      </Button>
                      <Button
                        variant="text"
                        onClick={handleOpenLocationAmbianceSetupDialog}
                        startIcon={<AirIcon />}
                        sx={{ ml: "auto" }}
                      >
                        {t("buttonAction.ambianceSetup")}
                      </Button>
                    </Stack>
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="images-content"
                  id="images-header"
                >
                  {t("header.media")}
                </AccordionSummary>
                <AccordionDetails>
                  <Box component="section" sx={{ p: 2 }}>
                    <Stack direction="row" justifyContent={"center"}>
                      <Button
                        onClick={handleOpenImagePickerDialog}
                        variant="contained"
                      >
                        {t("buttonAction.selectLocationImage")}
                      </Button>
                    </Stack>
                    {locationData.backgroundImage && (
                      <Box sx={{ pt: 2, pb: 2 }}>
                        <Avatar
                          alt={locationData.backgroundImage}
                          src={locationData.imageUrl}
                          variant="square"
                          sx={{ width: "100%", height: "30%" }}
                          onLoad={handlePageBackgroundImageLoaded}
                        />
                        <Typography
                          variant="body2"
                          id="backgroundImage"
                          name="backgroundImage"
                          sx={{ textAlign: "right" }}
                        >
                          {locationData.backgroundImage}
                        </Typography>
                      </Box>
                    )}
                    <input
                      id="newBackgroundImage"
                      name="newBackgroundImage"
                      ref={uploadInputRef}
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Stack spacing={2} direction="row" justifyContent={"center"}>
                <Button variant="contained" type="submit" color="primary">
                  {t("buttonAction.save")}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancel}
                >
                  {t("buttonAction.cancel")}
                </Button>
              </Stack>
            </Box>
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}

export default Location;
