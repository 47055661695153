import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { logEvent } from "../../Services/firebase";
import log from "../../Tools/Log";

/**
    https://www.sciencedirect.com/topics/social-sciences/personality-trait#:~:text=Briefly%2C%20a%20personality%20trait%20refers,fairly%20long%20period%20of%20time 

    A personality trait refers to differences among individuals in a typical tendency to behave, think, or feel in 
    some conceptually related ways, across a variety of relevant situations and across some fairly long period of 
    time. But this definition has several parts, some of which could be stated more precisely. Let us discuss this 
    definition a bit further.

    First, the idea of “differences among individuals” is important, because the description of an individual's 
    personality is meaningful only to the extent that it gives us, directly or indirectly, a comparison with others. 

    Next, “in a tendency to behave, think, or feel” refers to a likelihood of showing some behaviors or of having 
    some thoughts or feelings. 
 */

function LearningTraits() {
  const { t } = useTranslation();

  return (
    <Box sx={{ maxWidth: "500px" }}>
      <Typography variant="h6">{t("help.learningTraits.header")}</Typography>
      <Divider sx={{ p: 1 }} />
      <Typography variant="subtitle1" sx={{ fontStyle: "italic", p: 2 }}>
        {t("help.learningTraits.definition")}
      </Typography>
      <Typography variant="body1">{t("help.learningTraits.impact")}</Typography>
      <Typography variant="body1">
        {t("help.learningTraits.callToAction")}
      </Typography>
    </Box>
  );
}

export default LearningTraits;
