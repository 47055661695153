import { Box, LinearProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import * as d3 from "d3";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { logEvent } from "../../Services/firebase";
import { requestHeaders } from "../../Tools/DataUtils";
import log from "../../Tools/Log";

const Pathways = () => {
  const eventSource = "Pathways";
  const svgRef = useRef(null);
  const theme = useTheme();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [bookId] = useState(pageParams.id);
  const [pages, setPages] = useState([]);
  const [locations, setLocations] = useState([]);

  const containerStyles = {
    width: "100%",
    height: "100vh",
  };

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    fetchData();
  }, []);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      //TODO replace with implementation later
      const filters = {
        title: "search*",
      };

      const [pagesResponse, locationsResponse] = await Promise.all([
        axios.post(
          `${process.env.REACT_APP_API_URL}/getPages`,
          {
            bookUuid: pageParams.id,
            filters,
          },
          await requestHeaders()
        ),
        axios.post(
          `${process.env.REACT_APP_API_URL}/getLocations`,
          {
            bookUuid: pageParams.id,
            filters,
          },
          await requestHeaders()
        ),
      ]);

      log.trace("fetchData.response.data", pagesResponse.data);
      logEvent(eventSource, "fetchData.response");

      pagesResponse.data.map(item => {
        item.backgroundImage &&
          (item.imageUrl =
            `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
            item.backgroundImage);

        item.narrativeAudio &&
          (item.audioUrl =
            `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
            item.narrativeAudio);
      });

      setPages(pagesResponse.data);

      log.trace("fetchData.response.data", locationsResponse.data);
      logEvent(eventSource, "fetchData.response");

      locationsResponse.data.map(item => {
        item.imageUrl =
          `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
          item.backgroundImage;
      });

      if (locationsResponse.data && locationsResponse.data.length > 0) {
        setLocations(locationsResponse.data);
      }

      hideLoadingUI();
      //buildGraph();
    } catch (exception) {
      log.error("Error requesting data", exception);
      logEvent(eventSource, "fetchData.exception", exception);
      setAlertMessage({
        message: t("error.dataFetch"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  useEffect(() => {
    log.trace("useEffect");

    buildGraph();
  }, [theme, pages]);

  const buildGraph = () => {
    log.trace("buildGraph", pages.length);
    logEvent(eventSource, "buildGraph");

    if (pages.length === 0) {
      return;
    }

    const isDarkTheme = theme.palette.mode === "dark";
    // Set dimensions for the SVG
    //TODO calculate based on number of pages
    const width = 1500;
    const height = 1500;

    const nodeSize = 100;
    const nodeBGRadius = 55;
    const nodeFullSize = nodeBGRadius * 2;
    const nodeDistance = 180;

    // node = {name: String, children: Array<String>}
    let pageNodes = null;
    let rootNode = null;
    let level = 0;

    pages.map(page => {
      const newNode = {
        isRoot: pageNodes == null ?? false,
        level: level,
        name: page.pageNumber,
        location: page.location,
        url: page.imageUrl,
        children: [],
      };
      if (pageNodes == null) {
        pageNodes = newNode;
        rootNode = newNode;
      } else {
        pageNodes.children.push(newNode);
        pageNodes = newNode;
      }
      level++;
    });

    const data = rootNode;

    // Clear previous SVG content
    const svg = d3
      .select(svgRef.current)
      //   .attr("width", width)
      //   .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%; height: 80vh; user-select: none;");
    //svg.append(defs);

    const root = d3.hierarchy(data);

    let x = d3
      .scaleLinear()
      .domain([-1, width + 1])
      .range([-1, width + 1]);
    let y = d3
      .scaleLinear()
      .domain([-1, height + 1])
      .range([-1, height + 1]);
    let xAxis = d3
      .axisBottom(x)
      .ticks(((width + 2) / (height + 2)) * 10)
      .tickSize(height)
      .tickPadding(8 - height);
    let yAxis = d3
      .axisRight(y)
      .ticks(10)
      .tickSize(width)
      .tickPadding(8 - width);

    // const view = svg
    //   .append("rect")
    //   .attr("style", "fill: rgba(255, 145, 0, 0.34); stroke:rgb(255, 0, 0);")
    //   //.attr("class", "view")
    //   .attr("x", 0.5)
    //   .attr("y", 0.5)
    //   .attr("width", width - 1)
    //   .attr("height", height - 1);

    // const gX = svg.append("g").attr("class", "axis axis--x").call(xAxis);
    // const gY = svg.append("g").attr("class", "axis axis--y").call(yAxis);

    // root initial position
    // root.x = nodeSize / 1.5;
    // root.y = nodeSize / 1.5;
    // root.fx = nodeSize / 1.5;
    // root.fy = nodeSize / 1.5;

    // Set initial positions for nodes to avoid overlap
    let row = 0;
    let col = 0;
    let forward = true;
    let cnt = 0;
    root.descendants().forEach((d, i) => {
      if (forward) {
        cnt++;
        col = Math.floor(i % (width / nodeDistance));
        if (col == 0 && cnt > 1) {
          row++;
          forward = false;
          cnt -= 2;
          col = cnt;
        }
      } else {
        cnt--;
        col = cnt;
        if (col == 0) {
          row++;
          forward = true;
          col = cnt;
        }
      }
      d.x = col * nodeDistance;
      d.y = row * nodeDistance;
      console.log("d", d.x, d.y);
    });

    const links = svg
      .append("g")
      .attr("stroke", theme.palette.text.primary)
      .attr("stroke-opacity", 0.6)
      .selectAll("line")
      .data(root.links())
      .join("line")
      .attr("stroke-width", d => Math.sqrt(d.value || 1));

    const bgCircles = svg
      .append("g")
      .attr("stroke", theme.palette.background.paper)
      .attr("stroke-width", 1.5)
      .selectAll("circle")
      .data(root.descendants())
      .join("circle")
      .attr("r", nodeBGRadius)
      .attr("fill", d =>
        d.data.isRoot
          ? theme.palette.success.main
          : d.children
            ? theme.palette.secondary.surface
            : theme.palette.error.main
      );

    const images = svg
      .append("g")
      .selectAll("image")
      .data(root.descendants())
      .join("image")
      .attr("xlink:href", d => d.data.url)
      .attr("width", nodeSize)
      .attr("height", nodeSize)
      .attr(
        "clip-path",
        `border-box circle(${nodeSize / 2}px at ${nodeSize / 2}px ${nodeSize / 2}px)`
      )
      .call(
        d3
          .drag()
          .on("start", (event, d) => {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
          })
          .on("drag", (event, d) => {
            d.fx = event.x;
            d.fy = event.y;
          })
          .on("end", (event, d) => {
            if (!event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
          })
      );

    const labels = svg
      .append("g")
      .selectAll("text")
      .data(root.descendants())
      .join("text")
      .attr("dy", "2.8em")
      .attr("x", d => d.x)
      .attr("y", d => d.y)
      .attr("text-anchor", "middle")
      //.attr("text-anchor", d => (d.children ? "end" : "start"))
      .text(d => d.data.name)
      .attr("font-size", 16)
      .attr("font-weight", "bold")
      .attr("fill", "white");

    const tooltip = d3
      .select("body")
      .append("div")
      .style("position", "absolute")
      .style("visibility", "hidden")
      .style("color", theme.palette.primary.text)
      .style(
        "background-color",
        isDarkTheme ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)"
      )
      .style("border", `1px solid ${theme.palette.divider}`)
      .style("padding", "5px")
      .style("border-radius", "5px")
      .style("box-shadow", "0px 0px 5px rgba(0,0,0,0.5)");

    images
      .on("mouseover", (event, d) => {
        d.data.location &&
          tooltip.style("visibility", "visible").text(d.data.location);
      })
      .on("mousemove", event => {
        tooltip
          .style("top", `${event.pageY - 10}px`)
          .style("left", `${event.pageX + 10}px`);
      })
      .on("mouseout", () => {
        tooltip.style("visibility", "hidden");
      });

    // Force simulation
    const simulation = d3
      .forceSimulation(root.descendants())
      .velocityDecay(0.2)
      .force("x", d3.forceX().strength(0.002))
      .force("y", d3.forceY().strength(0.002))
      .force(
        "collide",
        d3
          .forceCollide()
          .radius(d => d.r + 0.5)
          .iterations(2)
      )
      .force(
        "link",
        d3
          .forceLink(root.links())
          .id(d => d.id)
          .distance(nodeDistance)
      )
      .force("charge", d3.forceManyBody().strength(-nodeDistance))
      .force("center", d3.forceCenter(width / 2, height / 2));

    // Update the simulation
    simulation.on("tick", () => {
      links
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);

      bgCircles.attr("cx", d => d.x).attr("cy", d => d.y);

      labels.attr("x", d => d.x).attr("y", d => d.y);

      images
        .attr("x", d => d.x - nodeSize / 2)
        .attr("y", d => d.y - nodeSize / 2);
    });

    const zoom = d3
      .zoom()
      .scaleExtent([0.5, 5])
      .translateExtent([
        [-100, -100],
        [width + 90, height + 100],
      ])
      .filter(filter)
      .on("zoom", zoomed);

    return Object.assign(svg.call(zoom).node(), { reset });

    function zoomed({ transform }) {
      //view.attr("transform", transform);
      links.attr("transform", transform);
      labels.attr("transform", transform);
      bgCircles.attr("transform", transform);
      images.attr("transform", transform);
      //   gX.call(xAxis.scale(transform.rescaleX(x)));
      //   gY.call(yAxis.scale(transform.rescaleY(y)));
    }

    function reset() {
      svg.transition().duration(750).call(zoom.transform, d3.zoomIdentity);
    }

    // prevent scrolling then apply the default filter
    function filter(event) {
      event.preventDefault();
      return (!event.ctrlKey || event.type === "wheel") && !event.button;
    }
  };

  return (
    <PageContainer
      title=""
      breadcrumbs={[
        { title: t("text.overview"), path: `/console/books/${pageParams.id}` },
        {
          title: t("text.pathways"),
          path: `/console/books/${pageParams.id}/pathways`,
        },
      ]}
    >
      <Box
        component="section"
        sx={{ width: containerStyles.width, height: containerStyles.height }}
      >
        {loading ? (
          <LinearProgress />
        ) : (
          <Box
            component="section"
            sx={{
              width: containerStyles.width,
              height: containerStyles.height,
            }}
          >
            {pages.length == 0 ? (
              <Typography variant="body1">
                As you add pages you will see the pathways here.
              </Typography>
            ) : (
              <svg
                ref={svgRef}
                style={{
                  background: theme.palette.background.paper,
                }}
              ></svg>
            )}
          </Box>
        )}
      </Box>
    </PageContainer>
  );
};

export default Pathways;
