import { Box, Button, LinearProgress, Stack, Typography } from "@mui/material";
import axios from "axios";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import firebaseAuth from "./Services/auth";
import { logEvent } from "./Services/firebase";
import { requestHeaders, truncateIfNeeded } from "./Tools/DataUtils";
import log from "./Tools/Log";

function Login() {
  const eventSource = "Login";

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({});

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace(
      "useEffect",
      firebaseAuth.currentUser,
      i18n.language,
      firebaseAuth.languageCode
    );
    logEvent(eventSource, "useEffect");

    showLoadingUI();

    firebaseAuth.languageCode = i18n.language;

    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebaseAuth);

    if (!firebaseAuth.currentUser) {
      ui.start("#firebaseui-auth-container", firebaseUIConfig);
    }
  }, [i18n.language]);

  // Configure FirebaseUI.
  const firebaseUIConfig = {
    signInFlow: "popup",
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        // Action if the user is authenticated successfully
        log.trace("signInSuccessWithAuthResult", authResult, redirectUrl);

        if (authResult.user) {
          handleSignedInUser(authResult.user, authResult.additionalUserInfo);
        }

        // Do not redirect.
        return false;
      },
      uiShown: function () {
        hideLoadingUI();
      },
    },
    signInSuccessUrl: "https://libell.us",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      {
        prompt: "consent",
        provider: "microsoft.com",
        loginHintKey: "login_hint",
      },
      firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    tosUrl: "https://libell.us/tos",
    privacyPolicyUrl: function () {
      window.location.assign("https://libell.us/privacy");
    },
  };

  /**
   * Displays the UI for a signed in user.
   * @param {!firebase.User} user
   */
  var handleSignedInUser = function (user, additionalUserInfo) {
    log.trace("handleSignedInUser", user, additionalUserInfo);

    checkUserAuthorization(user, additionalUserInfo);
  };

  // TODO this is a DEV only method; check what's best for prod later
  var checkUserAuthorization = async function (user, additionalUserInfo) {
    log.trace("checkUserAuthorization.request", user, additionalUserInfo);
    logEvent(eventSource, "checkUserAuthorization.request");

    try {
      showLoadingUI();

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/checkAuth`,
          {},
          await requestHeaders()
        )
        .then(response => {
          log.trace("checkUserAuthorization.response.data", response.data);
          logEvent(eventSource, "checkUserAuthorization.response");
          navigate("/checkAccountStateInterstitial");
        })
        .catch(error => {
          log.error("Error requesting data", error);
          logEvent(eventSource, "checkUserAuthorization.error", error);

          if (error.status == 310) {
            // 310 - not a member
            navigate("/newuserprofile");
          } else if (error.status == 311) {
            // 311 - no membership
            navigate("/newusermembership");
          } else if (error.status == 312) {
            // 312 - not allow-listed
            navigate("/betainvite");
          } else if (error.status == 313) {
            // 313 - already subscribed
            navigate("/console"); // TODO check loop cycles
          } else if (error.status == 314) {
            // 314 - session paid; no membership created yet
            navigate("/subscribesuccess");
          } else {
            // server error
            firebaseAuth.signOut().then(() => {
              navigate("/error");
            });
          }
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting data", exception);
      logEvent(eventSource, "checkUserAuthorization.exception", exception);
      hideLoadingUI();
      firebaseAuth.signOut().then(() => {
        navigate("/error");
      });
    }
  };

  var handleSignOut = function () {
    log.trace("handleSignOut");
    firebaseAuth.signOut().then(() => {
      navigate("/");
    });
  };

  /**
   * Displays the UI for a signed out user.
   */
  var handleSignedOutUser = function () {
    log.trace("handleSignedOutUser");
  };

  // Listen to change in auth state so it displays the correct UI for when
  // the user is signed in or not.
  firebaseAuth.onAuthStateChanged(user => {
    log.trace("onAuthStateChanged", user);
    // user ? handleSignedInUser(user) : handleSignedOutUser();
  });

  return (
    <Box component="section" sx={{}}>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          {firebaseAuth.currentUser ? (
            <Box component="section" sx={{ p: 2 }}>
              <Stack spacing={2}>
                <Typography component="body1">
                  {t("view.login.text.welcome")}
                </Typography>
                <Typography component="body1">
                  {firebaseAuth.currentUser.displayName}
                </Typography>
                <Typography component="body1">
                  {firebaseAuth.currentUser.email}
                </Typography>
                <Button variant="outlined" onClick={handleSignOut}>
                  {t("buttonAction.logout")}
                </Button>
              </Stack>
            </Box>
          ) : (
            <Box component="section" sx={{ p: 2 }}>
              <Typography component="h6" sx={{ textAlign: "center" }}>
                {t("view.login.text.loginMessage")}
              </Typography>
            </Box>
          )}
        </>
      )}
      <Box
        id="firebaseui-auth-container"
        sx={{
          display: loading ? "none" : "block",
        }}
      />
    </Box>
  );
}

export default Login;
