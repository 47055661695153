import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { logEvent } from "../../Services/firebase";
import log from "../../Tools/Log";

/**
    Honesty-humility (H):
    Facets: Sincerity, fairness, greed avoidance, modesty
    Adjectives: {sincere, honest, faithful, loyal, modest/unassuming} versus {sly, deceitful, greedy, pretentious, hypocritical, boastful, pompous}
    Emotionality (E):
    Facets: Fearfulness, Anxiety, Dependence, Sentimentality
    Adjectives: {emotional, oversensitive, sentimental, fearful, anxious, vulnerable} versus {brave, tough, independent, self-assured, stable}
    Extraversion (X):
    Facets: Social self-esteem, social boldness, sociability, liveliness
    Adjectives: {outgoing, lively, extraverted, sociable, talkative, cheerful, active} versus {shy, passive, withdrawn, introverted, quiet, reserved}
    Agreeableness (A):
    Facets: Forgivingness, gentleness, flexibility, patience
    Adjectives: {patient, tolerant, peaceful, mild, agreeable, lenient, gentle} versus {ill-tempered, quarrelsome, stubborn, choleric}
    Conscientiousness (C):
    Facets: Organization, diligence, perfectionism, prudence
    Adjectives: {organized, disciplined, diligent, careful, thorough, precise} versus {sloppy, negligent, reckless, lazy, irresponsible, absent-minded}
    Openness to experience (O):
    Facets: Aesthetic appreciation, inquisitiveness, creativity, unconventionality
    Adjectives: {intellectual, creative, unconventional, innovative, ironic} versus {shallow, unimaginative, conventional}  
 */

function LearningPersonality() {
  const { t } = useTranslation();

  return (
    <Box sx={{ maxWidth: "500px" }}>
      <Typography variant="h6">
        {t("help.learningPersonality.header")}
      </Typography>
      <Divider sx={{ p: 1 }} />
      <Typography variant="subtitle1" sx={{ fontStyle: "italic", p: 2 }}>
        {t("help.learningPersonality.definition")}
      </Typography>
      <Typography variant="body1">
        {t("help.learningPersonality.impact")}
      </Typography>
      <Typography variant="body1">
        {t("help.learningPersonality.callToAction")}
      </Typography>
      <List sx={{ width: "100%", maxWidth: 360 }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>{t("help.learningPersonality.h")}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("help.learningPersonality.honestyHumility")}
            secondary={t("help.learningPersonality.honestyHumilityFacets")}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>{t("help.learningPersonality.e")}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("help.learningPersonality.emotionality")}
            secondary={t("help.learningPersonality.emotionalityFacets")}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>{t("help.learningPersonality.x")}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("help.learningPersonality.extraversion")}
            secondary={t("help.learningPersonality.extraversionFacets")}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>{t("help.learningPersonality.a")}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("help.learningPersonality.agreeableness")}
            secondary={t("help.learningPersonality.agreeablenessFacets")}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>{t("help.learningPersonality.c")}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("help.learningPersonality.conscientiousness")}
            secondary={t("help.learningPersonality.conscientiousnessFacets")}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>{t("help.learningPersonality.o")}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("help.learningPersonality.opennessToExperience")}
            secondary={t("help.learningPersonality.opennessToExperienceFacets")}
          />
        </ListItem>
      </List>
    </Box>
  );
}

export default LearningPersonality;
