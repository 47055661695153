import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import firebaseAuth from "../Services/auth";
import { logEvent } from "../Services/firebase";
import { requestHeaders, stringAvatar } from "../Tools/DataUtils";
import log from "../Tools/Log";
import LanguageSelector from "./LanguageSelector";
import LibellusIcon from "./LibellusIcon";

function NavigationBar() {
  const eventSource = "NavigationBar";

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const pages = [
    { title: t("text.products"), route: "/products" },
    { title: t("text.pricing"), route: "/pricing" },
    { title: t("text.about"), route: "/about" },
  ];

  const memberSettings = [
    { title: t("text.profile"), route: "/profile" },
    { title: t("text.dashboard"), route: "/checkAccountStateInterstitial" },
    { title: t("text.logout"), route: "/logout" },
  ];

  const guestSettings = [
    { title: t("text.profile"), route: "/profile" },
    { title: t("text.becomeAMember"), route: "/newusermembership" },
    { title: t("text.logout"), route: "/logout" },
  ];

  const [menuOpen, setMenuOpen] = React.useState(false);
  const [navAnchor, setNavAnchor] = React.useState(null);
  const [userdataLoaded, setUserdataLoaded] = React.useState(false);
  const [settingsMenu, setSettingsMenu] = React.useState([]);

  useEffect(() => {
    log.trace("useEffect", userdataLoaded, firebaseAuth.currentUser);
    setSettingsMenu(guestSettings);
    fetchData();
  }, [i18n.language]);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/checkAuth`,
          {},
          await requestHeaders()
        )
        .then(response => {
          log.trace("fetchData.response.data", response.data);
          logEvent(eventSource, "fetchData.response");

          setSettingsMenu(memberSettings);
        })
        .catch(error => {
          log.error("Error requesting data", error);
          logEvent(eventSource, "fetchData.error", error);

          // TODO do we need to cover this case?
          if (error.status == 314) {
            // 314 - session paid; no membership created yet
            log.trace("settings->memberSettings");
            setSettingsMenu(memberSettings);
          } else {
            setSettingsMenu(guestSettings);
          }
        })
        .finally(() => {
          setUserdataLoaded(true);
        });
    } catch (exception) {
      log.error("Error requesting data", exception);
      logEvent(eventSource, "fetchData.exception", exception);
    }
  };

  const handleOpenNavMenu = event => {
    log.trace("handleOpenNavMenu");
    setNavAnchor(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseNavMenu = route => {
    log.trace("handleCloseNavMenu", route);

    setNavAnchor(null);
    setMenuOpen(false);

    // if (route) {
    //   navigate(route);
    // }
  };

  firebaseAuth.onAuthStateChanged(async user => {
    log.trace("onAuthStateChanged", user, userdataLoaded);

    if (user && !userdataLoaded) {
      setUserdataLoaded(true);
      fetchData();
    }
  });

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <LibellusIcon />
          <Typography
            to="/"
            onClick={handleCloseNavMenu}
            variant="h6"
            noWrap
            component={Link}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LIBELL.US
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map(page => (
              <Button
                key={page.title}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
                component={Link}
                to={page.route}
              >
                {page.title}
              </Button>
            ))}
          </Box>

          <Box sx={{ minWidth: 160, pr: 2 }}>
            <LanguageSelector />
          </Box>

          {userdataLoaded && firebaseAuth.currentUser ? (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenNavMenu} sx={{ p: 0 }}>
                  {firebaseAuth.currentUser.photoURL ? (
                    <Avatar
                      alt={firebaseAuth.currentUser.displayName}
                      src={firebaseAuth.currentUser.photoURL}
                    />
                  ) : (
                    <Avatar
                      {...stringAvatar(firebaseAuth.currentUser.displayName)}
                    />
                  )}
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={navAnchor}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={menuOpen}
                onClose={handleCloseNavMenu}
              >
                {settingsMenu.map(setting => (
                  <MenuItem
                    key={setting.title}
                    onClick={() => handleCloseNavMenu(setting.route)}
                  >
                    <Typography
                      sx={{
                        display: { xs: "none", md: "flex" },
                        color: "inherit",
                        textDecoration: "none",
                        textAlign: "center",
                      }}
                      component={Link}
                      to={setting.route}
                    >
                      {setting.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ) : (
            <Box sx={{ flexGrow: 0 }}>
              <Button
                key="Login"
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
                component={Link}
                to="/login"
              >
                {t("buttonAction.login")}
              </Button>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavigationBar;
