import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Slider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { logEvent } from "../../Services/firebase";
import { formSubmitHeaders, requestHeaders } from "../../Tools/DataUtils";
import log from "../../Tools/Log";
import ImageGenerationDialog from "../../UiComponents/ImageGenerationDialog";
import ImagePicker from "../../UiComponents/ImagePicker";
import NoMaxWidthTooltip from "../../UiComponents/NoMaxWidthTooltip";
import DictionaryDefinition from "../Learning/DictionaryDefinition";
import LearningHierarchyOfNeeds from "../Learning/HierarchyOfNeeds";
import LearningPersonality from "../Learning/Personality";
import LearningPhysicalDetails from "../Learning/PhysicalDetails";
import LearningTraits from "../Learning/Traits";

function Character() {
  const eventSource = "Character";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [characterData, setCharacterData] = useState({
    uuid: "",
    bookUuid: "",
    name: "",
    role: "",
    backstory: "",
    hierarchyOfNeeds: 3,
    physical: {
      overview: "",
      height: "",
      weight: "",
      age: "",
      race: "",
      appearance: "",
      style: "",
    },
    personality: {
      opennessToExperience: [40, 60],
      conscientiousness: [40, 60],
      extraversion: [40, 60],
      agreeableness: [40, 60],
      emotionality: [40, 60],
      honestyHumility: [40, 60],
      narcissism: [40, 60],
      machiavellianism: [40, 60],
      psychopathy: [40, 60],
      cruelty: [40, 60],
    },
    traits: { positive: [], negative: [], quirks: [] },
    backgroundImage: "",
    imagePrompt: "",
    newBackgroundImage: null,
  });
  const [characterRoles, setCharacterRoles] = useState([]);
  const [creatingNew, setCreatingNew] = useState(false);
  const [selectedTrait, setSelectedTrait] = useState([]);
  const [selectedHoN, setSelectedHoN] = useState([]);
  const [openImageGenerationDialog, setOpenImageGenerationDialog] =
    useState(false);
  const [imageGenerationOptions, setImageGenerationOptions] = useState({
    imageService: {
      name: `${process.env.REACT_APP_DEFAULT_AI_IMAGE_SERVICE}`,
      endpoint: `${process.env.REACT_APP_DEFAULT_AI_IMAGE_SERVICE_ENDPOINT}`,
    },
    useLocation: true,
    useCharacters: true,
    useImageDescriptorService: true,
  });
  const [openImagePickerDialog, setOpenImagePickerDialog] = useState(false);
  const [imagePickerOptions, setImagePickerOptions] = useState({});

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    fetchCharacterSetupData();

    if (pageParams.cid == "new") {
      log.trace("creating new");
      setCreatingNew(true);
      setCharacterData({ ...characterData, bookUuid: pageParams.id });

      const [item] = hierarchyOfNeedsMarks.filter(
        i => i.value == characterData.hierarchyOfNeeds
      );
      setSelectedHoN(item);
    }

    // if not new, request data
    // (React quirk) cannot use creatingNew as it won't be set as yet
    if (pageParams.cid != "new") {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      // TODO implement filters
      const filters = { characterUuid: pageParams.cid };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getCharacters`,
          {
            bookUuid: pageParams.id,
            filters,
          },
          await requestHeaders()
        )
        .then(response => {
          log.trace("fetchData.response.data", response.data);
          logEvent(eventSource, "fetchData.response");

          response.data.map(item => {
            item.imageUrl =
              `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
              item.backgroundImage;
          });

          if (response.data[0]) {
            setCharacterData(response.data[0]);

            // TODO handle this during creation
            if (!response.data[0].hierarchyOfNeeds) {
              response.data[0].hierarchyOfNeeds = 3;
            }

            // TODO handle this during creation
            if (!response.data[0].physical) {
              response.data[0].physical = {};
            }

            const [item] = hierarchyOfNeedsMarks.filter(
              i => i.value == response.data[0].hierarchyOfNeeds
            );
            setSelectedHoN(item);
          }
        })
        .catch(error => {
          log.error("Error requesting data", error);
          logEvent(eventSource, "fetchData.error", error);
          setAlertMessage({
            message: t("error.dataFetch"),
            severity: "error",
          });
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting data", exception);
      logEvent(eventSource, "fetchData.exception", exception);
      setAlertMessage({
        message: t("error.dataFetch"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const fetchCharacterSetupData = async () => {
    log.trace("fetchCharacterSetupData.request");
    logEvent(eventSource, "fetchCharacterSetupData.request");

    try {
      showLoadingUI();

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getCharacterSetupData`,
          {
            bookUuid: pageParams.id,
          },
          await requestHeaders()
        )
        .then(response => {
          log.trace("fetchData.response.data", response.data);
          logEvent(eventSource, "fetchData.response");

          if (response.data) {
            // TODO setup traits, HoN, etc
            if (response.data.roles) {
              setCharacterRoles({
                options: response.data.roles.sort((a, b) => a.localeCompare(b)),
              });
            }
          }
        })
        .catch(error => {
          log.error("Error requesting data", error);
          logEvent(eventSource, "fetchData.error", error);
          setAlertMessage({
            message: t("error.dataFetch"),
            severity: "error",
          });
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting data", exception);
      logEvent(eventSource, "fetchData.exception", exception);
      setAlertMessage({
        message: t("error.dataFetch"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleFormSubmit = async e => {
    log.trace("handleFormSubmit.request", e, characterData);
    logEvent(eventSource, "handleFormSubmit.request");

    try {
      e.preventDefault();
      showLoadingUI();

      const formData = new FormData();

      if (!creatingNew) {
        formData.append("uuid", characterData.uuid);
      }
      formData.append("bookUuid", pageParams.id);
      formData.append("name", characterData.name);
      formData.append("backstory", characterData.backstory);
      formData.append("hierarchyOfNeeds", characterData.hierarchyOfNeeds);
      formData.append("personality", JSON.stringify(characterData.personality));
      formData.append("traits", JSON.stringify(characterData.traits));
      formData.append("physical", JSON.stringify(characterData.physical));

      characterData.role && formData.append("role", characterData.role);

      characterData.imagePrompt &&
        formData.append("imagePrompt", characterData.imagePrompt);

      if (characterData.backgroundImage) {
        formData.append("backgroundImage", characterData.backgroundImage);
      }

      if (characterData.newBackgroundImage) {
        formData.append("newBackgroundImage", characterData.newBackgroundImage);
      }

      if (creatingNew) {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/putCharacter`,
            formData,
            await formSubmitHeaders()
          )
          .then(response => {
            // Handle the response
            log.trace("handleFormSubmit.response.data", response.data);
            logEvent(eventSource, "handleFormSubmit.response");

            setCharacterData({});
            navigate(`/console/books/${pageParams.id}/characters`);
          })
          .catch(error => {
            log.error("Error requesting action", error);
            logEvent(eventSource, "handleFormSubmit.error", error);
            setAlertMessage({
              message: t("error.action"),
              severity: "error",
            });
          })
          .finally(() => {
            hideLoadingUI();
          });
      } else {
        axios
          .put(
            `${process.env.REACT_APP_API_URL}/putCharacter`,
            formData,
            await formSubmitHeaders()
          )
          .then(response => {
            log.trace("handleFormSubmit.response.data", response.data);
            logEvent(eventSource, "handleFormSubmit.response");

            setCharacterData({});
            navigate(`/console/books/${pageParams.id}/characters`);
          })
          .catch(error => {
            log.error("Error requesting action", error);
            logEvent(eventSource, "handleFormSubmit.error", error);
            setAlertMessage({
              message: t("error.action"),
              severity: "error",
            });
          })
          .finally(() => {
            hideLoadingUI();
          });
      }
    } catch (exception) {
      log.error("Error requesting action", exception);
      logEvent(eventSource, "handleFormSubmit.exception", exception);
      setAlertMessage({
        message: t("error.action"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleInputChange = e => {
    log.trace("handleInputChange", e);
    const { name, value } = e.target;
    setCharacterData({ ...characterData, [name]: value });
  };

  const handleFileChange = e => {
    log.trace("handleFileChange", e);

    if (e.target.files[0]) {
      URL.revokeObjectURL(characterData.imageUrl);
      const previewImageUrl = URL.createObjectURL(e.target.files[0]);

      setCharacterData({
        ...characterData,
        newBackgroundImage: e.target.files[0],
        backgroundImage: e.target.files[0].name,
        imageUrl: previewImageUrl,
        imagePrompt: null,
      });
    }
  };

  const handleCancel = () => {
    log.trace("handleCancel");
    navigate(`/console/books/${pageParams.id}/characters`);
  };

  const handlePageBackgroundImageLoaded = e => {
    log.trace("handleBookCoverLoaded", e);
    // const img = e.target;
    // TODO warn of best options for image format, ration, etc
    // BE uses sharp; converts to web friendly format automatically
  };

  const handleRoleInputChange = (e, value) => {
    log.trace("handleRoleInputChange", e, value);
    setCharacterData({ ...characterData, role: value });
  };

  const handlePersonalityInputChange = e => {
    log.trace("handlePersonalityInputChange", e);
    const { name, value } = e.target;
    const personality = characterData.personality;
    personality[name] = value;
    setCharacterData({ ...characterData, personality: personality });
  };

  const handlePhysicalInputChange = e => {
    log.trace("handlePhysicalInputChange", e);
    const { name, value } = e.target;
    const physical = characterData.physical;
    physical[name] = value;
    setCharacterData({ ...characterData, physical: physical });
  };

  const handlePositiveTraitInputChange = (e, values) => {
    log.trace("handlePositiveTraitInputChange", e, values);
    const maxTraits = 5;
    if (values.length > maxTraits) {
      values.slice(0, maxTraits - 1);
      setAlertMessage({
        message: "You've reached the max number of traits.",
        severity: "warning", //success info warning error
      });
    } else {
      setAlertMessage({});
      characterData.traits.positive = values;
      setCharacterData({ ...characterData, traits: characterData.traits });
    }
  };

  const handleNegativeTraitInputChange = (e, values) => {
    log.trace("handleNegativeTraitInputChange", e, values);
    const maxTraits = 5;
    if (values.length > maxTraits) {
      values.slice(0, maxTraits - 1);
      setAlertMessage({
        message: "You've reached the max number of traits.",
        severity: "warning", //success info warning error
      });
    } else {
      setAlertMessage({});
      characterData.traits.negative = values;
      setCharacterData({ ...characterData, traits: characterData.traits });
    }
  };

  const handleTraitClick = (e, word) => {
    log.trace("handleTraitClick", word);
    setSelectedTrait(word);
  };

  const handleHierarchyOfNeedsInputChange = e => {
    log.trace("handleHierarchyOfNeedsInputChange", e);
    const { name, value } = e.target;
    setCharacterData({ ...characterData, [name]: value });

    const [item] = hierarchyOfNeedsMarks.filter(i => i.value == value);
    setSelectedHoN(item);
  };

  const suggestPortraitImageFromCharacterDescription =
    async selectedOptionsData => {
      log.trace(
        "suggestPortraitImageFromCharacterDescription.request",
        selectedOptionsData
      );
      logEvent(
        eventSource,
        "suggestPortraitImageFromCharacterDescription.request"
      );

      if (
        !characterData.physical.overview ||
        characterData.physical.overview.length == 0
      ) {
        setAlertMessage({
          message: t("error.suggestPortraitImageMissingParameters"),
          severity: "error",
        });

        return;
      }

      showLoadingUI();

      // TODO priority this should be a specific endpoint for this functionality; no client side logic
      // include personality characteristics and backstory for an accurate suggestion
      // genai to produce bg image
      try {
        const characterPhysicalData = [
          "Generate a portrait image for a character with the following characteristics. ",
        ];
        characterData.physical.overview &&
          characterPhysicalData.push(characterData.physical.overview + ".");

        characterData.physical.race &&
          characterPhysicalData.push(
            "With the race: " + characterData.physical.race + "."
          );
        characterData.physical.height &&
          characterPhysicalData.push(
            "With the height: " + characterData.physical.height + "."
          );
        characterData.physical.weight &&
          characterPhysicalData.push(
            "With the weight: " + characterData.physical.weight + "."
          );
        characterData.physical.age &&
          characterPhysicalData.push(
            "With the age: " + characterData.physical.age + "."
          );
        characterData.physical.appearance &&
          characterPhysicalData.push(
            "With the following appearance characteristics: " +
              characterData.physical.appearance +
              "."
          );
        characterData.physical.style &&
          characterPhysicalData.push(
            "With the visual style: " + characterData.physical.style + "."
          );

        axios
          .post(
            `${process.env.REACT_APP_API_URL}/${selectedOptionsData.imageService.endpoint}`,
            {
              bookUuid: pageParams.id,
              narrativePrompt: characterPhysicalData.join(" "),
              options: selectedOptionsData,
            },
            await requestHeaders()
          )
          .then(response => {
            log.trace(
              "suggestPortraitImageFromCharacterDescription.response.data",
              response.data
            );
            logEvent(
              eventSource,
              "suggestPortraitImageFromCharacterDescription.response"
            );

            const imageUrl =
              `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
              response.data.filePath;

            //set form data with image generated. image is already stored, so just save the file path
            setCharacterData({
              ...characterData,
              imagePrompt: response.data.imagePrompt,
              backgroundImage: response.data.filePath,
              imageUrl: imageUrl,
            });
          })
          .catch(error => {
            log.error("Error requesting action", error);
            logEvent(
              eventSource,
              "suggestPortraitImageFromCharacterDescription.error",
              error
            );

            if (error.status == 460) {
              setAlertMessage({
                message: t("error.membershipGenerationLimitsReached"),
                severity: "error", //success info warning error
              });
            } else {
              setAlertMessage({
                message: t("error.imageGenerationGeneric"),
                severity: "error", //success info warning error
              });
            }
          })
          .finally(() => {
            hideLoadingUI();
          });
      } catch (exception) {
        log.error("Error requesting action", exception);
        logEvent(
          eventSource,
          "suggestPortraitImageFromCharacterDescription.exception",
          exception
        );
        setAlertMessage({
          message: t("error.imageGenerationGeneric"),
          severity: "error",
        });
        hideLoadingUI();
      }
    };

  const handleOpenImageGenerationDialog = () => {
    log.trace("handleOpenImageGenerationDialog");
    logEvent(eventSource, "handleOpenImageGenerationDialog");

    // TODO how to properly handle no characters or location options
    setImageGenerationOptions({
      ...imageGenerationOptions,
      useCharacters: "hidden",
      useLocation: "hidden",
    });

    setOpenImageGenerationDialog(true);
  };

  const handleCloseImageGenerationDialog = selectedOptionsData => {
    log.trace("handleCloseImageGenerationDialog", selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);
    setOpenImageGenerationDialog(false);
  };

  const handleContinueImageGeneration = async selectedOptionsData => {
    log.trace("handleContinueImageGeneration", selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);
    setOpenImageGenerationDialog(false);
    suggestPortraitImageFromCharacterDescription(selectedOptionsData);
  };

  const handleOpenImagePickerDialog = () => {
    setOpenImagePickerDialog(true);
  };

  const handleCloseImagePickerDialog = selectedOptionsData => {
    log.trace("handleCloseImagePickerDialog", selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);
    setOpenImagePickerDialog(false);
  };

  const handleContinueImagePicker = async selectedOptionsData => {
    log.trace("handleContinueImagePicker", selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);
    setOpenImagePickerDialog(false);

    //from computer or from gallery, ...
    if (!selectedOptionsData) {
      log.trace("handleContinueImagePicker.fromComputer");
      logEvent(eventSource, "handleContinueImagePicker.fromComputer");
      imagePickerSelectFromComputer();
    } else {
      log.trace("handleContinueImagePicker.fromGallery");
      logEvent(eventSource, "handleContinueImagePicker.fromGallery");
      const filePath = selectedOptionsData.filePath;
      const imagePrompt = selectedOptionsData.imagePrompt;
      const imageUrl = selectedOptionsData.imageUrl;

      //set form data with image previously generated. image is already stored, so just get the file path
      setCharacterData({
        ...characterData,
        imagePrompt: imagePrompt,
        backgroundImage: filePath,
        imageUrl: imageUrl,
        newBackgroundImage: null,
      });
    }
  };

  const imagePickerSelectFromComputer = () => {
    log.trace("imagePickerSelectFromComputer");
    uploadInputRef.current && uploadInputRef.current.click();
  };

  /**
      VICE
      -------------------------------------------------------------------------------------------------
      https://en.wikipedia.org/wiki/Vice

      A vice is a practice, behaviour, or habit generally considered morally wrong in the associated 
      society. In more minor usage, vice can refer to a fault, a negative character trait, a defect, 
      an infirmity, or a bad or unhealthy habit.[citation needed] Vices are usually associated with a 
      fault in a person's character or temperament rather than their morality.

      The modern English term that best captures its original meaning is the word vicious, which means 
      "full of vice". In this sense, the word vice comes from the Latin word vitium, meaning 
      "failing or defect"

      VIRTUE
      -------------------------------------------------------------------------------------------------
      https://en.wikipedia.org/wiki/Virtue

      A virtue (Latin: virtus) is a trait of excellence, including traits that may be moral, social, 
      or intellectual. The cultivation and refinement of virtue is held to be the "good of humanity" 
      and thus is valued as an end purpose of life or a foundational principle of being.

      The ancient Romans used the Latin word virtus (derived from vir, their word for man) to refer 
      to all of the "excellent qualities of men, including physical strength, valorous conduct, and 
      moral rectitude". The French words vertu and virtu came from this Latin root. The word virtue 
      "was borrowed into English in the 13th century".

      ETHICS
      -------------------------------------------------------------------------------------------------
      https://en.wikipedia.org/wiki/Virtue_ethics

      Virtues are not everyday habits; they are character traits, in the sense that they are central 
      to someone’s personality and what they are like as a person.

      Virtue ethics is usually contrasted with two other major approaches in ethics, consequentialism 
      and deontology, which make the goodness of outcomes of an action (consequentialism) and the 
      concept of moral duty (deontology) central

      virtue was not a skill that made you better able to achieve eudaimonia but was itself an 
      expression of eudaimonia.

      incontinent (who are tempted by their feelings into doing the wrong thing even though they know 
      what is right)
      continent (whose emotions tempt them toward doing the wrong thing but whose strength of will 
      lets them do what they know is right)

      RELIGION
      -------------------------------------------------------------------------------------------------
      cardinal virtues of prudence, justice, temperance, and fortitude
      theological virtues of faith, hope, and charity
      
      chastity, temperance, charity, diligence, kindness, patience, and humility
      pride, greed, wrath, envy, lust, gluttony and sloth

      PLATONIC VIRTUE
      -------------------------------------------------------------------------------------------------
      The four classic cardinal virtues are:

      Prudence (φρόνησις, phrónēsis; Latin: prudentia; also Wisdom, Sophia, sapientia), the ability to 
      discern the appropriate course of action to be taken in a given situation at the appropriate time.
      
      Fortitude (ἀνδρεία, andreía; Latin: fortitudo): also termed courage, forbearance, strength, 
      endurance, and the ability to confront fear, uncertainty, and intimidation.
      
      Temperance (σωφροσύνη, sōphrosýnē; Latin: temperantia): also known as restraint, the practice of 
      self-control, abstention, discretion, and moderation tempering the appetition. Plato considered 
      sōphrosynē, which may also be translated as sound-mindedness, to be the most important virtue.
      
      Justice (δικαιοσύνη, dikaiosýnē; Latin: iustitia): also considered as fairness;[6] the Greek word 
      also having the meaning of righteousness.

      Temperance was most closely associated with the producing classes, the farmers and craftsmen, 
      to moderate their animal appetites. Fortitude was assigned to the warrior class, to strengthen 
      their fighting spirit. Prudence was assigned to the rulers, to guide their reason. Justice 
      stood above these three to properly regulate the relations among them.

      Aristotle's Rhetoric: The forms of Virtue are justice, courage, temperance, magnificence, 
      magnanimity, liberality, gentleness, prudence, wisdom.

      HEXACO
      -------------------------------------------------------------------------------------------------
      https://hexaco.org/
      HEXACO and Virtue - https://www.psychologytoday.com/us/blog/ethics-everyone/201304/hexaco-and-virtue

      Honesty-Humility                Justice/Fairness 
      Emotionality                    Fortitude/Courage 
      eXtraversion                    
      Agreeableness (versus Anger)    
      Conscientiousness               Temperance/Restraint
      Openness to Experience          Prudence/Wisdom 

      DARK TRIAD/TETRAD
      -------------------------------------------------------------------------------------------------
      Machiavellianism: A tendency to manipulate others for self-interest, often through deception and a 
      lack of morality or emotion. The term comes from the 16th-century Italian diplomat and politician 
      Niccolo Machiavelli, whose book The Prince was interpreted as endorsing cunning and deceit in 
      diplomacy.

      Narcissism: A tendency to have an excessive sense of self-importance and grandiosity. People with 
      narcissistic tendencies may be charming, but they can also be political, manipulative, and lack 
      remorse. 

      Psychopathy: A tendency to be impulsive, callous, and thrill seeking. 

      Sadism/Cruelty: delight in cruelty; the derivation of gratification from the infliction of physical pain 
      or humiliation on another person
   */

  const negativeTraitsList = {
    options: [
      "ABRASIVE",
      "ABRUPT",
      "ADDICTIVE",
      "AGONIZING",
      "AIMLESS",
      "AIRY",
      "ALOOF",
      "AMORAL",
      "ANGRY",
      "ANTISOCIAL",
      "ANXIOUS",
      "APATHETIC",
      "ARBITRARY",
      "ARGUMENTATIVE",
      "ARROGANT",
      "ARTIFICIAL",
      "ASOCIAL",
      "ASSERTIVE",
      "ASTIGMATIC",
      "BARBARIC",
      "BEWILDERED",
      "BIOSTEROUS",
      "BIZARRE",
      "BLAND",
      "BLUNT",
      "BRITTLE",
      "BRUTAL",
      "CALCULATING",
      "CALLOUS",
      "CANTAKEROUS",
      "CARELESS",
      "CATTY",
      "CAUTIOUS",
      "CHARMLESS",
      "CHILDISH",
      "CLUMSY",
      "COARSE",
      "COCKY",
      "COLD",
      "COLORLESS",
      "COMPLACENT",
      "COMPLAINTIVE",
      "COMPULSIVE",
      "CONCEITED",
      "CONDEMNATORY",
      "CONFORMIST",
      "CONFRONTATIONAL",
      "CONFUSED",
      "CONTEMPTIBLE",
      "CONTROLLING",
      "CONVENTIONAL",
      "COWARDLY",
      "CRAFTY",
      "CRASS",
      "CRAZY",
      "CRIMINAL",
      "CRITICAL",
      "CRUDE",
      "CRUEL",
      "CYNICAL",
      "DECADENT",
      "DECEITFUL",
      "DEFENSIVE",
      "DELICATE",
      "DEMANDING",
      "DEPENDENT",
      "DESPERATE",
      "DESTRUCTIVE",
      "DEVIOUS",
      "DIFFICULT",
      "DIRTY",
      "DISCONCERTING",
      "DISCONTENTED",
      "DISCOURAGING",
      "DISCOURTEOUS",
      "DISHONEST",
      "DISLOYAL",
      "DISOBEDIENT",
      "DISORDERLY",
      "DISORGANIZED",
      "DISPUTATIOUS",
      "DISRESPECTFUL",
      "DISRUPTIVE",
      "DISSOLUTE",
      "DISSONANT",
      "DISTRACTIBLE",
      "DISTURBING",
      "DOGMATIC",
      "DOMINEERING",
      "DULL",
      "EASILY DISCOURAGED",
      "EGOCENTRIC",
      "ENERVATED",
      "ENVIOUS",
      "ERRATIC",
      "ESCAPIST",
      "EVASIVE",
      "EVIL",
      "EXCITABLE",
      "EXPEDIENT",
      "EXTRAVAGANT",
      "EXTREME",
      "FAITHLESS",
      "FANATICAL",
      "FANCIFUL",
      "FATALISTIC",
      "FAWNING",
      "FEARFUL",
      "FICKLE",
      "FIERY",
      "FIXED",
      "FLAKY",
      "FLAMBOYANT",
      "FOOLISH",
      "FORGETFUL",
      "FRAUDULENT",
      "FRIGHTENING",
      "FRIVOLOUS",
      "FUSSY",
      "GLOOMY",
      "GOSSIPY",
      "GRACELESS",
      "GRAND",
      "GREEDY",
      "GRIM",
      "GRUMPY",
      "GULLIBLE",
      "HATEFUL",
      "HAUGHTY",
      "HEDONISTIC",
      "HESITANT",
      "HIDEBOUND",
      "HIGH-HANDED",
      "HOSTILE",
      "HYPOCRITICAL",
      "IGNORANT",
      "IMITATIVE",
      "IMPATIENT",
      "IMPRACTICAL",
      "IMPRUDENT",
      "IMPULSIVE",
      "INATTENTIVE",
      "INCONSIDERATE",
      "INCURIOUS",
      "INDECISIVE",
      "INDULGENT",
      "INERT",
      "INFLEXIBLE",
      "INHIBITED",
      "INSECURE",
      "INSENSITIVE",
      "INSINCERE",
      "INSULTING",
      "INTOLERANT",
      "IRASCIBLE",
      "IRRATIONAL",
      "IRRESPONSIBLE",
      "IRRITABLE",
      "JEALOUS",
      "JUDGMENTAL",
      "KNOW-IT-ALL",
      "LAZY",
      "LIBIDINOUS",
      "LOQUACIOUS",
      "MACHO",
      "MALICIOUS",
      "MANIPULATIVE",
      "MANNERED",
      "MANNERLESS",
      "MARTYR",
      "MATERIALISTIC",
      "MAWKISH",
      "MEALYMOUTHED",
      "MECHANICAL",
      "MEDDLESOME",
      "MELANCHOLIC",
      "MELODRAMATIC",
      "MERETRICIOUS",
      "MESSY",
      "MISCHIEVOUS",
      "MISERABLE",
      "MISERLY",
      "MISGUIDED",
      "MISTAKEN",
      "MONEY-MINDED",
      "MONSTROUS",
      "MOODY",
      "MORBID",
      "MUDDLE-HEADED",
      "NAGGING",
      "NAIVE",
      "NARCISSISTIC",
      "NARROW",
      "NARROW-MINDED",
      "NATTY",
      "NEEDY",
      "NEGATIVISTIC",
      "NEGLECTFUL",
      "NERVOUS",
      "NEUROTIC",
      "NIHILISTIC",
      "NOSY",
      "OBNOXIOUS",
      "OBSESSIVE",
      "OBVIOUS",
      "ODD",
      "OFFHAND",
      "ONE-DIMENSIONAL",
      "ONE-SIDED",
      "OPINIONATED",
      "OPPORTUNISTIC",
      "OPPRESSED",
      "OUTRAGEOUS",
      "OVERIMAGINATIVE",
      "OVERSENSITIVE",
      "PARANOID",
      "PASSIVE",
      "PEDANTIC",
      "PERFECTIONIST",
      "PERVERSE",
      "PESSIMISTIC",
      "PETTY",
      "PHARISAICAL",
      "PHLEGMATIC",
      "PLODDING",
      "POMPOUS",
      "POSSESSIVE",
      "POWER-HUNGRY",
      "PREDATORY",
      "PREJUDICED",
      "PRESUMPTUOUS",
      "PRETENTIOUS",
      "PRIM",
      "PROCRASTINATING",
      "PROFLIGATE",
      "PROVOCATIVE",
      "PUGNACIOUS",
      "PURITANICAL",
      "PUSHY",
      "QUIRKY",
      "REACTIONARY",
      "REACTIVE",
      "REBELLIOUS",
      "RECKLESS",
      "REGIMENTAL",
      "REGRETFUL",
      "REPENTANT",
      "REPRESSED",
      "RESENTFUL",
      "RIDICULOUS",
      "RIGID",
      "RITUALISTIC",
      "ROWDY",
      "RUINED",
      "SADISTIC",
      "SANCTIMONIOUS",
      "SCATTERBRAINED",
      "SCHEMING",
      "SCORNFUL",
      "SECRETIVE",
      "SEDENTARY",
      "SELF-DESTRUCTIVE",
      "SELF-INDULGENT",
      "SELFISH",
      "SHALLOW",
      "SHORTSIGHTED",
      "SHY",
      "SILLY",
      "SINGLE-MINDED",
      "SLEAZY",
      "SLOPPY",
      "SLOW",
      "SLY",
      "SMALL-THINKING",
      "SOFTHEADED",
      "SORDID",
      "SPOILED",
      "STEELY",
      "STIFF",
      "STINGY",
      "STRONG-WILLED",
      "STUBBORN",
      "STUPID",
      "SUBMISSIVE",
      "SUBSERVIENT",
      "SUPERFICIAL",
      "SUPERSTITIOUS",
      "SUSPICIOUS",
      "TACTLESS",
      "TASTELESS",
      "TEMPERAMENTAL",
      "TENSE",
      "THIEVISH",
      "THOUGHTLESS",
      "TIMID",
      "TRANSPARENT",
      "TREACHEROUS",
      "TRENDY",
      "TROUBLESOME",
      "UNAPPRECIATIVE",
      "UNCARING",
      "UNCHARITABLE",
      "UNCOMMUNICATIVE",
      "UNCONVINCING",
      "UNCOOPERATIVE",
      "UNCOUTH",
      "UNCREATIVE",
      "UNCRITICAL",
      "UNCTUOUS",
      "UNDISCIPLINED",
      "UNETHICAL",
      "UNFRIENDLY",
      "UNGRATEFUL",
      "UNHEALTHY",
      "UNIMAGINATIVE",
      "UNIMPRESSIVE",
      "UNINTELLIGENT",
      "UNLOVABLE",
      "UNPOLISHED",
      "UNPRINCIPLED",
      "UNREALISTIC",
      "UNREFLECTIVE",
      "UNRELIABLE",
      "UNRESTRAINED",
      "UNSELF-CRITICAL",
      "UNSTABLE",
      "VACUOUS",
      "VAGUE",
      "VAIN",
      "VENAL",
      "VENOMOUS",
      "VERBOSE",
      "VINDICTIVE",
      "VIOLENT",
      "VOLATILE",
      "VULNERABLE",
      "WEAK",
      "WEAK-WILLED",
      "WELL-MEANING",
      "WHINY",
      "WILLFUL",
      "WISHFUL",
      "WITHDRAWN",
      "WORKAHOLIC",
      "WORRYWART",
      "ZANY",
    ],
    // getOptionLabel: option => option.title,
  };

  const positiveTraitsList = {
    options: [
      "ABSENTMINDED",
      "ACCESSIBLE",
      "ACTIVE",
      "ADAPTABLE",
      "ADMIRABLE",
      "ADVENTUROUS",
      "AFFECTIONATE",
      "AGGRESSIVE",
      "AGREEABLE",
      "ALERT",
      "ALLOCENTRIC",
      "AMBITIOUS",
      "AMIABLE",
      "AMUSING",
      "ANALYTICAL",
      "ANTICIPATIVE",
      "APPRECIATIVE",
      "ARTFUL",
      "ARTICULATE",
      "ASCETIC",
      "ASPIRING",
      "ATHLETIC",
      "ATTRACTIVE",
      "AUTHORITARIAN",
      "BALANCED",
      "BENEVOLENT",
      "BIG-THINKING",
      "BOLD",
      "BOYISH",
      "BREEZY",
      "BRILLIANT",
      "BUSINESSLIKE",
      "BUSY",
      "CALM",
      "CAPABLE",
      "CAPTIVATING",
      "CARING",
      "CASUAL",
      "CENTERED",
      "CHALLENGING",
      "CHARISMATIC",
      "CHARMING",
      "CHEERFUL",
      "CHUMMY",
      "CIRCUMSPECT",
      "CLEAN",
      "CLEAR-HEADED",
      "CLEVER",
      "COLORFUL",
      "COMPANIONLY",
      "COMPASSIONATE",
      "COMPETITIVE",
      "COMPLEX",
      "CONCILIATORY",
      "CONFIDENT",
      "CONFIDENTIAL",
      "CONSCIENTIOUS",
      "CONSERVATIVE",
      "CONSIDERATE",
      "CONSTANT",
      "CONTEMPLATIVE",
      "CONTRADICTORY",
      "COOPERATIVE",
      "COURAGEOUS",
      "COURTEOUS",
      "CREATIVE",
      "CREBRAL",
      "CRISP",
      "CULTURED",
      "CURIOUS",
      "CUTE",
      "DARING",
      "DEBONAIR",
      "DECENT",
      "DECEPTIVE",
      "DECISIVE",
      "DEDICATED",
      "DEEP",
      "DETERMINED",
      "DIGNIFIED",
      "DIPLOMATIC",
      "DIRECTED",
      "DISCIPLINED",
      "DISCREET",
      "DOMINATING",
      "DRAMATIC",
      "DREAMY",
      "DRIVING",
      "DROLL",
      "DRY",
      "DUTIFUL",
      "DYNAMIC",
      "EARNEST",
      "EARTHY",
      "EASYGOING",
      "EBULLIENT",
      "EDUCATED",
      "EFFEMINATE",
      "EFFICIENT",
      "ELEGANT",
      "ELOQUENT",
      "EMOTIONAL",
      "EMPATHETIC",
      "ENERGETIC",
      "ENIGMATIC",
      "ENTHUSIASTIC",
      "ESTHETIC",
      "EXCITING",
      "EXPERIMENTAL",
      "EXTRAORDINARY",
      "EXTROVERTED",
      "FAIR",
      "FAITHFUL",
      "FAMILIAL",
      "FARSIGHTED",
      "FELICIFIC",
      "FIRM",
      "FLAMBOYANT",
      "FLEXIBLE",
      "FLIRTATIOUS",
      "FOCUSED",
      "FOLKSY",
      "FORECFUL",
      "FORGIVING",
      "FORMAL",
      "FORTHRIGHT",
      "FREETHINKING",
      "FREEWHEELING",
      "FRIENDLY",
      "FRUGAL",
      "FUN-LOVING",
      "FUNNY",
      "GALLANT",
      "GENEROUS",
      "GENTLE",
      "GENUINE",
      "GLAMOROUS",
      "GOOD-NATURED",
      "GRACIOUS",
      "GUILELESS",
      "HAPPY",
      "HARDWORKING",
      "HEALTHY",
      "HEARTY",
      "HELPFUL",
      "HERIOC",
      "HIGH-MINDED",
      "HIGH-SPIRITED",
      "HONEST",
      "HONORABLE",
      "HOSPITABLE",
      "HUMBLE",
      "HUMOROUS",
      "HURIED",
      "HYPNOTIC",
      "ICONOCLASTIC",
      "IDEALISTIC",
      "IDIOSYNCRATIC",
      "IMAGINATIVE",
      "IMPASSIVE",
      "IMPERSONAL",
      "IMPRESSIONABLE",
      "IMPRESSIVE",
      "INCISIVE",
      "INCORRUPTIBLE",
      "INDEPENDENT",
      "INDIVIDUALISTIC",
      "INDUSTRIOUS",
      "INNOCENT",
      "INNOVATIVE",
      "INOFFENSIVE",
      "INSIGHTFUL",
      "INSOUCIANT",
      "INSPIRATIONAL",
      "INTELLIGENT",
      "INTENSE",
      "INTROVERTED",
      "INTUITIVE",
      "INVISIBLE",
      "INVULNERABLE",
      "IRRELIGIOUS",
      "IRREVERENT",
      "JUST",
      "KIND",
      "KNOWLEDGE",
      "LEADERLY",
      "LEISURELY",
      "LIBERAL",
      "LOGICAL",
      "LOVABLE",
      "LOYAL",
      "LYRICAL",
      "MAGNANIMOUS",
      "MANY-SIDED",
      "MASCULINE  (MANLY)",
      "MATERNAL",
      "MATICULOUS",
      "MATURE",
      "MELLOW",
      "MERCIFUL",
      "METHODICAL",
      "METICULOUS",
      "MODERATE",
      "MODERN",
      "MODEST",
      "MORALISTIC",
      "MULTI-LEVELED",
      "MYSTICAL",
      "NATURE-FOCUSED",
      "NEAT",
      "NEUTRAL",
      "NONAUTHORITARIAN",
      "NONCOMMITTAL",
      "NONCOMPETITIVE",
      "NURTURING",
      "OBEDIENT",
      "OBJECTIVE",
      "OBSERVANT",
      "OLD-FASHINED",
      "OPEN",
      "OPTIMISTIC",
      "ORDERLY",
      "ORDINARY",
      "ORGANIZED",
      "ORIGINAL",
      "OUTSPOKEN",
      "PAINSTAKING",
      "PASSIONATE",
      "PATERNALISTIC",
      "PATIENT",
      "PATRIOTIC",
      "PEACEFUL",
      "PENSIVE",
      "PERCEPTIVE",
      "PERFECTIONIST",
      "PERSISTENT",
      "PERSONABLE",
      "PERSUASIVE",
      "PHILOSOPHICAL",
      "PHYSICAL",
      "PLACID",
      "PLANFUL",
      "PLAYFUL",
      "POLISHED",
      "POLITICAL",
      "POPULAR",
      "PRACTICAL",
      "PRECISE",
      "PREDICTABLE",
      "PREOCCUPIED",
      "PRINCIPLED",
      "PRIVATE",
      "PROACTIVE",
      "PROFESSIONAL",
      "PROFOUND",
      "PROGRESSIVE",
      "PROPER",
      "PROTEAN",
      "PROTECTIVE",
      "PROUD",
      "PROVIDENTIAL",
      "PRUDENT",
      "PRUPOSEFUL",
      "PUNCTUAL",
      "PURE",
      "QUESTIONING",
      "QUIET",
      "QUIRKY",
      "RATIONAL",
      "REALISTIC",
      "REFLECTIVE",
      "RELAXED",
      "RELIABLE",
      "RELIGIOUS",
      "RESERVED",
      "RESOURCEFUL",
      "RESPECTFUL",
      "RESPONSIBLE",
      "RESPONSIVE",
      "RESTRAINED",
      "RETIRING",
      "REVERENTIAL",
      "ROMANTIC",
      "RUSTIC",
      "SAGE",
      "SANE",
      "SARCASTIC",
      "SCHOLARLY",
      "SCRUPULOUS",
      "SECURE",
      "SELF-CONSCIOUS",
      "SELF-CRITICAL",
      "SELF-DEFACING",
      "SELF-DENYING",
      "SELF-RELIANT",
      "SELF-SUFFICENT",
      "SELFLESS",
      "SENSIBLE",
      "SENSITIVE",
      "SENSUAL",
      "SENTIMENTAL",
      "SERAPHIC",
      "SERIOUS",
      "SEXY",
      "SHARING",
      "SHREWD",
      "SIMPLE",
      "SKEPTICAL",
      "SKILLFUL",
      "SMOOTH",
      "SOBER",
      "SOCIABLE",
      "SOCIALLY AWARE",
      "SOFT",
      "SOLEMN",
      "SOLID",
      "SOLITARY",
      "SOPHISTICATED",
      "SPIRITUAL",
      "SPONTANEOUS",
      "SPORTING",
      "SPUNKY",
      "STABLE",
      "STEADFAST",
      "STEADY",
      "STERN",
      "STOIC",
      "STOIID",
      "STRICT",
      "STRONG",
      "STUBBORN",
      "STUDIOUS",
      "STYLISH",
      "SUAVE",
      "SUBJECTIVE",
      "SUBTLE",
      "SUPPORTIVE",
      "SURPRISING",
      "SWEET",
      "SYMPATHETIC",
      "SYSTEMATIC",
      "TALENTED",
      "TASTEFUL",
      "TEACHERLY",
      "THOROUGH",
      "THRIFTY",
      "TIDY",
      "TOLERANT",
      "TOUGH",
      "TRACTABLE",
      "TRADITIONAL",
      "TRUSTING",
      "UNAGGRESSIVE",
      "UNAMBITIOUS",
      "UNCEREMONIOUS",
      "UNCHANGING",
      "UNCOMPLAINING",
      "UNDEMANDING",
      "UNDERSTANDING",
      "UNDOGMATIC",
      "UNFATHOMABLE",
      "UNFOOLABLE",
      "UNHURRIED",
      "UNINHIBITED",
      "UNPATRIOTIC",
      "UNPREDICATABLE",
      "UNRELIGIOUS",
      "UNSELFISH",
      "UNSENTIMENTAL",
      "UPRIGHT",
      "URBANE",
      "VENTURESOME",
      "VIVACIOUS",
      "WARM",
      "WELL-BRED",
      "WELL-READ",
      "WELL-ROUNDED",
      "WHIMSICAL",
      "WHOLESOME",
      "WINNING",
      "WISE",
      "WITTY",
      "YOUTHFUL",
    ],
  };

  /**
    Maslow's hierarchy of needs
    //1. Biological and physiological needs - air, food, drink, shelter, warmth, sex, sleep, etc. [homeostasis]
    //2. Safety needs - protection from elements, security, order, law, stability, freedom from fear.
    //3. Love and belongingness needs - friendship, intimacy, trust, and acceptance, receiving and giving affection 
          and love.Affiliating, being part of a group(family, friends, work).
    //4. Esteem needs - which Maslow classified into two categories: (i) esteem for oneself(dignity, achievement, 
          mastery, independence) and(ii) the need to be accepted and valued by others(e.g., status, prestige).
    //5. Cognitive needs - knowledge and understanding, curiosity, exploration, need for meaning and predictability.
    //6. Aesthetic needs - appreciation and search for beauty, balance, form, etc.
    //7. Self-actualization needs - realizing personal potential, self-fulfillment, seeking personal growth and peak 
          experiences.A desire to become everything one is capable of becoming (Maslow, 1987, p. 64).
    //8. Transcendence needs - A person is motivated by values which transcend beyond the personal self(e.g., 
          mystical experiences and certain experiences with nature, aesthetic experiences, sexual experiences, 
          service to others, the pursuit of science, religious faith, etc.).
  */
  const hierarchyOfNeedsMarks = [
    {
      value: 1,
      label: "Physiological",
      description:
        "Physiological needs are the base of the hierarchy. These needs are the biological component for survival. Characters are compelled to satisfy physiological needs first to pursue higher levels of intrinsic satisfaction.",
      examples: "Air, Water, Food, Heat, Clothes, Reproduction, Shelter, Sleep",
    },
    {
      value: 2,
      label: "Safety",
      description:
        "Once a person's physiological needs are satisfied, their safety needs take precedence and dominate behavior. This level is more likely to predominate in children as they generally have a greater need to feel safe.  Adults are also impacted by this, typically in economic matters; 'adults are not immune to the need of safety'.  It includes shelter, job security, health, and safe environments.",
      examples:
        "Health, Personal security, Emotional security, Financial security.",
    },
    {
      value: 3,
      label: "Love and belongingness",
      description:
        "After physiological and safety needs are fulfilled, the third level of needs is interpersonal and involves feelings of belongingness. There's an effective need for a sense of belonging and acceptance among social groups, regardless of whether these groups are large or small; being a part of a group is crucial, regardless if it is work, sports, friends or family.",
      examples:
        "Family, Friendship, Intimacy, Trust, Acceptance, Receiving and giving love and affection",
    },
    {
      value: 4,
      label: "Esteem",
      description:
        "Esteem is the respect, and admiration of a person, but also 'self-respect and respect from others'. Most characters need stable esteem, meaning that which is soundly based on real capacity or achievement.",
      examples:
        "Low esteem is the need for respect from others and may include a need for status, recognition, fame, prestige, and attention.\nHigh esteem is the need for self-respect, and can include a need for strength, competence, mastery, self-confidence, independence, and freedom.",
    },
    {
      value: 5,
      label: "Cognitive",
      description:
        "Cognitive needs crave meaning, information, comprehension and curiosity – this creates a will to learn and attain knowledge.",
      examples:
        "Intrinsic motivation to become educated. Needs creativity, foresight, curiosity and meaning.",
    },
    {
      value: 6,
      label: "Aesthetic",
      description:
        "This would consist of having the ability to appreciate the beauty within the world around one's self, on a day-to-day basis.",
      examples:
        "Immerse themselves in nature's splendor while paying close attention to their surroundings and observing them in order to extract the world's beauty.",
    },
    {
      value: 7,
      label: "Self-actualization",
      description:
        "This level of need refers to the realization of one's full potential. Strong, particular desire to become an ideal parent, succeed athletically, or create paintings, pictures, or inventions",
      examples:
        "Seek and understand how their needs, relationships, and sense of self are expressed through their behavior.",
    },
    {
      value: 8,
      label: "Transcendence",
      description:
        "Also known as spiritual needs, the fullest realization in giving oneself to something beyond oneself. For example, in altruism or spirituality. Transcendence refers to the very highest and most inclusive or holistic levels of consciousness, behaving and relating, as ends rather than means, to oneself, to significant others, to similar beings in general, to other species, to nature, and to the cosmos.",
      examples: "The desire to reach the infinite.",
    },
  ];

  function hierarchyOfNeedsMarkText(mark) {
    log.trace("hierarchyOfNeedsMarkText", mark);
    return `${mark.label}`;
  }

  const uploadInputRef = useRef(null);

  return (
    <PageContainer
      title=""
      breadcrumbs={[
        { title: t("text.overview"), path: `/console/books/${pageParams.id}` },
        {
          title: t("text.characters"),
          path: `/console/books/${pageParams.id}/characters`,
        },
        {
          title: t("text.character"),
          path: `/console/books/${pageParams.id}/characters/${pageParams.cid}`,
        },
      ]}
    >
      <Box component="section">
        {alertMessage.message && (
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        )}

        {loading ? (
          <LinearProgress />
        ) : (
          <Box component="section" sx={{ p: 2 }}>
            <ImageGenerationDialog
              title={t("imageGenerationDialog.title")}
              message={t("imageGenerationDialog.message")}
              open={openImageGenerationDialog}
              onClose={handleCloseImageGenerationDialog}
              onContinue={handleContinueImageGeneration}
              optionsData={imageGenerationOptions}
            />

            <ImagePicker
              title={t("imagePicker.title")}
              message={t("imagePicker.message")}
              open={openImagePickerDialog}
              onClose={handleCloseImagePickerDialog}
              onContinue={handleContinueImagePicker}
              optionsData={imagePickerOptions}
            />

            <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 1 }}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="char-details-content"
                  id="char-details-header"
                >
                  {t("header.characterDetails")}
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2} direction="column" sx={{ p: 2 }}>
                    <Stack spacing={2} direction="row" sx={{}}>
                      <TextField
                        sx={{ width: "50%" }}
                        id="name"
                        name="name"
                        required
                        autoFocus
                        value={characterData.name}
                        onChange={handleInputChange}
                        label={t("text.name")}
                        variant="outlined"
                      />
                      <Autocomplete
                        sx={{ width: "50%" }}
                        {...characterRoles}
                        id="role-auto-complete"
                        name="role-auto-complete"
                        autoComplete
                        filterSelectedOptions
                        onChange={handleRoleInputChange}
                        value={characterData.role}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            const { key, ...tagProps } = getTagProps({ index });
                            return (
                              <Chip
                                variant="filled"
                                label={option}
                                key={key}
                                {...tagProps}
                              />
                            );
                          })
                        }
                        renderInput={trait => (
                          <TextField
                            {...trait}
                            key={trait}
                            variant="standard"
                            label={t("text.role")}
                          />
                        )}
                      />
                    </Stack>
                    <TextField
                      id="backstory"
                      name="backstory"
                      required
                      fullWidth
                      multiline
                      maxRows={20}
                      value={characterData.backstory}
                      onChange={handleInputChange}
                      label={t("text.backstory")}
                      variant="outlined"
                    />
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="char-physical-details-content"
                  id="char-physical-details-header"
                >
                  <NoMaxWidthTooltip arrow title={<LearningPhysicalDetails />}>
                    {t("header.physicalDetails")}
                  </NoMaxWidthTooltip>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2} direction="column" sx={{ p: 2 }}>
                    <TextField
                      id="overview"
                      name="overview"
                      fullWidth
                      multiline
                      maxRows={20}
                      value={characterData.physical.overview}
                      onChange={handlePhysicalInputChange}
                      label={t("text.overview")}
                      variant="outlined"
                    />
                    <TextField
                      id="age"
                      name={t("text.age")}
                      value={characterData.physical.age}
                      onChange={handlePhysicalInputChange}
                      label="Age"
                      variant="outlined"
                    />
                    <TextField
                      id="height"
                      name="height"
                      value={characterData.physical.height}
                      onChange={handlePhysicalInputChange}
                      label={t("text.height")}
                      variant="outlined"
                    />
                    <TextField
                      id="weight"
                      name="weight"
                      value={characterData.physical.weight}
                      onChange={handlePhysicalInputChange}
                      label={t("text.weight")}
                      variant="outlined"
                    />
                    <TextField
                      id="race"
                      name="race"
                      value={characterData.physical.race}
                      onChange={handlePhysicalInputChange}
                      label={t("text.race")}
                      variant="outlined"
                    />
                    <TextField
                      id="appearance"
                      name="appearance"
                      fullWidth
                      multiline
                      maxRows={20}
                      value={characterData.physical.appearance}
                      onChange={handlePhysicalInputChange}
                      label={t("text.appearanceDetails")}
                      variant="outlined"
                    />
                    <TextField
                      id="style"
                      name="style"
                      fullWidth
                      multiline
                      maxRows={20}
                      value={characterData.physical.style}
                      onChange={handlePhysicalInputChange}
                      label={t("text.clothingStyleDetails")}
                      variant="outlined"
                    />
                    <Button
                      variant="text"
                      onClick={handleOpenImageGenerationDialog}
                    >
                      {t("buttonAction.suggestPortraitImage")}
                    </Button>
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="personality-content"
                  id="personality-header"
                >
                  <NoMaxWidthTooltip arrow title={<LearningPersonality />}>
                    {t("header.personality")}
                  </NoMaxWidthTooltip>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="column" spacing={2}>
                    <Stack direction="row" spacing={2}>
                      <Tooltip
                        placement="right-start"
                        title={t(
                          "view.character.personalityExamples.deceitful"
                        )}
                      >
                        <Typography
                          variant="p"
                          sx={{ textAlign: "right", width: "16em" }}
                        >
                          {t("view.character.text.deceitful")}
                        </Typography>
                      </Tooltip>
                      <Slider
                        id="honestyHumility"
                        name="honestyHumility"
                        value={characterData.personality.honestyHumility}
                        onChange={handlePersonalityInputChange}
                        valueLabelDisplay="auto"
                      />
                      <Tooltip
                        placement="left-start"
                        title={t("view.character.personalityExamples.sincere")}
                      >
                        <Typography variant="p" sx={{ width: "16em" }}>
                          {t("view.character.text.sincere")}
                        </Typography>
                      </Tooltip>
                    </Stack>

                    <Stack direction="row" spacing={2}>
                      <Tooltip
                        placement="right-start"
                        title={t(
                          "view.character.personalityExamples.selfRestraint"
                        )}
                      >
                        <Typography
                          variant="p"
                          sx={{ textAlign: "right", width: "16em" }}
                        >
                          {t("view.character.text.selfRestraint")}
                        </Typography>
                      </Tooltip>
                      <Slider
                        id="emotionality"
                        name="emotionality"
                        value={characterData.personality.emotionality}
                        onChange={handlePersonalityInputChange}
                        valueLabelDisplay="auto"
                      />
                      <Tooltip
                        placement="left-start"
                        title={t(
                          "view.character.personalityExamples.sentimental"
                        )}
                      >
                        <Typography variant="p" sx={{ width: "16em" }}>
                          {t("view.character.text.sentimental")}
                        </Typography>
                      </Tooltip>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <Tooltip
                        placement="right-start"
                        title={t(
                          "view.character.personalityExamples.introvert"
                        )}
                      >
                        <Typography
                          variant="p"
                          sx={{ textAlign: "right", width: "16em" }}
                        >
                          {t("view.character.text.introvert")}
                        </Typography>
                      </Tooltip>
                      <Slider
                        id="extraversion"
                        name="extraversion"
                        value={characterData.personality.extraversion}
                        onChange={handlePersonalityInputChange}
                        valueLabelDisplay="auto"
                      />
                      <Tooltip
                        placement="left-start"
                        title={t(
                          "view.character.personalityExamples.extrovert"
                        )}
                      >
                        <Typography variant="p" sx={{ width: "16em" }}>
                          {t("view.character.text.extrovert")}
                        </Typography>
                      </Tooltip>
                    </Stack>

                    <Stack direction="row" spacing={2}>
                      <Tooltip
                        placement="right-start"
                        title={t(
                          "view.character.personalityExamples.cantankerousness"
                        )}
                      >
                        <Typography
                          variant="p"
                          sx={{ textAlign: "right", width: "16em" }}
                        >
                          {t("view.character.text.cantankerousness")}
                        </Typography>
                      </Tooltip>
                      <Slider
                        id="agreeableness"
                        name="agreeableness"
                        value={characterData.personality.agreeableness}
                        onChange={handlePersonalityInputChange}
                        valueLabelDisplay="auto"
                      />
                      <Tooltip
                        placement="left-start"
                        title={t(
                          "view.character.personalityExamples.obsequious"
                        )}
                      >
                        <Typography variant="p" sx={{ width: "16em" }}>
                          {t("view.character.text.obsequious")}
                        </Typography>
                      </Tooltip>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <Tooltip
                        placement="right-start"
                        title={t(
                          "view.character.personalityExamples.impulsive"
                        )}
                      >
                        <Typography
                          variant="p"
                          sx={{ textAlign: "right", width: "16em" }}
                        >
                          {t("view.character.text.impulsive")}
                        </Typography>
                      </Tooltip>
                      <Slider
                        id="conscientiousness"
                        name="conscientiousness"
                        value={characterData.personality.conscientiousness}
                        onChange={handlePersonalityInputChange}
                        valueLabelDisplay="auto"
                      />
                      <Tooltip
                        placement="left-start"
                        title={t("view.character.personalityExamples.diligent")}
                      >
                        <Typography variant="p" sx={{ width: "16em" }}>
                          {t("view.character.text.diligent")}
                        </Typography>
                      </Tooltip>
                    </Stack>

                    <Stack direction="row" spacing={2}>
                      <Tooltip
                        placement="right-start"
                        title={t(
                          "view.character.personalityExamples.comfortZone"
                        )}
                      >
                        <Typography
                          variant="p"
                          sx={{ textAlign: "right", width: "16em" }}
                        >
                          {t("view.character.text.comfortZone")}
                        </Typography>
                      </Tooltip>
                      <Slider
                        id="opennessToExperience"
                        name="opennessToExperience"
                        value={characterData.personality.opennessToExperience}
                        onChange={handlePersonalityInputChange}
                        valueLabelDisplay="auto"
                      />
                      <Tooltip
                        placement="left-start"
                        title={t(
                          "view.character.personalityExamples.curiosity"
                        )}
                      >
                        <Typography variant="p" sx={{ width: "16em" }}>
                          {t("view.character.text.curiosity")}
                        </Typography>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="traits-content"
                  id="traits-header"
                >
                  <NoMaxWidthTooltip arrow title={<LearningTraits />}>
                    {t("header.traits")}
                  </NoMaxWidthTooltip>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="column" spacing={2}>
                    {selectedTrait.length > 0 && (
                      <DictionaryDefinition
                        language={"en"}
                        word={selectedTrait}
                        partOfSpeech={["adjective"]}
                      />
                    )}

                    <Autocomplete
                      {...positiveTraitsList}
                      id="positive-trait-auto-complete"
                      name="positive-trait-auto-complete"
                      fullWidth
                      autoComplete
                      multiple
                      filterSelectedOptions
                      onChange={handlePositiveTraitInputChange}
                      value={characterData.traits.positive}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              variant="filled"
                              label={option}
                              key={key}
                              onClick={e => handleTraitClick(e, option)}
                              {...tagProps}
                            />
                          );
                        })
                      }
                      renderInput={trait => (
                        <TextField
                          {...trait}
                          key={trait}
                          variant="standard"
                          label={t("text.positiveNeutral")}
                        />
                      )}
                    />
                    <Autocomplete
                      {...negativeTraitsList}
                      id="negative-trait-auto-complete"
                      name="negative-trait-auto-complete"
                      fullWidth
                      autoComplete
                      multiple
                      filterSelectedOptions
                      onChange={handleNegativeTraitInputChange}
                      value={characterData.traits.negative}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              variant="outlined"
                              label={option}
                              key={key}
                              onClick={e => handleTraitClick(e, option)}
                              {...tagProps}
                            />
                          );
                        })
                      }
                      renderInput={trait => (
                        <TextField
                          {...trait}
                          key={trait}
                          variant="standard"
                          label={t("text.negative")}
                        />
                      )}
                    />
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="images-content"
                  id="images-header"
                >
                  <NoMaxWidthTooltip arrow title={<LearningHierarchyOfNeeds />}>
                    {t("header.needs")}
                  </NoMaxWidthTooltip>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="row" spacing={2}>
                    <Slider
                      id="hierarchyOfNeeds"
                      name="hierarchyOfNeeds"
                      value={characterData.hierarchyOfNeeds}
                      shiftStep={1}
                      step={1}
                      orientation="vertical"
                      valueLabelDisplay="auto"
                      aria-label="Hierarchy of Needs"
                      marks={hierarchyOfNeedsMarks}
                      getAriaValueText={hierarchyOfNeedsMarkText}
                      min={1}
                      max={8}
                      onChange={handleHierarchyOfNeedsInputChange}
                      sx={{ height: 300 }}
                    />
                    <Box sx={{ pl: 30 }}>
                      <Card variant="outlined" sx={{ maxWidth: 700 }}>
                        <CardContent>
                          <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                            {selectedHoN.label}
                          </Typography>
                          <List>
                            <ListItem>
                              <ListItemText
                                primary={selectedHoN.description}
                                secondary={selectedHoN.examples}
                              />
                            </ListItem>
                          </List>
                        </CardContent>
                      </Card>
                    </Box>
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="images-content"
                  id="images-header"
                >
                  {t("header.media")}
                </AccordionSummary>
                <AccordionDetails>
                  <Box component="section" sx={{ p: 2 }}>
                    <Stack direction="row" justifyContent="center">
                      <Button
                        onClick={handleOpenImagePickerDialog}
                        variant="contained"
                      >
                        {t("buttonAction.selectPortraitImage")}
                      </Button>
                    </Stack>
                    {characterData.backgroundImage && (
                      <Box sx={{ p: 2 }}>
                        <Stack direction="column">
                          <Avatar
                            alt={characterData.backgroundImage}
                            src={characterData.imageUrl}
                            variant="square"
                            sx={{
                              width: "auto",
                              height: "100%",
                            }}
                            onLoad={handlePageBackgroundImageLoaded}
                          />
                          <Typography
                            variant="body2"
                            id="backgroundImage"
                            name="backgroundImage"
                            sx={{ pt: 2, textAlign: "right" }}
                          >
                            {characterData.backgroundImage}
                          </Typography>
                        </Stack>
                      </Box>
                    )}
                    <input
                      id="newBackgroundImage"
                      name="newBackgroundImage"
                      ref={uploadInputRef}
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Stack spacing={2} direction="row" justifyContent="center">
                <Button variant="contained" type="submit" color="primary">
                  {t("buttonAction.save")}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancel}
                >
                  {t("buttonAction.cancel")}
                </Button>
              </Stack>
            </Box>
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}

export default Character;
