import {
  Autocomplete,
  Button,
  Chip,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import log from "../Tools/Log";

const ImageGenerationDialog = ({
  title,
  message,
  open,
  onClose,
  onContinue,
  optionsData,
}) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState({
    useLocation: false,
    useCharacters: false,
    useImageDescriptorService: false,
  });

  useEffect(() => {
    log.trace("useEffect");

    setOptions(optionsData);
  }, [open, optionsData]);

  const handleConfirmation = e => {
    log.trace("handleConfirmation", e);
    onContinue(options);
  };

  const handleDialogClose = e => {
    log.trace("handleDialogClose", e);
    onClose(options);
  };

  const handleSwitchInputChange = e => {
    log.trace("handleSwitchInputChange", e);
    const { name, checked } = e.target;
    setOptions({ ...options, [name]: checked });
  };

  const handleImageServiceInputChange = (event, value) => {
    log.trace("handleImageServiceInputChange", event, value);
    setOptions({ ...options, imageService: value });
  };

  // TODO priority should come from BE db
  const imageGeneratorServicesList = {
    options: [
      { name: "Amazon AWS (Titan)", endpoint: "imageIdeationBasedOnPromptAWS" },
      {
        name: "Google (AIPlatform Imagen)",
        endpoint: "imageIdeationBasedOnPromptGAI",
      },
      // {
      //   name: "Google (VertexAI Imagen)",
      //   endpoint: "imageIdeationBasedOnPromptVAI",
      // },
      {
        name: "Microsoft (Azure OpenAI)",
        endpoint: "imageIdeationBasedOnPromptAzure",
      },
      { name: "OpenAI (DALL·E)", endpoint: "imageIdeationBasedOnPromptDalle" },
    ],
    getOptionLabel: option => option.name,
  };

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <FormGroup>
          <Autocomplete
            {...imageGeneratorServicesList}
            id="image-services-auto-complete"
            name="image-services-auto-complete"
            fullWidth
            autoComplete
            filterSelectedOptions
            onChange={handleImageServiceInputChange}
            value={options.imageService}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    variant="filled"
                    label={option.label}
                    key={key}
                    {...tagProps}
                  />
                );
              })
            }
            renderInput={service => (
              <TextField
                {...service}
                key={service.label}
                variant="standard"
                label={t("view.imageGenerationDialog.text.imageService")}
              />
            )}
          />
          {options.useLocation !== "hidden" && (
            <FormControlLabel
              control={
                <Switch
                  name="useLocation"
                  id="useLocation"
                  checked={options.useLocation}
                  onChange={handleSwitchInputChange}
                />
              }
              label={t("view.imageGenerationDialog.text.useLocation")}
            />
          )}
          {options.useCharacters !== "hidden" && (
            <FormControlLabel
              control={
                <Switch
                  name="useCharacters"
                  id="useCharacters"
                  checked={options.useCharacters}
                  onChange={handleSwitchInputChange}
                />
              }
              label={t("view.imageGenerationDialog.text.useCharacters")}
            />
          )}

          <FormControlLabel
            control={
              <Switch
                name="useImageDescriptorService"
                id="useImageDescriptorService"
                checked={options.useImageDescriptorService}
                onChange={handleSwitchInputChange}
              />
            }
            label={t("view.imageGenerationDialog.text.use2StepsGenerator")}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmation} color="primary">
          {t("buttonAction.continue")}
        </Button>
        <Button onClick={handleDialogClose} color="secondary">
          {t("buttonAction.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageGenerationDialog;
