import {
  Alert,
  Avatar,
  Box,
  Button,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { PageContainer } from "@toolpad/core/PageContainer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import firebaseAuth from "../Services/auth";
import { logEvent } from "../Services/firebase";
import { requestHeaders, stringAvatar } from "../Tools/DataUtils";
import log from "../Tools/Log";

function Profile() {
  const eventSource = "Profile";

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [userProfileData, setUserProfileData] = useState({
    displayName: "",
    email: "",
    emailVerified: "",
    penName: "",
    membership: {},
  });

  const showLoadingUI = () => {
    log.trace("showLoadingUI");
    setLoading(true);
    setAlertMessage({});
  };

  const hideLoadingUI = () => {
    log.trace("hideLoadingUI");
    setLoading(false);
  };

  useEffect(() => {
    log.trace("useEffect");
    logEvent(eventSource, "useEffect");

    fetchData();
  }, []);

  const fetchData = async () => {
    log.trace("fetchData.request");
    logEvent(eventSource, "fetchData.request");

    try {
      showLoadingUI();

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getUserProfile`,
          {},
          await requestHeaders()
        )
        .then(response => {
          log.trace("fetchData.response.data", response.data);
          logEvent(eventSource, "fetchData.response");

          setUserProfileData(response.data);
        })
        .catch(error => {
          log.error("Error requesting data", error);
          logEvent(eventSource, "fetchData.error", error);
          setAlertMessage({
            message: t("error.dataFetch"),
            severity: "error",
          });

          // TODO priority auto create profile?
          // if new member without profile, redirect
          if (error.status == 310) {
            // 310 - not a member
            navigate("/newuserprofile");
          } else {
            setAlertMessage({
              severity: "error",
              message:
                "Error fetching user profile data. Please try again or contact support.",
            });
          }
        })
        .finally(() => {
          hideLoadingUI();
        });
    } catch (exception) {
      log.error("Error requesting data", exception);
      logEvent(eventSource, "fetchData.exception", exception);
      setAlertMessage({
        message: t("error.dataFetch"),
        severity: "error",
      });
      hideLoadingUI();
    }
  };

  const handleSignOut = async () => {
    navigate("/logout");
  };

  return (
    <PageContainer title={t("text.profile")} breadcrumbs="">
      <Box component="section">
        {alertMessage.message && (
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        )}
        {loading ? (
          <LinearProgress />
        ) : (
          <Box component="section">
            <Stack direction="row" spacing={2}>
              <Stack direction="column">
                {firebaseAuth.currentUser.photoURL ? (
                  <Avatar
                    sx={{ width: 130, height: 130 }}
                    alt={firebaseAuth.currentUser.displayName}
                    src={firebaseAuth.currentUser.photoURL}
                  />
                ) : (
                  <Avatar
                    {...stringAvatar(firebaseAuth.currentUser.displayName)}
                  />
                )}
                <Button
                  key="Logout"
                  variant="outlined"
                  sx={{ my: 2 }}
                  onClick={handleSignOut}
                >
                  {t("buttonAction.logout")}
                </Button>
              </Stack>

              <Box>
                <Stack direction="column">
                  <Typography variant="h5">
                    {userProfileData.displayName}
                  </Typography>
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{ display: "block" }}
                  >
                    {t("text.displayName")}
                  </Typography>
                </Stack>
                <Stack direction="column">
                  <Typography variant="h5">{userProfileData.email}</Typography>
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{ display: "block" }}
                  >
                    {t("text.email")}
                  </Typography>
                </Stack>
                {userProfileData.penName && (
                  <Stack direction="column">
                    <Typography variant="h6">
                      {userProfileData.penName}
                    </Typography>
                    <Typography
                      variant="caption"
                      gutterBottom
                      sx={{ display: "block" }}
                    >
                      {t("text.penName")}
                    </Typography>
                  </Stack>
                )}
              </Box>
            </Stack>
          </Box>
        )}
      </Box>
    </PageContainer>
  );
}

export default Profile;
