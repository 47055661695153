import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import firebaseAuth from "./Services/auth";
import { logEvent } from "./Services/firebase";
import log from "./Tools/Log";

function Logout() {
  const eventSource = "Logout";

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    log.trace("useEffect", firebaseAuth.currentUser);
    logEvent(eventSource, "useEffect");

    try {
      firebaseAuth.signOut().then(() => {
        navigate("/");
      });
    } catch (error) {
      navigate("/");
    }
  }, []);

  return (
    <Box
      component="section"
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack spacing={1}>
        <Typography variant="h6">{t("view.logout.text.loggingOut")}</Typography>
        <Typography variant="body1">
          {t("view.logout.text.redirectingMessage")}
          <Link to="/">{t("view.logout.text.clickHere")}</Link>.
        </Typography>
      </Stack>
    </Box>
  );
}

export default Logout;
