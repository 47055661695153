import { Button, FormControlLabel, FormGroup, Switch } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import log from "../Tools/Log";

const TextGenerationDialog = ({
  title,
  message,
  open,
  onClose,
  onContinue,
  optionsData,
}) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState({
    useLocation: true,
    useCharacters: true,
    usePreviousPagesContext: true,
  });

  useEffect(() => {
    log.trace("useEffect", optionsData);

    setOptions(optionsData);
  }, [open, optionsData]);

  const handleConfirmation = e => {
    log.trace("handleConfirmation", e);
    onContinue(options);
  };

  const handleDialogClose = e => {
    log.trace("handleDialogClose", e);
    onClose(options);
  };

  const handleSwitchInputChange = e => {
    log.trace("handleSwitchInputChange", e);
    const { name, checked } = e.target;
    setOptions({ ...options, [name]: checked });
  };

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <FormGroup>
          {options.useLocation !== "hidden" && (
            <FormControlLabel
              control={
                <Switch
                  name="useLocation"
                  id="useLocation"
                  checked={options.useLocation}
                  onChange={handleSwitchInputChange}
                />
              }
              label={t("view.textGenerationDialog.text.useLocation")}
            />
          )}
          {options.useCharacters !== "hidden" && (
            <FormControlLabel
              control={
                <Switch
                  name="useCharacters"
                  id="useCharacters"
                  checked={options.useCharacters}
                  onChange={handleSwitchInputChange}
                />
              }
              label={t("view.textGenerationDialog.text.useCharacters")}
            />
          )}
          <FormControlLabel
            control={
              <Switch
                name="usePreviousPagesContext"
                id="usePreviousPagesContext"
                checked={options.usePreviousPagesContext}
                onChange={handleSwitchInputChange}
              />
            }
            label={t("view.textGenerationDialog.text.usePreviousPagesContext")}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmation} color="primary">
          {t("buttonAction.continue")}
        </Button>
        <Button onClick={handleDialogClose} color="secondary">
          {t("buttonAction.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TextGenerationDialog;
